<!--ReleaseStateMessageComponent-->
<template>
  <div
    class="flex items-center rounded-2xl p-20 min-h-[5.8rem] justify-center gap-12 flex-col"
    :class="productReleaseStatusBackgroundColor[productReleaseStatus]"
  >
    <s-text
      as="p"
      role="title6"
      class="flex gap-4 flex-wrap justify-center"
      :class="productReleaseStatusTextColor[productReleaseStatus]"
    >
      <span class="stds-text text-title6 leading-title6 font-title6 flex gap-4 !font-bold">
        <s-icon
          :icon="productReleaseStatusStateIcon[productReleaseStatus] as any"
          size="4xl"
          class="shrink-0"
        />
        <slot v-if="customText"></slot>
        <safe-html v-else tag="span" class="text-center" :html="productReleaseStatusContent[productReleaseStatus]" />
      </span>
      <safe-html
        v-if="getTextRestrict && getTextRestrictSeconds"
        class="inline-block w-full text-center"
        :html="getTextRestrictSeconds"
      />
    </s-text>
    <a
      v-if="buttonName || isRestrictStatusCaseRatingReport"
      target="_blank"
      class="stds-button stds-button-outline rounded-lg min-w-44 pl-20 pr-12 min-h-[4.4rem] flex-row-reverse"
    >
      <s-icon icon="ic-v2-control-web-link-line" size="3xl" class="!mr-0 ml-4" />
      <s-text
        as="span"
        role="btn2"
        class="font-medium text-on-surface-elevation-2"
        @click="goToLink()"
      >
        <span v-if="buttonName">{{ buttonName }}</span>
        <span v-else>{{ $t('studio.gnb.common_2depth_tier_review') }}</span>
      </s-text>
    </a>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import { OPEN_TARGET_BLANK } from '@/constants/common.const';
import { CLAIM_TYPE_LABEL, RESTRICT_STATUS } from '@/constants/products.const';
import { RELEASE_PLAN_PRODUCT_STATUS } from '@/constants/release-plan.const';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { ProductSanctionInquiryResponse } from '@/types/product/product-response.type';
import { redirectTo } from '@/utils/common.util';
import { getDisplaySanctionText } from '@/utils/product.util';

const props = defineProps<{
  buttonName?: string;
  customText?: boolean;
  productReleaseStatus: number;
  isOnlyPageOpenReleased?: boolean;
  dataSanctionInquiry?: ProductSanctionInquiryResponse;
  buttonUrl?: string;
}>();

const { t } = useI18n();

const releasePlanStore = useReleasePlanStore();
const { isStopSale, isNotDisplay } = storeToRefs(releasePlanStore);

let getTextRestrictSeconds = '';

const isRestrictStatusCaseRatingReport = computed(() => {
  return (
    props.dataSanctionInquiry?.restrictStatus === RESTRICT_STATUS.RATING_DISPLAY ||
    props.dataSanctionInquiry?.restrictStatus === RESTRICT_STATUS.RATING_RUN
  );
});

const getTextRestrict = computed(() => {
  getTextRestrictSeconds = '';
  if (
    !props.dataSanctionInquiry ||
    props.dataSanctionInquiry.restrictStatus === RESTRICT_STATUS.NONE
  ) {
    return '';
  }
  const reportClaims = props.dataSanctionInquiry.reportClaims || [];
  let result;
  if (props.dataSanctionInquiry.restrictStatus === RESTRICT_STATUS.REPORT) {
    result = `${t('studio.release_stt_pbar.user_report_release_suspended_msg', {
      reportedReason: `${reportClaims
        .map((item: any) => t(CLAIM_TYPE_LABEL[item.claimType]))
        .join(', ')}`
    })}`;
    getTextRestrictSeconds = `${t('studio.release_stt_pbar.restrict_case_cs_contact_guide')}`;
    return result;
  }
  if (isRestrictStatusCaseRatingReport.value) {
    result = `${t('studio.release_stt_pbar.operator_review_restrict', {
      restrictionType: `${t(`${getDisplaySanctionText(props.dataSanctionInquiry.restrictStatus)}`)}`
    })}`;
    return result;
  }
  result = `${t('studio.release_stt_pbar.operator_restrict_release_suspended_msg', {
    restrictType: `${t(`${getDisplaySanctionText(props.dataSanctionInquiry.restrictStatus)}`)}`
  })}`;
  getTextRestrictSeconds = `${t('studio.release_stt_pbar.restrict_case_cs_contact_guide')}`;
  return result;
});

const getTextReleaseStopped = computed(() => {
  let text = '';
  if (isNotDisplay.value) {
    text += `${t('studio.prj_prod.this_prod.stt_release_sale_suspended_guide.case_prod_pg_only')}`;
  }
  if (isStopSale.value) {
    text += `${t('studio.prj_prod.this_prod.stt_release_sale_suspended_guide.case_prod_pg_build')}`;
  }
  return text;
});

const productReleaseStatusContent = {
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED]: t(
    'studio.prj_prod.this_prod.release_details_pbar_msg1'
  ),
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUESTING_REVIEW]: t(
    'studio.prj_prod.this_prod.release_details_pbar_prep_completed'
  ),
  [RELEASE_PLAN_PRODUCT_STATUS.REQUESTED_REVIEW]: t(
    'studio.prj_prod.this_prod.review_status_pbar_msg2'
  ),
  [RELEASE_PLAN_PRODUCT_STATUS.UNDER_REVIEW]: t(
    'studio.prj_prod.this_prod.review_status_pbar_under_review'
  ),
  [RELEASE_PLAN_PRODUCT_STATUS.OPERATOR_REJECTED_SANCTIONED]: `<span>${t(
    'studio.prj_prod.this_prod.review_status_pbar_msg4_1'
  )}</span> ${t('studio.prj_prod.this_prod.review_status_pbar_msg4_2')}`,
  [RELEASE_PLAN_PRODUCT_STATUS.REVIEW_COMPLETED]: t(
    'studio.prj_prod.this_prod.review_status_pbar_msg5'
  ),
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_LIVE]: t('studio.prj_prod.this_prod.release_stt_pbar_msg6'),
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED]: getTextReleaseStopped.value,
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_RESTRICT]: getTextRestrict.value
};

const productReleaseStatusBackgroundColor = {
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED]: 'bg-[#CCDCFF]',
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUESTING_REVIEW]: 'bg-[#CCDCFF]',
  [RELEASE_PLAN_PRODUCT_STATUS.REQUESTED_REVIEW]: 'bg-[#CCDCFF]',
  [RELEASE_PLAN_PRODUCT_STATUS.UNDER_REVIEW]: 'bg-[#FFC226]',
  [RELEASE_PLAN_PRODUCT_STATUS.OPERATOR_REJECTED_SANCTIONED]: 'bg-tint-red-a400',
  [RELEASE_PLAN_PRODUCT_STATUS.REVIEW_COMPLETED]: 'bg-success',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_LIVE]: 'bg-brand-primary',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED]: 'bg-on-surface-elevation-3',
  [RELEASE_PLAN_PRODUCT_STATUS.CUSTOM_ERROR]: 'bg-on-surface-elevation-3',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_RESTRICT]: 'bg-tint-red-a400'
};

const productReleaseStatusTextColor = {
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED]: '',
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUESTING_REVIEW]: 'text-[#3669E6]',
  [RELEASE_PLAN_PRODUCT_STATUS.REQUESTED_REVIEW]: 'text-[#3669E6]',
  [RELEASE_PLAN_PRODUCT_STATUS.UNDER_REVIEW]: 'text-on-surface-elevation-2',
  [RELEASE_PLAN_PRODUCT_STATUS.OPERATOR_REJECTED_SANCTIONED]: 'text-white !font-bold',
  [RELEASE_PLAN_PRODUCT_STATUS.REVIEW_COMPLETED]: 'text-white !font-bold',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_LIVE]: 'text-white !font-bold',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED]: 'text-white !font-bold',
  [RELEASE_PLAN_PRODUCT_STATUS.CUSTOM_ERROR]: 'text-on-surface-elevation-2',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_RESTRICT]: 'text-white !font-bold'
};

const productReleaseStatusStateIcon = {
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED]: 'ic-v2-state-info-circle-line',
  [RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUESTING_REVIEW]: 'ic-v2-control-check-bold-line',
  [RELEASE_PLAN_PRODUCT_STATUS.REQUESTED_REVIEW]: 'ic-v2-navigation-search-line',
  [RELEASE_PLAN_PRODUCT_STATUS.UNDER_REVIEW]: 'ic-v2-navigation-search-line',
  [RELEASE_PLAN_PRODUCT_STATUS.OPERATOR_REJECTED_SANCTIONED]: 'ic-v2-state-warning-circle-line',
  [RELEASE_PLAN_PRODUCT_STATUS.REVIEW_COMPLETED]: 'ic-v2-control-check-bold-line',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_LIVE]: 'ic-v2-navigation-shop-line',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED]: 'ic-v2-control-minus-circle-line',
  [RELEASE_PLAN_PRODUCT_STATUS.CUSTOM_ERROR]: 'ic-v2-state-warning-circle-line',
  [RELEASE_PLAN_PRODUCT_STATUS.RELEASE_RESTRICT]: 'ic-v2-control-minus-circle-line'
};

const goToLink = () => {
  redirectTo(props.buttonUrl ?? PRODUCT_RATING_URL, OPEN_TARGET_BLANK);
};
</script>

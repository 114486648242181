<!--ReleaseChecklistComponent-->
<template>
  <div
    class="relative overflow-hidden w-full h-auto py-24 pb-[3.2rem] rounded-[2.8rem] shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] bg-surface-layer"
  >
    <div ref="commonInfo" class="space-y-16 px-20">
      <div class="flex gap-8 justify-between items-center !font-bold">
        <s-text as="h2" role="title5" class="text-on-surface-elevation-2">
          {{ $t('studio.mail.this_release_status.checklist_popup_title') }}
        </s-text>
        <s-text
          as="span"
          role="text2"
          :class="isCompleted ? 'text-brand-primary' : 'text-tint-red-a400'"
        >
          {{
            isCompleted
              ? $t('studio.prj_prod.release_info_checklist_stt_completed')
              : $t('studio.prj_prod.release_info_checklist_stt_incompleted')
          }}
        </s-text>
      </div>
      <div class="space-y-8">
        <s-text as="dl" role="text3" class="flex gap-8 items-center justify-between font-medium">
          <dt class="text-on-surface-elevation-2">
            {{ $t('studio.prj_prod.release_info_checklist_review_stt') }}
          </dt>
          <dd class="text-placeholder text-right">
            <!-- 준비중 [Preparing]-->
            <span
              v-if="isBeforeRequiredItemEnteredStatus || isBeforeRequestingReviewStatus"
              class="text-md font-medium leading-lg text-placeholder"
            >
              {{ $t('studio.prj_prod.release_info_checklist_review_stt_prep') }}
            </span>
            <!-- 반려 [Rejected] -->
            <div
              v-else-if="
                isOperatorRejectedSanctionedStatus || (isReleaseStoppedStatus && hasRejectData)
              "
              class="inline-flex items-center gap-4"
            >
              <i class="w-12 h-12 rounded-full shrink-0 bg-tint-red-a400"></i>
              <span class="text-md font-medium leading-lg text-on-surface-elevation-2">
                {{ $t('studio.prj_prod.status_rejected') }}
              </span>
            </div>
            <!-- 검수요청 [Review Request]-->
            <div v-else-if="isRequestedReviewStatus" class="inline-flex items-center gap-4">
              <i class="w-12 h-12 rounded-full shrink-0 bg-[#B8CEFF]"></i>
              <span class="text-md font-medium leading-lg text-on-surface-elevation-2">
                {{
                  $t(
                    'studio.prj_prod.this_prod.release_details_browser_request_review_pop_request_review_btn'
                  )
                }}
              </span>
            </div>
            <!-- 검수중 [Under Review]-->
            <div v-else-if="isUnderReviewStatus" class="inline-flex items-center gap-4">
              <i class="w-12 h-12 rounded-full shrink-0 bg-[#FFC226]"></i>
              <span class="text-md font-medium leading-lg text-on-surface-elevation-2">
                {{ $t('studio.prj_prod.status_in_review') }}
              </span>
            </div>
            <!-- 검수완료 [Review Completed]-->
            <div
              v-else-if="
                isReviewCompletedStatus ||
                  isReleaseLiveStatus ||
                  (isReleaseStoppedStatus && !hasRejectData)
              "
              class="inline-flex items-center gap-4"
            >
              <i class="w-12 h-12 rounded-full shrink-0 bg-success"></i>
              <span class="text-md font-medium leading-lg text-on-surface-elevation-2">
                {{ $t('studio.prj_prod.status_review_completed') }}
              </span>
            </div>
          </dd>
        </s-text>
        <s-text as="dl" role="text3" class="flex gap-8 items-center justify-between font-medium">
          <dt class="text-on-surface-elevation-2">
            {{ $t('studio.prj_prod.release_info_checklist_official_release_date') }}
          </dt>
          <dd class="text-placeholder text-right">
            <!-- 판매중단 [Suspend Sales]-->
            <span
              v-if="isStopSale || isNotDisplay"
              class="text-md font-medium leading-lg text-placeholder"
            >
              <span v-if="isNotDisplay">
                {{ $t('studio.prj_prod_mngmt.popup_prod_pg_open_suspend_title') }}
              </span>
              <span v-else>
                {{ $t('studio.prj_prod_mngmt.popup_prod_pg_open_suspend_title') }}
              </span>
            </span>
            <template v-else>
              <template v-if="isPageBuild">
                <span
                  v-if="releasedAt"
                  class="text-md font-medium leading-lg text-on-surface-elevation-2"
                >
                  {{ getDateTimeByLocale(releasedAt, true) }}
                </span>
              </template>
              <template v-else>
                <!-- 상품 페이지 오픈예정 [Product Page Coming Soon] -->
                <span v-if="isReviewCompletedStatus">
                  {{ $t('studio.prj_prod.this_prod.stt_product_page_upcoming') }}
                </span>
                <!-- 상품 페이지 오픈 [Product Page Open] -->
                <span v-else-if="isReleaseLiveStatus">
                  {{
                    $t(
                      'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_prod_pg_open'
                    )
                  }}
                </span>
                <!-- 출시예정 [Coming Soon]-->
                <span v-else class="text-md font-medium leading-lg text-placeholder">
                  {{
                    $t('studio.prj_prod.release_info_checklist_official_release_stt_to_be_release')
                  }}
                </span>
              </template>
            </template>
          </dd>
        </s-text>
        <s-text
          v-if="isStopSale && isNotDisplay"
          as="dl"
          role="text3"
          class="flex gap-8 items-center justify-between font-medium"
        >
          <dd class="text-placeholder text-right">
            <span class="text-md font-medium leading-lg text-placeholder">
              {{ $t('studio.prj_prod.this_prod.stt_release_sale_suspended') }}
            </span>
          </dd>
        </s-text>
      </div>
    </div>
    <ul
      id="checklist-sticky"
      ref="listGroup"
      class="space-y-20 mt-20 studio-scrollbar-4 hidden-bar pl-20"
      @scroll="handleListScroll"
    >
      <release-checklist-item-group
        :releaseChecklistTitle="
          $t('studio.prj_prod.this_prod.release_details_product_page_info_title')
        "
        :isCompleted="currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.PREPARE_PRODUCT_PAGE"
        :reloadId="RELEASE_CHECKLIST_ID.PREPARE_PRODUCT_PAGE"
        iconClass="ic-v2-navigation-studio-test-fill"
        isRequired
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.PREPARE_PRODUCT_PAGE)"
      >
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.productSetting)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status.checklist_popup_item1_prod_pg_prep.prod_setting')
          "
          :status="checkListData.productSetting"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.productPage)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_product_page_info_product_page')
          "
          :status="checkListData.productPage"
        />
      </release-checklist-item-group>
      <release-checklist-item-group
        :releaseChecklistTitle="
          $t('studio.prj_prod.this_prod.release_details_product_page_option_title')
        "
        :isCompleted="currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.PRODUCT_PAGE_OPTIONS"
        :reloadId="RELEASE_CHECKLIST_ID.PRODUCT_PAGE_OPTIONS"
        iconClass="ic-v2-community-board-line"
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.PRODUCT_PAGE_OPTIONS)"
      >
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.storeCommunity)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_product_page_option_community')
          "
          :status="checkListData.storeCommunity"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.earlyAccess)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_prod_pg_opt_early_access_guide')
          "
          :status="checkListData.earlyAccess"
        />
      </release-checklist-item-group>
      <release-checklist-item-group
        v-if="isPageBuild"
        :releaseChecklistTitle="$t('studio.mail.this_release_status_checklist_build_title')"
        :isCompleted="currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.BUILD_PREPARATION"
        :reloadId="RELEASE_CHECKLIST_ID.BUILD_PREPARATION"
        iconClass="ic-v2-object-game-line"
        isRequired
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.BUILD_PREPARATION)"
      >
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.executionSettings)"
          :releaseChecklistText="$t('studio.mail.this_release_status_checklist_build_execution')"
          :status="checkListData.executionSettings"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.uploadBuildFile)"
          :releaseChecklistText="$t('studio.mail.this_release_status_checklist_build_file_upload')"
          :status="checkListData.uploadBuildFile"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.ageRatingAndBuild)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_build_ratings_and_review')
          "
          :status="checkListData.ageRatingAndBuild"
        />
      </release-checklist-item-group>
      <release-checklist-item-group
        v-if="
          isPageBuild &&
            currentProductDetailType !== PRODUCT_TYPE_DETAIL.UTILITY_DLC &&
            currentProductDetailType !== PRODUCT_TYPE_DETAIL.GAME_DLC
        "
        :releaseChecklistTitle="
          $t('studio.mail.this_release_status_checklist_studio_support_title')
        "
        :isCompleted="
          currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.GAME_SUPPORT_SERVICE_SETTINGS
        "
        :reloadId="RELEASE_CHECKLIST_ID.GAME_SUPPORT_SERVICE_SETTINGS"
        iconClass="ic-v2-object-trophy-line"
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.GAME_SUPPORT_SERVICE_SETTINGS)"
      >
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.inGameStore)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_studio_support_in_app_shop')
          "
          :status="checkListData.inGameStore"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.productTerms)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_studio_support_condition')
          "
          :status="checkListData.productTerms"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.achievements)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_studio_support_challenges')
          "
          :status="checkListData.achievements"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.rankings)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_studio_support_ranking')
          "
          :status="checkListData.rankings"
          @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.RANKINGS)"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.cloudSaving)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_studio_support_cloud')
          "
          :status="checkListData.cloudSaving"
        />
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.matchMaking)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_studio_support_match')
          "
          :status="checkListData.matchMaking"
        />
      </release-checklist-item-group>
      <release-checklist-item-group
        v-if="isPageBuild && !releasePlanStore.isCheckListHidden(checkListData.priceSettings)"
        :releaseChecklistTitle="$t('studio.mail.this_release_status_checklist_sales_setting_title')"
        :isCompleted="currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.PRICE_SETTINGS"
        :reloadId="RELEASE_CHECKLIST_ID.PRICE_SETTINGS"
        iconClass="ic-v2-object-coin-line"
        isRequired
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.PRICE_SETTINGS)"
      >
        <release-checklist-item
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_sales_setting_price_and_discount')
          "
          :status="checkListData.priceSettings"
        />
      </release-checklist-item-group>
      <release-checklist-item-group
        v-if="isPageBuild"
        :releaseChecklistTitle="$t('studio.prj_prod.this_prod.release_details_pur_cond_title')"
        :isCompleted="currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.PURCHASE_CONDITIONS"
        :reloadId="RELEASE_CHECKLIST_ID.PURCHASE_CONDITIONS"
        iconClass="ic-v2-object-collection-line"
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.PURCHASE_CONDITIONS)"
      >
        <release-checklist-item
          :releaseChecklistText="$t('studio.mail.this_release_status_checklist_sales_setting_sale')"
          :status="checkListData.purchaseConditions"
        />
      </release-checklist-item-group>
      <release-checklist-item-group
        id="release-checklist-item-release-date"
        :releaseChecklistTitle="$t('studio.mail.this_release_status_checklist_release_plan_title')"
        :isCompleted="currentSectionId === RELEASE_CHECKLIST_ID_NAVIGATOR.RELEASE_PLAN"
        iconClass="ic-v2-community-calendar-line"
        isRequired
        @clickTitle="goToSection(RELEASE_CHECKLIST_ID_NAVIGATOR.RELEASE_PLAN)"
      >
        <release-checklist-item
          v-if="!releasePlanStore.isCheckListHidden(checkListData.storePageOpen)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_release_plan_open_date_input')
          "
          :status="checkListData.storePageOpen"
        />
        <release-checklist-item
          v-if="isPageBuild && !releasePlanStore.isCheckListHidden(checkListData.prePurchase)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_release_plan_early_access')
          "
          :status="checkListData.prePurchase"
        />
        <release-checklist-item
          v-if="isPageBuild && !releasePlanStore.isCheckListHidden(checkListData.officialRelease)"
          :releaseChecklistText="
            $t('studio.mail.this_release_status_checklist_release_plan_schedule')
          "
          :status="checkListData.officialRelease"
        />
      </release-checklist-item-group>
    </ul>
    <div
      class="w-full h-[3.2rem] border-t border-studio-border flex justify-center items-center absolute bottom-0 left-0 bg-surface-layer transition-opacity duration-300"
      :class="{ 'opacity-0': isAtBottom }"
    >
      <s-button
        icon="ic-v2-control-select-arrow-down-fill"
        class="!min-w-0 !min-h-0 text-on-surface-elevation-2 !bg-transparent !border-none"
        @mouseenter="scrollToBottom"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue } from 'vee-validate';
import { computed, nextTick, onMounted, onUnmounted, ref, toRefs } from 'vue';

import ReleaseChecklistItem from '@/components/release-plan/release-checklist-item.vue';
import ReleaseChecklistItemGroup from '@/components/release-plan/release-checklist-item-group.vue';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import {
  RELEASE_CHECKLIST_ID,
  RELEASE_CHECKLIST_ID_NAVIGATOR
} from '@/constants/release-plan.const';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import { getDateTimeByLocale } from '@/utils/date.util';

const props = defineProps<{
  isPageBuild: boolean;
  isOnlyPageOpenReleased: boolean;
  hasRejectData: boolean;
  stickyID: string;
}>();
const { isPageBuild } = toRefs(props);

const productStore = useProductStore();
const { currentProductDetailType } = storeToRefs(productStore);

const releasePlanStore = useReleasePlanStore();
const {
  isStopSale,
  isNotDisplay,
  isBeforeRequiredItemEnteredStatus,
  isBeforeRequestingReviewStatus,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isOperatorRejectedSanctionedStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  isReleaseStoppedStatus,
  isCheckListWithoutBuildCompleted,
  isCheckListWithBuildCompleted,
  checkListData
} = storeToRefs(releasePlanStore);

const releasedAt = useFieldValue<number>('releasePlanDetail.plans.release.releasedAt');

const isCompleted = computed(() =>
  isPageBuild.value ? isCheckListWithBuildCompleted.value : isCheckListWithoutBuildCompleted.value
);
const currentSectionId = ref<string>();
const listGroup = ref<HTMLElement | null | undefined>(null);
const commonInfo = ref<HTMLElement | null | undefined>(null);
const maxHeight = ref(0);
const isAtBottom = ref(false);

const handleListScroll = () => {
  if (!listGroup.value) {
    return;
  }

  const { scrollTop, scrollHeight, clientHeight } = listGroup.value;
  // scroll to end of list - 10px
  isAtBottom.value = scrollHeight - scrollTop - clientHeight < 10;
};

let $stickyElement: HTMLElement | null | undefined = null;
let $parentElement: HTMLElement | null | undefined = null;
let $wrapperElement: HTMLElement | null | undefined = null;
let isSticky = false;

onMounted(() => {
  nextTick(() => {
    $stickyElement = document.getElementById(props.stickyID);
    $parentElement = $stickyElement?.parentElement;
    $wrapperElement = $stickyElement?.closest('.release-setting.studio-scrollbar-4');

    getMaxHeight();
    $wrapperElement?.addEventListener('scroll', catchIfSticky);
    window.addEventListener('resize', getMaxHeight);
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', getMaxHeight);
  $wrapperElement?.removeEventListener('scroll', catchIfSticky);
});

const getMaxHeight = () => {
  if (listGroup.value && commonInfo.value) {
    const commonUIHeight = commonInfo.value.clientHeight;
    /**
     * aside: space top + bottom : 24 + 40 = 64
     * listGroup margin top: 20
     * asign: padding top + bottom: 48
     */

    const space = 64 + 20 + 48;
    const wrapperHeight = $wrapperElement?.clientHeight ?? 0;

    maxHeight.value = wrapperHeight - commonUIHeight - space;
    setMaxHeightforList();
  }
};

const setMaxHeightforList = () => {
  if (listGroup.value) {
    if (isSticky) {
      listGroup.value.style.maxHeight = `${maxHeight.value}px`;
    } else {
      listGroup.value.style.maxHeight = 'none';
    }

    handleListScroll();
  }
};

const catchIfSticky = () => {
  if (!!$stickyElement && !!$parentElement) {
    const stickyRect = $stickyElement.getBoundingClientRect();
    const parentRect = $parentElement.getBoundingClientRect();
    isSticky = stickyRect.top > parentRect.top;

    setMaxHeightforList();
  }
};

const scrollToBottom = () => {
  if (listGroup.value) {
    listGroup.value.scrollTo({
      top: listGroup.value.scrollHeight,
      behavior: 'smooth'
    });
  }
};

const goToSection = (sectionId: string) => {
  currentSectionId.value = sectionId;
  document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
</script>

<!--ReleaseChecklistItemGroup-->
<template>
  <div class="space-y-8 border-border border-solid border-t-1 pt-12">
    <div
      class="relative flex items-center justify-between rounded-lg px-8 py-12 "
      :class="{
        'bg-[#F2F6FF] text-brand-primary': isCompleted,
        'text-on-surface-elevation-1 hover:bg-[#F7FAFD] hover:text-on-surface-elevation-1': !isCompleted
      }"
    >
      <span class="flex-1 flex gap-8 items-center cursor-pointer" @click="emits('clickTitle', true)">
        <s-icon :icon="iconClass" size="3xl" class="shrink-0" />
        <s-text
          as="span"
          role="title7"
          class="flex-1 flex gap-4 items-start justify-start !font-bold"
        >
          {{ releaseChecklistTitle }}
          <s-icon
            v-if="isRequired"
            icon="ic-v2-control-required-fill"
            size="sm"
            class="text-tint-red-a400"
          />
        </s-text>
      </span>
      <s-button
        v-if="reloadId"
        icon="ic-v2-control-refresh-line"
        size="xl"
        class="absolute z-[20] right-8 flex items-center justify-center w-24 h-24 rounded-full text-on-surface-elevation-2 hover:bg-[#ECF0F3]"
        @click="reloadData"
      />
    </div>
    <ul class="space-y-8 px-8">
      <slot></slot>
    </ul>
  </div>
</template>
<script setup lang="ts">
import type { AllIcons } from '@stove-ui/vue';

const props = defineProps<{
  isRequired?: boolean;
  releaseChecklistTitle: string;
  isCompleted: boolean;
  iconClass: AllIcons;
  reloadId?: string;
}>();

const emits = defineEmits<{
  clickTitle: [boolean];
}>();

const reloadData = () => {
  if (!props.reloadId) {
    return;
  }
  const button = document.getElementById(props.reloadId)?.querySelector('.release-reload-btn') as HTMLElement;
  if (button) {
    button?.click();
  }
};
</script>

<template>
  <st-box :id="RELEASE_CHECKLIST_ID.PURCHASE_CONDITIONS" class="mt-24 !p-[3rem]">
    <folding-release
      :settingTitle="$t('studio.prj_prod.this_prod.release_details_pur_cond_title')"
      @onReload="fetchData"
    >
      <setting-item-title
        useButton
        :settingTitle="$t('studio.prj_prod.this_prod.release_details_pur_cond_setting_title')"
      >
        <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
          {{ $t('studio.prj_prod.this_prod.release_details_pur_cond_setting_msg1') }}
          <br />
          {{ $t('studio.prj_prod.this_prod.release_details_pur_cond_setting_msg2') }}
        </p>
        <template #button>
          <s-button
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="openPurchaseConditionPage"
          >
            {{ $t('studio.prj_prod.this_prod.release_details_pur_cond_setting_redirect_btn') }}
          </s-button>
        </template>
      </setting-item-title>
      <setting-content useTypeList>
        <div class="flex flex-col gap-y-8">
          <template v-if="purchaseCondition?.liveYn === Confirmation.YES">
            <setting-content-item
              :settingContentItemTitle="
                $t('studio.prj_prod.this_prod.release_details_pur_cond_setting_cond_type_title')
              "
              :settingContentItemValue="
                $t(
                  `studio.prj_prod.this_prod.release_details_pur_cond_setting_cond_type_this_type_${
                    purchaseCondition?.precessionType === PRODUCT_CONDITION_TYPE.OR ? 'or' : 'and'
                  }`
                )
              "
            />
            <setting-content-item
              v-for="(item, index) in purchaseCondition?.precessionList"
              :key="index"
              typeBig
              :settingContentItemTitle="`[${item.groupName}]`"
              :settingContentItemValue="getPurchaseConditionContentText(item)"
            />
          </template>
          <template v-else>
            <setting-content-item
              :settingContentItemTitle="
                $t('studio.prj_prod.this_prod.release_details_pur_cond_setting_place_holder')
              "
            />
          </template>
        </div>
      </setting-content>
    </folding-release>
  </st-box>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { fetchPrecessionApi } from '@/apis/purchase-condition.api';
import StBox from '@/components/common/st-box.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import SettingContent from '@/components/release-plan/setting-content.vue';
import SettingContentItem from '@/components/release-plan/setting-content-item.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import { OPEN_TARGET_BLANK } from '@/constants/common.const';
import { PRODUCT_CONDITION_TYPE } from '@/constants/products.const';
import { RELEASE_CHECKLIST_ID } from '@/constants/release-plan.const';
import { PRODUCT_PURCHASE_CONDITION_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type {
  PrecessionDetails,
  PrecessionResponse
} from '@/types/purchase-condition/purchase-condition.response.type';
import { redirectTo } from '@/utils/common.util';

const routeParams = useRoute().params;
const productNo: string = routeParams?.productId as string;
const purchaseCondition = ref<PrecessionResponse | undefined>();

const releasePlanStore = useReleasePlanStore();

const openPurchaseConditionPage = () => {
  redirectTo(PRODUCT_PURCHASE_CONDITION_PAGE_URL, OPEN_TARGET_BLANK);
};

const getPurchaseConditionContentText = (item: PrecessionDetails) => {
  let text = '';
  if (item.projectName) {
    text += `${item.projectName} - `;
  }
  if (item.productName) {
    text += `${item.productName} `;
  }
  if (productNo) {
    text += `(${productNo})`;
  }
  return text;
};

// Init Data
const getPurchaseCondition = async () => {
  purchaseCondition.value = await fetchPrecessionApi(productNo);
  if (purchaseCondition.value) {
    releasePlanStore.setPurchaseConditionsCheckList(
      purchaseCondition.value?.liveYn === Confirmation.YES
    );
  }
};

const fetchData = async () => {
  try {
    await getPurchaseCondition();
  } catch (err) {}
};

const init = () => {
  fetchData();
};

init();
</script>

<template>
  <release-product-manage-box simpleBackground>
    <s-text as="h2" role="title5" class="!font-bold text-on-surface-elevation-2">
      {{ $t('studio.prod_home.release_option_select_guide') }}
    </s-text>
    <radio-group
      rules="required"
      name="releasePlanDetail.releaseType"
      :options="releaseTypeOptions"
      class="flex gap-16"
    >
      <template #default="{ value, label, data }">
        <release-radio
          :id="value"
          :value="value"
          :label="label"
          :radioLabelDesc="data"
          :isDisabled="
            (isDisabledWithoutRelease && releaseType !== value) ||
              (isReleaseLiveStatus && value === PRODUCT_LAUNCH_TYPE.PAGE && !isOnlyPageOpenReleased)
          "
          @click="
            onClick(
              $event,
              (isDisabledWithoutRelease && releaseType !== value) ||
                (isReleaseLiveStatus &&
                  value === PRODUCT_LAUNCH_TYPE.PAGE &&
                  !isOnlyPageOpenReleased) ||
                releaseType === value,
              releaseType === value
            )
          "
        />
      </template>
    </radio-group>
  </release-product-manage-box>
  <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.PREPARE_PRODUCT_PAGE"></div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import ReleaseProductManageBox from '@/components/release-plan/release-product-manage-box.vue';
import ReleaseRadio from '@/components/release-plan/release-radio.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import {PRODUCT_LAUNCH_TYPE, RELEASE_CHECKLIST_ID_NAVIGATOR} from '@/constants/release-plan.const';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { FormOption } from '@/types/common/form.type';

const props = defineProps<{
  isPageBuild: boolean;
  isOnlyPageOpenReleased: boolean;
  isReleaseOnlyProductPage: boolean;
}>();

const emits = defineEmits<{
  onChangeWhenReleaseOnlyProductPage: [value: string];
}>();

const { t } = useI18n();

const releasePlanStore = useReleasePlanStore();
const {
  isOfficialReleased,
  isBeforeRequiredItemEnteredStatus,
  isBeforeRequestingReviewStatus,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  isReleaseStoppedStatus
} = storeToRefs(releasePlanStore);

const releaseTypeOptions: FormOption[] = [
  // Release the product page and build together.
  {
    label: t('studio.prj_prod.this_prod.release_details_with_build_title'),
    value: PRODUCT_LAUNCH_TYPE.PAGE_BUILD,
    data: t('studio.prj_prod.this_prod.release_details_with_build_msg')
  },
  // Just open the product page.
  {
    label: t('studio.prj_prod.this_prod.release_details_page_only_title'),
    value: PRODUCT_LAUNCH_TYPE.PAGE,
    data: t('studio.prj_prod.this_prod.release_details_page_only_msg')
  }
];

const releaseType = useFieldValue<string>('releasePlanDetail.releaseType');

const isDisabledWithoutRelease = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReviewCompletedStatus.value ||
    isOfficialReleased.value
  );
});

const onClick = async (event: PointerEvent, isDisabled: boolean, isCurrent: boolean) => {
  if (isDisabled) {
    return;
  }
  if (
    isBeforeRequestingReviewStatus &&
    releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
    !isCurrent
  ) {
    event.preventDefault();
    emits('onChangeWhenReleaseOnlyProductPage', PRODUCT_LAUNCH_TYPE.PAGE_BUILD);
    return;
  }
  if (props.isReleaseOnlyProductPage) {
    if (
      releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE &&
      (isReleaseLiveStatus.value || isReleaseStoppedStatus.value)
    ) {
      event.preventDefault();
      emits('onChangeWhenReleaseOnlyProductPage', PRODUCT_LAUNCH_TYPE.PAGE);
    } else if (
      releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
      (isBeforeRequiredItemEnteredStatus.value || isBeforeRequestingReviewStatus.value)
    ) {
      event.preventDefault();
      emits('onChangeWhenReleaseOnlyProductPage', PRODUCT_LAUNCH_TYPE.PAGE_BUILD);
    }
  }
};
</script>

<template>
  <st-box :id="RELEASE_CHECKLIST_ID.RELEASE_PLAN" class="mt-24 !p-[3rem]">
    <folding-release
      required
      openDetail
      :settingTitle="$t('studio.prj_prod.this_prod.release_details_release_plan_title')"
      :isHiddenReload="true"
    >
      <!--Section 4-->
      <setting-item-title
        :id="RELEASE_CHECKLIST_ID.STORE_PAGE_OPEN"
        :settingTitle="$t('studio.home.release_timeline.prod_pg_item.release_plan_prod_page_open')"
      >
        <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
          {{ $t('studio.prj_prod.this_prod.release_details_release_plan_product_page_open_msg') }}
        </p>
      </setting-item-title>
      <release-setting-box class="mt-8">
        <setting-sub-title
          useButton
          :settingSubTitle="
            $t(
              'studio.prj_prod.this_prod.release_details_release_plan_product_page_open_date_title'
            )
          "
          :buttonName="$t('studio.timezone_preview_view_btn')"
          :settingSubTitleDesc="utcTimezone"
          @onClick="showMajorCitiesUtcDialog(pageOpenedAt)"
        />
        <datetime-picker
          name="releasePlanDetail.plans.page.pageOpenedAt"
          :disabledDates="disabledDates"
          :isDisabled="isDisabledOpenProductPage"
          :containerClass="'mt-8 py-16 px-24 flex items-center gap-16 bg-[var(--stds-glob-color-gray20)] rounded-xl'"
          isReplaceContainerClass
          hiddenUtc
        />
        <s-box v-if="isPageOpenedAtError" class="flex gap-x-2 text-error mt-8">
          <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" />
          <s-text role="cap1">{{ PRODUCT_PAGE_OPEN_DATE_ERROR[isPageOpenedAtError] }}</s-text>
        </s-box>
      </release-setting-box>

      <div v-show="isPageBuild">
        <template v-if="!releasePlanStore.isCheckListHidden(checkListData.prePurchase)">
          <setting-item-title
            :id="RELEASE_CHECKLIST_ID.PRE_PURCHASE"
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_title')
            "
            class="mt-24"
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_msg') }}
            </p>
          </setting-item-title>
          <release-setting-box class="mt-8">
            <radio-group
              name="releasePlanDetail.plans.prePurchase.prePurchaseYn"
              class="flex gap-24"
              :disabled="isDisabledPrePurchaseYn"
              :options="prePurchaseYnOptions"
              :optionProps="{
                size: 'sm',
                align: 'middle'
              }"
            />
            <div v-show="prePurchaseYn === Confirmation.YES">
              <div v-show="!isDemo">
                <setting-sub-title
                  class="mt-20"
                  :settingSubTitle="
                    $t(
                      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_schedule_setting_title'
                    )
                  "
                  :settingSubTitleDesc="utcTimezone"
                />
                <div
                  class="mt-8 py-16 px-24 flex items-center bg-[var(--stds-glob-color-gray20)] rounded-xl"
                >
                  <datetime-picker
                    name="releasePlanDetail.plans.prePurchase.prePurchaseSchedule.startedAt"
                    :isDisabled="isDisabledPrePurchaseDate || isPrePurchaseStartedAtDateOverDateNow"
                    :disabledDates="disabledPrePurchaseStartDate"
                    :containerClass="'flex items-center gap-16'"
                    isReplaceContainerClass
                    hiddenUtc
                  />
                  <span class="px-16 text-md font-bold text-on-surface-elevation-2 leading-lg">
                    ~
                  </span>
                  <datetime-picker
                    name="releasePlanDetail.plans.prePurchase.prePurchaseSchedule.endedAt"
                    :isDisabled="isDisabledPrePurchaseDate || isPrePurchaseStartedAtDateOverDateNow"
                    :disabledDates="disabledPrePurchaseEndDate"
                    :containerClass="'flex items-center gap-16'"
                    isReplaceContainerClass
                    hiddenUtc
                  />
                </div>
                <s-box v-if="isPrePurchaseError" class="flex gap-x-2 text-error mt-8">
                  <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" />
                  <s-text role="cap1">{{ PRE_PURCHASE_DATE_ERROR[isPrePurchaseError] }}</s-text>
                </s-box>
              </div>
              <template v-if="currentProductDetailType !== PRODUCT_TYPE_DETAIL.CONTENT_BASIC">
                <setting-sub-title
                  :settingSubTitle="
                    $t(
                      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_download_and_installation_title'
                    )
                  "
                  class="mt-20"
                />
                <radio-group
                  name="releasePlanDetail.plans.prePurchase.downloadInstallYn"
                  class="flex gap-24 mt-8"
                  :disabled="isDisabledPrePurchaseYn"
                  :options="downloadInstallYnOptions"
                  :optionProps="{
                    size: 'sm',
                    align: 'middle'
                  }"
                />
                <setting-sub-title
                  :settingSubTitle="
                    $t(
                      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_title'
                    )
                  "
                  class="mt-20"
                />
                <radio-group
                  name="releasePlanDetail.plans.prePurchase.playOption"
                  class="flex gap-24 mt-8"
                  :disabled="isDisabledPrePurchaseYn"
                  :optionProps="{
                    size: 'sm',
                    align: 'middle'
                  }"
                  :options="playDownloadOptions"
                >
                  <template #default="{ value, label, data }">
                    <s-radio :id="value" :value="value" size="sm" align="middle">
                      <span v-if="data" class="flex items-center gap-4">
                        {{ label }}
                        <s-tooltip
                          arrow
                          :content="`<p class='text-center'>${data}</p>`"
                          :duration="0"
                          useFlip
                          flipOnUpdate
                          placement="bottom"
                          trigger="mouseenter focus"
                          :theme="'studio-tooltip'"
                          :zIndex="2501"
                          :allowHTML="true"
                          class="h-20 flex items-center"
                        >
                          <template #target>
                            <s-icon
                              icon="ic-v2-state-info-circle-line"
                              size="xl"
                              class="text-on-surface-elevation-4"
                            />
                          </template>
                        </s-tooltip>
                      </span>
                      <template v-else>
                        {{ label }}
                      </template>
                    </s-radio>
                  </template>
                </radio-group>
                <release-upload-file
                  v-if="playOption === PLAY_OPTION.STOVE_MEMBER || playOption === PLAY_OPTION.IP"
                  :playOption="playOption"
                  :isDisabled="isDisabledPrePurchaseYn"
                />
              </template>
            </div>
          </release-setting-box>
        </template>

        <setting-item-title
          :id="RELEASE_CHECKLIST_ID.OFFICIAL_RELEASE"
          :settingTitle="
            $t('studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_title')
          "
          class="mt-24"
        >
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{ $t('studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_msg') }}
          </p>
        </setting-item-title>
        <release-setting-box class="mt-8">
          <setting-sub-title
            :settingSubTitle="
              $t(
                'studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_release_option'
              )
            "
          />
          <div class="mt-8">
            <div class="relative w-[36rem]">
              <dropdown
                class="w-full"
                name="releasePlanDetail.plans.release.releaseTimeFormat"
                :disabled="
                  (isDisabledDateTimePicker && !isOnlyPageOpenReleased) ||
                    isOfficialReleased ||
                    (isReleaseAtDateOverDateNow && !isOperatorRejectedSanctionedStatus)
                "
                :options="releaseDateDisplayOpts"
                :dropdownProps="{
                  size: 'lg',
                  variant: 'line',
                  distance: 0,
                  offset: [0, 0],
                  placement: 'bottom-start'
                }"
              />
            </div>
          </div>

          <setting-sub-title
            useButton
            class="mt-20"
            :settingSubTitle="
              $t(
                'studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_release_date_title'
              )
            "
            :buttonName="$t('studio.timezone_preview_view_btn')"
            :settingSubTitleDesc="utcTimezone"
            @onClick="showMajorCitiesUtcDialog(releasedAt)"
          >
            <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
              {{
                $t(
                  'studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_release_date_msg'
                )
              }}
            </p>
          </setting-sub-title>
          <div
            class="mt-8 py-16 px-24 flex items-center bg-[var(--stds-glob-color-gray20)] rounded-xl"
          >
            <datetime-picker
              name="releasePlanDetail.plans.release.releasedAt"
              :containerClass="'flex items-center gap-16'"
              :isDisabled="
                isDisabledDateTimePicker ||
                  isOfficialReleased ||
                  (isReleaseAtDateOverDateNow && !isOperatorRejectedSanctionedStatus)
              "
              :disabledDates="disabledReleaseAt"
              isReplaceContainerClass
              hiddenUtc
            />
          </div>
          <s-box v-if="isReleaseAtError" class="flex gap-x-2 text-error mt-8">
            <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" />
            <s-text role="cap1">{{ RELEASE_DATE_ERROR[isReleaseAtError] }}</s-text>
          </s-box>
        </release-setting-box>
      </div>
      <!--Section 5-->
      <setting-item-title
        :settingTitle="
          $t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_title')
        "
        class="mt-24"
      >
        <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
          {{ $t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_msg') }}
        </p>
      </setting-item-title>
      <release-info class="mt-8">
        <template v-if="releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD">
          <release-info-item
            :disabled="isOfficialReleased"
            complete
            :stateMessage="
              $t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_present')
            "
            notDateTime
          />
          <release-info-item
            :disabled="isOfficialReleased"
            :complete="!!pageOpenedAt && !isPageOpenedAtError"
            :stateMessage="
              $t(
                'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_prod_pg_open'
              )
            "
            :startDateTime="getDateTimeByLocale(pageOpenedAt)"
            :isInvalidStartDateTime="!pageOpenedAt || !!isPageOpenedAtError"
            :error="!pageOpenedAt || !!isPageOpenedAtError"
            :errorMessage="
              isPageOpenedAtError ? PRODUCT_PAGE_OPEN_DATE_ERROR[isPageOpenedAtError] : ''
            "
          />
          <release-info-item
            v-if="earlyAccessStatus === Confirmation.YES"
            :disabled="isOfficialReleased"
            :complete="
              !earlyAccessCheckListTextError &&
                !!earlyAccessBeginDateValue &&
                !!earlyAccessEndDateValue
            "
            :stateMessage="
              $t(
                'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_early_access_display_on'
              )
            "
            :startDateTime="getDateTimeByLocale(earlyAccessBeginDateValue)"
            :isInvalidStartDateTime="
              isEarlyAccessError === 1 || isEarlyAccessError === 2 || isEarlyAccessError === 5
            "
            :endDateTime="getDateTimeByLocale(earlyAccessEndDateValue)"
            :isInvalidEndDateTime="
              isEarlyAccessError === 3 || isEarlyAccessError === 4 || isEarlyAccessError === 5
            "
            :error="!!isEarlyAccessError"
            :errorMessage="earlyAccessCheckListTextError ?? ''"
          />
          <release-info-item
            v-if="prePurchaseYn === Confirmation.YES"
            :disabled="isOfficialReleased"
            :complete="!isPrePurchaseError && !!prePurchaseStartDate && !!prePurchaseEndDate"
            :stateMessage="
              $t(
                'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_pre_order'
              )
            "
            :startDateTime="getDateTimeByLocale(prePurchaseStartDate)"
            :isInvalidStartDateTime="
              isPrePurchaseError === 1 || isPrePurchaseError === 2 || isPrePurchaseError === 5
            "
            :endDateTime="getDateTimeByLocale(prePurchaseEndDate)"
            :isInvalidEndDateTime="
              isPrePurchaseError === 3 || isPrePurchaseError === 4 || isPrePurchaseError === 5
            "
            :error="!!isPrePurchaseError"
            :errorMessage="isPrePurchaseError ? PRE_PURCHASE_DATE_ERROR[isPrePurchaseError] : ''"
          />
          <release-info-item
            :disabled="isOfficialReleased"
            :complete="!!releasedAt && !isReleaseAtError"
            :stateMessage="
              $t(
                'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_official_release'
              )
            "
            :startDateTime="getDateTimeByLocale(releasedAt)"
            :isInvalidStartDateTime="!!isReleaseAtError"
            :error="!!isReleaseAtError"
            :errorMessage="isReleaseAtError ? RELEASE_DATE_ERROR[isReleaseAtError] : ''"
          />
          <release-info-item
            v-if="releaseDiscountYn === Confirmation.YES"
            :disabled="isOfficialReleased"
            :complete="!!releaseDiscountDays"
            :stateMessage="
              $t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_discount')
            "
            :startDateTime="`${getDateTimeByLocale(releasedAt)}`"
            :endDateTime="`${
              releaseDiscountDays
                ? getDateTimeByLocale(
                  formatToDateTime(releasedAt)
                    .plus({ days: releaseDiscountDays ?? 0 })
                    .toJSDate()
                )
                : ''
            }`"
            :isInvalidEndDateTime="!releaseDiscountDays"
            :error="!releaseDiscountDays"
            :errorMessage="
              !releaseDiscountDays
                ? $t('studio.group.collection.register_discount_period_req_msg')
                : ''
            "
          />
        </template>
        <template v-else>
          <release-info-item
            :disabled="isOfficialReleased"
            complete
            :stateMessage="
              $t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_present')
            "
          />
          <release-info-item
            :disabled="isOfficialReleased"
            :complete="!!pageOpenedAt && !isPageOpenedAtError"
            :stateMessage="
              $t(
                'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_prod_pg_open'
              )
            "
            :startDateTime="getDateTimeByLocale(pageOpenedAt)"
            :isInvalidStartDateTime="!pageOpenedAt || !!isPageOpenedAtError"
            :error="!pageOpenedAt || !!isPageOpenedAtError"
            :errorMessage="
              isPageOpenedAtError ? PRODUCT_PAGE_OPEN_DATE_ERROR[isPageOpenedAtError] : ''
            "
          />
        </template>
      </release-info>
    </folding-release>
  </st-box>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, toRefs, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import StBox from '@/components/common/st-box.vue';
import MajorCitiesUtcDialog from '@/components/release-plan/dialog/major-cities-utc-dialog.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import ReleaseInfo from '@/components/release-plan/release-info.vue';
import ReleaseInfoItem from '@/components/release-plan/release-info-item.vue';
import ReleaseSettingBox from '@/components/release-plan/release-setting-box.vue';
import ReleaseUploadFile from '@/components/release-plan/release-upload-file.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import SettingSubTitle from '@/components/release-plan/setting-sub-title.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showDialog } from '@/composables/useDialog';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import {
  PLAY_OPTION,
  PRODUCT_LAUNCH_TYPE,
  RELEASE_CHECKLIST_ID,
  RELEASE_TIME_FORMAT
} from '@/constants/release-plan.const';
import { Confirmation } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { FormOption } from '@/types/common/form.type';
import type { ReleasePlanInformation } from '@/types/release-plan/release-plan.type';
import {
  formatToDateTime,
  formatUnknownSrcToMillis,
  getDateTimeByLocale,
  getFormatByLocale,
  getFormatDateMonthByLocale,
  getUtcTimezone
} from '@/utils/date.util';

const props = defineProps<{
  isPageBuild: boolean;
  releasePlanInformation: ReleasePlanInformation;
  isReleaseOnlyProductPage: boolean;
  isOnlyPageOpenReleased: boolean;
}>();
const { isPageBuild, releasePlanInformation, isReleaseOnlyProductPage } = toRefs(props);

const releasePlanStore = useReleasePlanStore();
const {
  isOfficialReleased,
  isPrePurchaseStartedAtDateOverDateNow,
  isReleaseAtDateOverDateNow,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isOperatorRejectedSanctionedStatus,
  isReleaseRestrictStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  isReleaseStoppedStatus,
  storeReleasePlanInformation,
  earlyAccessCheckListTextError,
  checkListData
} = storeToRefs(releasePlanStore);

const productStore = useProductStore();
const { currentProductDetailType } = storeToRefs(productStore);

const { t } = useI18n();

const releaseType = useFieldValue<string>('releasePlanDetail.releaseType');

const PRE_PURCHASE_DATE_ERROR: Record<number, string> = {
  1: t('studio.release_details.period_settings.end_before_start_val_msg1'),
  2: t('studio.release_details.period_settings.start_date_before_open_val_msg2'),
  3: t('studio.release_details.period_settings.end_date_input_n_val_msg3'),
  4: t('studio.release_details.period_settings.end_date_after_official_release_val_msg4'),
  5: t('studio.release_info_settings.cannot_set_pre_purchase_msg')
};
const RELEASE_DATE_ERROR: Record<number, string> = {
  1: t('studio.event_period_settings.case_pp_open_after_official_release'),
  2: t('studio.calendar_event_period.val_msg_start_time_before_current_time')
};
const PRODUCT_PAGE_OPEN_DATE_ERROR: Record<number, string> = {
  1: t('studio.calendar_event_period.val_msg_start_time_before_current_time')
};
const prePurchaseYnOptions: FormOption[] = [
  {
    value: Confirmation.YES,
    label: t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_radio_enable_msg')
  },
  {
    value: Confirmation.NO,
    label: t(
      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_radio_disable_msg'
    )
  }
];
const downloadInstallYnOptions: FormOption[] = [
  {
    value: Confirmation.YES,
    label: t(
      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_download_and_installation_allow'
    )
  },
  {
    value: Confirmation.NO,
    label: t(
      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_download_and_installation_not_allow'
    )
  }
];
const playDownloadOptions: FormOption[] = [
  {
    value: PLAY_OPTION.ALL,
    label: t(
      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_allow_all'
    ),
    data: ''
  },
  // {
  //   value: PLAY_OPTION.STOVE_MEMBER,
  //   label: t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_allow_by_stove_mem_no'),
  //   data: t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_stove_mem_no_reg_tooltip')
  // },
  // {
  //   value: PLAY_OPTION.IP,
  //   label: t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_allow_by_ip_address'),
  //   data: t('studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_ip_address_reg_tooltip')
  // },
  {
    value: PLAY_OPTION.NONE,
    label: t(
      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_not_allow'
    )
  }
];

const releasedAt = useFieldValue<number | Date>('releasePlanDetail.plans.release.releasedAt');
const playOption = useFieldValue<string>('releasePlanDetail.plans.prePurchase.playOption');
const setPrePurchaseEndDate = useSetFieldValue<number | null>(
  'releasePlanDetail.prePurchaseEndDate'
);
const prePurchaseYn = useFieldValue<string>('releasePlanDetail.plans.prePurchase.prePurchaseYn');
const prePurchaseStartDate = useFieldValue<number | Date>(
  'releasePlanDetail.plans.prePurchase.prePurchaseSchedule.startedAt'
);
const prePurchaseEndDate = useFieldValue<number | Date>(
  'releasePlanDetail.plans.prePurchase.prePurchaseSchedule.endedAt'
);
const pageOpenedAt = useFieldValue<number | Date>('releasePlanDetail.plans.page.pageOpenedAt');
const earlyAccessStatus = useFieldValue<string>('releasePlanDetail.plans.page.earlyAccessYn');
const earlyAccessBeginDateValue = useFieldValue<number | Date>(
  'releasePlanDetail.plans.page.earlyAccessSchedule.startedAt'
);
const earlyAccessEndDateValue = useFieldValue<number | Date>(
  'releasePlanDetail.plans.page.earlyAccessSchedule.endedAt'
);
const releaseDiscountYn = useFieldValue<Confirmation | undefined>(
  'releasePlanDetail.plans.release.sale.releaseDiscountYn'
);
const releaseDiscountDays = useFieldValue<number>(
  'releasePlanDetail.plans.release.sale.releaseDiscountDays'
);

const utcTimezone = `(${getUtcTimezone()})`;
const isDemo = ref(false);
const isPrePurchaseError = ref<number | null>();
const isReleaseAtError = ref<number | null>();
const isPageOpenedAtError = ref<number | null>();
const isDisabledOpenProductPage = computed(
  () =>
    isDisabledDateTimePicker.value || isReleaseOnlyProductPage.value || isReleaseLiveStatus.value
);
const isPageVerified = computed(
  () =>
    storeReleasePlanInformation.value?.pageVerifiedYn === Confirmation.YES &&
    storeReleasePlanInformation.value?.releaseVerifiedYn === Confirmation.NO
);

const isDisabledDateTimePicker = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReleaseStoppedStatus.value ||
    isReleaseRestrictStatus.value
  );
});
const isDisabledPrePurchaseYn = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReleaseLiveStatus.value ||
    isReleaseStoppedStatus.value ||
    isReviewCompletedStatus.value ||
    isReleaseRestrictStatus.value
  );
});

const isDisabledPrePurchaseDate = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isOfficialReleased.value ||
    isReleaseStoppedStatus.value ||
    isReleaseRestrictStatus.value
  );
});
const disabledDates = computed(() => {
  if (
    releasePlanInformation.value?.plans?.page?.pageOpenedAt &&
    !isOperatorRejectedSanctionedStatus.value
  ) {
    return {
      to: formatToDateTime(new Date()).startOf('day').toJSDate()
    };
  }
  return {
    to: DateTime.local().startOf('day').plus({ day: 7 }).toJSDate()
  };
});
const disabledPrePurchaseStartDate = computed(() => {
  const toDate = isPageVerified.value
    ? formatToDateTime(new Date()).plus({ days: 7 }).startOf('day').toJSDate()
    : formatToDateTime(pageOpenedAt.value).startOf('day').toJSDate();
  const to =
    formatUnknownSrcToMillis(toDate) < Date.now()
      ? formatToDateTime(new Date()).startOf('day').toJSDate()
      : toDate;
  return {
    to,
    from: formatToDateTime(releasedAt.value).endOf('day').toJSDate()
  };
});
const disabledPrePurchaseEndDate = computed(() => {
  return {
    to: formatToDateTime(prePurchaseStartDate.value ?? new Date())
      .startOf('day')
      .toJSDate(),
    from: formatToDateTime(releasedAt.value).endOf('day').toJSDate()
  };
});
const disabledReleaseAt = computed(() => {
  const toDate = isPageVerified.value
    ? formatToDateTime(new Date()).plus({ days: 7 }).startOf('day').toJSDate()
    : formatToDateTime(pageOpenedAt.value).startOf('day').toJSDate();
  const to =
    formatUnknownSrcToMillis(toDate) < Date.now()
      ? formatToDateTime(new Date()).startOf('day').toJSDate()
      : toDate;
  return {
    to
  };
});
const releaseDateDisplayOpts = computed(() => {
  const releaseDate = DateTime.fromJSDate(new Date(releasedAt.value));
  return [
    {
      label: t('studio.group.collection.register_plan_official_release_date_option_def_value'),
      value: RELEASE_TIME_FORMAT.COMING_SOON
    },
    {
      label: releaseDate.toFormat(getFormatByLocale(false)),
      value: RELEASE_TIME_FORMAT.YEAR_MONTH_DAY
    },
    {
      label: releaseDate.toFormat(getFormatDateMonthByLocale()),
      value: RELEASE_TIME_FORMAT.YEAR_MONTH
    },
    {
      label: `${t(
        'studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_release_option_dbox_opt4_quarter',
        { quarter: releaseDate.quarter, relYear: releaseDate.toFormat('yyyy') }
      )}`,
      value: RELEASE_TIME_FORMAT.YEAR_QUARTER
    },
    { label: releaseDate.toFormat('yyyy'), value: RELEASE_TIME_FORMAT.YEAR }
  ];
});
const isEarlyAccessError = computed(() => {
  switch (earlyAccessCheckListTextError.value) {
    case t('studio.release_details.period_settings.end_before_start_val_msg1'):
      return 1;
    case t('studio.release_details.period_settings.start_date_before_open_val_msg2'):
      return 2;
    case t('studio.release_details.period_settings.end_date_input_n_val_msg3'):
      return 3;
    case t('studio.release_details.period_settings.end_date_after_official_release_val_msg4'):
      return 4;
    case t('studio.release_info_settings.cannot_set_early_access_msg'):
      return 5;
    default:
      return null;
  }
});

const showMajorCitiesUtcDialog = async (rootDate: Date | number) => {
  const props = {
    rootDate: new Date(rootDate) || new Date()
  };
  await showDialog({
    component: shallowRef(MajorCitiesUtcDialog),
    props: {
      ...props
    }
  });
};

watchEffect(() => {
  const releaseDateMillis = formatUnknownSrcToMillis(releasedAt.value);
  const pageOpenAtMillis = formatUnknownSrcToMillis(pageOpenedAt.value);
  const beginDateJS = formatUnknownSrcToMillis(prePurchaseStartDate.value);
  const endDateJS = formatUnknownSrcToMillis(prePurchaseEndDate.value);

  // If PrePurchase is non-active
  if (prePurchaseYn.value === Confirmation.NO) {
    releasePlanStore.setPrePurchaseCheckListCompleted(false);
    isPrePurchaseError.value = null;
    return;
  }

  // If PrePurchase is active
  if (pageOpenAtMillis === releaseDateMillis) {
    isPrePurchaseError.value = 5;
    releasePlanStore.setPrePurchaseCheckListError();
    return;
  }
  if (!prePurchaseEndDate.value) {
    isPrePurchaseError.value = 3;
    releasePlanStore.setPrePurchaseCheckListError();
    return;
  }
  if (beginDateJS >= endDateJS) {
    isPrePurchaseError.value = 1;
    releasePlanStore.setPrePurchaseCheckListError();
    return;
  }
  if (beginDateJS < pageOpenAtMillis) {
    isPrePurchaseError.value = 2;
    releasePlanStore.setPrePurchaseCheckListError();
    return;
  }
  if (endDateJS > releaseDateMillis) {
    isPrePurchaseError.value = 4;
    releasePlanStore.setPrePurchaseCheckListError();
    return;
  }
  isPrePurchaseError.value = null;
  releasePlanStore.setPrePurchaseCheckListCompleted(true);
});

watchEffect(() => {
  if (
    releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE ||
    isOfficialReleased.value ||
    isReleaseAtDateOverDateNow.value
  ) {
    releasePlanStore.setOfficialReleaseCheckListCompleted(true);
    return;
  }
  if (
    formatUnknownSrcToMillis(pageOpenedAt.value) > formatUnknownSrcToMillis(releasedAt.value) &&
    releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD
  ) {
    isReleaseAtError.value = 1;
    releasePlanStore.setOfficialReleaseCheckListError();
    return;
  }
  if (Date.now() >= formatUnknownSrcToMillis(releasedAt.value)) {
    isReleaseAtError.value = 2;
    releasePlanStore.setOfficialReleaseCheckListError();
    return;
  }
  isReleaseAtError.value = null;
  releasePlanStore.setOfficialReleaseCheckListCompleted(!!releasedAt.value);
});

watch(
  () => prePurchaseStartDate.value,
  () => {
    if (!prePurchaseStartDate.value || !prePurchaseEndDate.value) {
      return;
    }
    const beginDateJS = formatToDateTime(prePurchaseStartDate.value);
    const endDateJS = formatToDateTime(prePurchaseEndDate.value);
    if (beginDateJS.diff(endDateJS, 'days').days > 0) {
      setPrePurchaseEndDate(null);
    }
  }
);

watch(
  () => releasedAt.value,
  () => {
    releasePlanStore.setOfficialReleaseCheckListCompleted(!!releasedAt.value);
  },
  { immediate: true }
);

watch(
  () => pageOpenedAt.value,
  () => {
    if (isDisabledOpenProductPage.value) {
      isPageOpenedAtError.value = null;
      releasePlanStore.setStorePageOpenCheckList(!!pageOpenedAt.value);
      return;
    }
    if (
      !isReleaseStoppedStatus.value &&
      !isReleaseLiveStatus.value &&
      !isReleaseRestrictStatus.value &&
      Date.now() >= formatUnknownSrcToMillis(pageOpenedAt.value)
    ) {
      isPageOpenedAtError.value = 1;
      releasePlanStore.setStorePageOpenCheckListError();
      return;
    }
    isPageOpenedAtError.value = null;
    releasePlanStore.setStorePageOpenCheckList(!!pageOpenedAt.value);
  },
  { immediate: true }
);
</script>

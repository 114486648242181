<template>
  <st-box v-show="isPageBuild" :id="RELEASE_CHECKLIST_ID.BUILD_PREPARATION" class="mt-24 !p-[3rem]">
    <folding-release
      required
      :isError="!!dataRejectBuildRating?.length && isOperatorRejectedSanctionedStatus"
      :settingTitle="$t('studio.prj_prod.this_prod.release_details_build_title')"
      @onReload="handleReload"
    >
      <setting-item-title
        :id="RELEASE_CHECKLIST_ID.EXECUTION_SETTINGS"
        useButton
        :settingTitle="$t('studio.prj_prod.this_prod.release_details_build_execution_title')"
      >
        <template #default>
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{ $t('studio.prj_prod.this_prod.release_details_build_execution_msg') }}
          </p>
        </template>
        <template #button>
          <s-button
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="openExecutionSettingPage"
          >
            {{ $t('studio.prj_prod.this_prod.release_details_build_execution_redirect_btn') }}
          </s-button>
        </template>
      </setting-item-title>
      <setting-content
        :isError="!executionSettingsCheckCompleted"
        :settingContentDesc="
          isRunSetting
            ? $t('studio.prj_prod.this_prod.release_details_build_execution_place_holder2')
            : $t('studio.prj_prod.this_prod.release_details_build_execution_place_holder1')
        "
      />
      <setting-item-title
        :id="RELEASE_CHECKLIST_ID.UPLOAD_BUILD_FILE"
        useButton
        class="mt-24"
        :settingTitle="
          $t('studio.prj_prod.this_prod.release_details_build_and_rating_review_title')
        "
      >
        <template #default>
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{ $t('studio.prj_prod.this_prod.release_details_build_and_rating_review_msg1') }}
            <br />
            {{ $t('studio.prj_prod.this_prod.release_details_build_and_rating_review_msg2') }}
          </p>
        </template>
        <template #button>
          <s-button
            v-if="!isWebOrMobileOption"
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="openBuildManagementPage"
          >
            {{ $t('studio.prj_prod.build_mgmt_pg_dir_btn') }}
          </s-button>
          <s-button
            v-if="!ageRatingAndBuildCheckHidden"
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="openAgeRatingPage"
          >
            {{ $t('studio.prj_prod.rating_build_review_pg_dir_btn') }}
          </s-button>
        </template>
      </setting-item-title>
      <release-setting-box
        :id="RELEASE_CHECKLIST_ID.AGE_RATING_AND_BUILD"
        :isError="!ageRatingAndBuildCheckCompleted || !uploadBuildFileCheckCompleted"
        class="mt-20"
      >
        <!--Show when not data-->
        <dl class="flex flex-col gap-8">
          <template v-if="!ageRatingAndBuildCheckHidden">
            <div v-if="isRatingDataEmpty" class="flex items-start gap-8">
              <dd class="flex gap-8 flex-wrap">
                <span class="text-text2 leading-md text-system-red font-medium">
                  {{
                    $t(
                      'studio.prj_prod.this_prod.release_details_build_and_rating_review.rating_none_guide'
                    )
                  }}
                </span>
              </dd>
            </div>
            <!--Show when have data-->
            <template v-else>
              <div
                v-if="!requestRating && releasedRatings?.length >= 1"
                class="flex items-start gap-8"
              >
                <dt class="text-sm font-medium leading-md text-on-surface-elevation-4 shrink-0">
                  {{
                    releasedRatings[0].directYn === Confirmation.YES
                      ? $t('studio.prj_prod_mngmt.rating_build_review.type_direct_input')
                      : $t('studio.prj_prod_mngmt.rating_build_review.type_self_review')
                  }}
                </dt>
                <dd
                  v-for="releasedRating in releasedRatings"
                  :key="releasedRating.ratingId"
                  class="flex gap-8 flex-wrap"
                >
                  <span class="text-sm font-bold leading-md text-on-surface-elevation-2">
                    {{ releasedRating.ratingId }} ({{
                      t(getAgeRatingText(releasedRating.ageRating), {
                        rating: releasedRating.ageRating
                      })
                    }})
                  </span>
                </dd>
              </div>
              <!--Show when rating is self-rating-->
              <div v-if="requestRating && !releasedRatings?.length" class="flex items-start gap-8">
                <dt class="text-sm font-medium leading-md text-on-surface-elevation-4 shrink-0">
                  {{ $t('studio.prj_prod_mngmt.rating_build_review.type_self_review') }}
                </dt>
                <dd class="flex gap-8 flex-wrap">
                  <span class="text-sm font-bold leading-md text-on-surface-elevation-2">
                    {{
                      t(
                        'studio.prj_prod.this_prod.release_details_build_and_rating_review.self_review_expected_rating_guide',
                        {
                          expectedRating: t(getAgeRatingText(requestRating.ageRating), {
                            rating: requestRating.ageRating
                          })
                        }
                      )
                    }}
                  </span>
                </dd>
              </div>
              <!--Show global rating-->
              <template v-if="globalRatings?.length && (releasedRatings?.length || requestRating)">
                <div
                  v-for="(globalRatingGroupItem, index) in globalRatingGroups"
                  :key="index"
                  class="flex items-start gap-8"
                >
                  <dt class="text-sm font-medium leading-md text-on-surface-elevation-4 shrink-0">
                    {{
                      $t(
                        GLOBAL_RATING_BOARDS.find(
                          (item: GlobalRatingBoardType) => item.boardName === index
                        )!.locale
                      )
                    }}
                  </dt>
                  <dd
                    v-for="globalRating in globalRatingGroupItem"
                    :key="globalRating.ratingId"
                    class="flex gap-8 flex-wrap"
                  >
                    <span class="text-sm font-bold leading-md text-on-surface-elevation-2">
                      {{ globalRating.ratingId }} ({{
                        t(getAgeRatingText(globalRating.ageRating), {
                          rating: globalRating.ageRating
                        })
                      }})
                    </span>
                  </dd>
                </div>
              </template>
            </template>
          </template>
          <div
            v-if="!isRatingDataEmpty || !isProductGameType"
            class="relative"
            :class="{ 'mt-20': !ageRatingAndBuildCheckHidden }"
          >
            <template v-if="isWebOrMobileOption">
              <input-text
                readonly
                size="lg"
                variant="outline"
                class="!bg-[#FCFCFD] text-on-surface-elevation-4 rounded-xl"
                :modelValue="$t('studio.release_details.build_prep.no_build_list_display_guide')"
                :clearable="false"
              />
            </template>
            <template v-else>
              <dropdown
                name="releasePlanDetail.buildId"
                rules="required"
                :disabled="isDisableRatingBuild"
                :options="versionBuildOption"
                :placeholder="$t(versionBuildOptionPlaceholder)"
                :dropdownProps="{
                  size: 'lg',
                  variant: 'line',
                  distance: 0,
                  offset: [0, 0],
                  placement: 'bottom-start',
                  class: 'w-full'
                }"
              />
              <error-message
                v-if="isDrmError"
                class="mt-4 pt-12 flex gap-2 text-xs leading-xs text-error"
                as="p"
                :message="$t(DRM_ERROR_LIST[isDrmError])"
              />
            </template>
          </div>
        </dl>
      </release-setting-box>
      <template v-if="dataRejectBuildRating?.length && isOperatorRejectedSanctionedStatus">
        <reason-rejections
          v-for="(item, index) in dataRejectBuildRating"
          :key="index"
          class="mt-24"
          :rejectContentTitle="`[${item.title}]`"
          :rejectContentDesc="item.reason"
        />
      </template>
    </folding-release>
  </st-box>
</template>
<script setup lang="ts">
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import type { ComputedRef } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  fetchIsAllRunOptionApplyApi,
  fetchIsAllRunOptionTypeApi,
  findBuildReleaseEnableApi,
  getProductBuildIdApi
} from '@/apis/build.api';
import { fetchRatingsProductsApi } from '@/apis/rating.api';
import { getBuildMetaReleaseInfoApi } from '@/apis/release-plan.api';
import StBox from '@/components/common/st-box.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import ReasonRejections from '@/components/release-plan/reasons-rejection.vue';
import ReleaseSettingBox from '@/components/release-plan/release-setting-box.vue';
import SettingContent from '@/components/release-plan/setting-content.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { OPEN_TARGET_BLANK } from '@/constants/common.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { GLOBAL_RATING_BOARDS } from '@/constants/rating.const';
import {
  PRODUCT_LAUNCH_TYPE,
  RELEASE_CHECKLIST_ID,
  RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE,
  RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE
} from '@/constants/release-plan.const';
import {
  PRODUCT_BUILD_PAGE_URL,
  PRODUCT_LAUNCH_SETTING_PAGE_URL,
  PRODUCT_RATING_URL
} from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { VerifyStatus } from '@/enums/rating.enum';
import ErrorMessage from '@/markup/components/error-message/ErrorMessage.vue';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { Build, GetDetailProductBuildId, IsAllRunOptionType } from '@/types/build/build.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  GlobalRatings,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import type { GlobalRatingBoardType } from '@/types/rating/rating.type';
import type {
  RejectContent,
  ReviewRejectionInformationResponse
} from '@/types/release-plan/release-plan-response.type';
import { redirectTo } from '@/utils/common.util';
import { getAgeRatingText, getCorrectRating } from '@/utils/rating.util';

const props = defineProps<{
  isPageBuild: boolean;
  dataReject?: ReviewRejectionInformationResponse;
}>();

const routeParams = useRoute().params;
const productNo: string = routeParams?.productId as string;

const { t } = useI18n();
const releasePlanStore = useReleasePlanStore();
const {
  isBeforeRequiredItemEnteredStatus,
  isOperatorRejectedSanctionedStatus,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  isReleaseStoppedStatus,
  isReleaseRestrictStatus,
  storeReleasePlanInformation,
  checkListData
} = storeToRefs(releasePlanStore);
const productStore = useProductStore();
const { isReleased, currentProductDetailType } = storeToRefs(productStore);

const isRunSetting = ref<boolean | undefined>(false);
const isFirstTime = ref<boolean>(true);
const buildReleaseList = ref<Build[]>([]);
const releasedRatings = ref<ReleasedRatings[]>([]);
const globalRatings = ref<GlobalRatings[]>([]);
const globalRatingGroups = ref<Record<string, GlobalRatings[]>>({});
const requestRating = ref<RequestRating | null>();
const runOptionType = ref<IsAllRunOptionType>();
const isDrmError = ref<number>(0);

const isDisableRatingBuild = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReviewCompletedStatus.value ||
    isReleaseLiveStatus.value ||
    isReleaseStoppedStatus.value ||
    isReleaseRestrictStatus.value
  );
});

const executionSettingsCheckCompleted = computed(() =>
  releasePlanStore.isCheckCompleted(checkListData.value.executionSettings)
);
const ageRatingAndBuildCheckCompleted = computed(() =>
  releasePlanStore.isCheckCompleted(checkListData.value.ageRatingAndBuild)
);
const uploadBuildFileCheckCompleted = computed(() =>
  releasePlanStore.isCheckCompleted(checkListData.value.uploadBuildFile)
);

const ageRatingAndBuildCheckHidden = computed(() =>
  releasePlanStore.isCheckListHidden(checkListData.value.ageRatingAndBuild)
);

const buildId = useFieldValue<string>('releasePlanDetail.buildId');
const setBuildId = useSetFieldValue<string>('releasePlanDetail.buildId');
const releaseType = useFieldValue<string>('releasePlanDetail.releaseType');
const setGracRatingNos = useSetFieldValue<number[]>('releasePlanDetail.gracRatingNos');

const versionBuildOption = ref<FormOption[]>([]);
const versionBuildOptionPlaceholder = ref<string>(
  'studio.prj_prod.this_prod.release_details_build_and_rating_review_build_dbox_place_holder'
);
const DRM_ERROR_LIST: Record<number, string> = {
  1: 'studio.release_plan.drm_not_applied_msg',
  2: 'studio.release_plan.drm_applied_exe_not_matched_msg'
};

const isWebOrMobileOption = ref<boolean>(false);

const dataRejectBuildRating: ComputedRef<RejectContent[] | undefined> = computed(() =>
  props.dataReject?.rejectContents?.filter(
    (item: RejectContent) =>
      item.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.BUILD_RATING &&
      isOperatorRejectedSanctionedStatus.value
  )
);

const openExecutionSettingPage = () => {
  redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL, OPEN_TARGET_BLANK);
};

const openBuildManagementPage = () => {
  redirectTo(PRODUCT_BUILD_PAGE_URL, OPEN_TARGET_BLANK);
};

const openAgeRatingPage = () => {
  redirectTo(PRODUCT_RATING_URL, OPEN_TARGET_BLANK);
};

const isProductGameType = computed(
  () =>
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DLC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_BASIC
);
const isNeedCheckDRM = computed(
  () =>
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.GAME_DLC &&
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.UTILITY_BASIC &&
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.UTILITY_DLC &&
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.UTILITY_DEMO
);
const isProductUtilityType = computed(
  () =>
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DLC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DEMO
);
const isRatingDataEmpty = computed(() => !releasedRatings?.value.length && !requestRating.value);

const updateChecklistData = () => {
  releasePlanStore.setUploadingBuildFileCheckList(!!buildReleaseList.value?.length);
  releasePlanStore.setExecutionSettingsCheckList(isRunSetting.value || false);
  releasePlanStore.setAgeRatingAndBuildCheckList(
    !!buildId.value,
    isFirstTime.value ? !!dataRejectBuildRating.value?.length : false
  );
  if (
    releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
    !runOptionType.value?.isExistPcOption &&
    (runOptionType.value?.isExistMobileOption || runOptionType.value?.isExistWebOption)
  ) {
    setBuildId(RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE);
    releasePlanStore.setUploadingBuildFileCheckList(true);
    releasePlanStore.setAgeRatingAndBuildCheckList(!isRatingDataEmpty.value);
  }
};

const handleReload = async () => {
  await callDataAPI();
  await checkAgeRatingAndBuildCheckList();
};

const getRatingsProduct = async () => {
  const ratingProduct = await fetchRatingsProductsApi(productNo);
  if (ratingProduct) {
    const ratingData = isReleaseLiveStatus.value ? ratingProduct.releasedRatings : getCorrectRating(ratingProduct.requestRating, ratingProduct.releasedRatings);
    releasedRatings.value = [];
    globalRatings.value = [];
    globalRatingGroups.value = {};
    requestRating.value = null;
    if (Array.isArray(ratingData)) {
      releasedRatings.value = ratingData;
      setGracRatingNos(
        releasedRatings.value.map((item: ReleasedRatings) => item.productGracRatingNo)
      );
    } else if (ratingData?.verifyInfo?.verifyStatus === VerifyStatus.PASS) {
      requestRating.value = ratingData;
      setGracRatingNos([requestRating.value?.productGracRatingNo]);
    }
    if (!isEmpty(ratingProduct.releasedRatings) && isReleased.value) {
      globalRatings.value = ratingProduct.releasedGlobalRatings;
    } else {
      globalRatings.value = ratingProduct.requestedGlobalRatings;
    }
    globalRatingGroups.value = {};
    globalRatings.value.forEach((item: GlobalRatings) => {
      if (!globalRatingGroups.value[item.boardType]) {
        globalRatingGroups.value[item.boardType] = [];
      }
      globalRatingGroups.value[item.boardType].push(item);
    });
    releasePlanStore.setAgeRatingAndBuildCheckList(!isRatingDataEmpty.value && !!buildId.value && !!storeReleasePlanInformation.value?.build?.buildNo);
  } else {
    releasedRatings.value = [];
    globalRatings.value = [];
    globalRatingGroups.value = {};
    requestRating.value = null;
  }
};

const getProductBuildDetail = async () => {
  const params: GetDetailProductBuildId = {
    buildId: buildId.value
  };
  const res = await getProductBuildIdApi(params);
  if (res) {
    return res;
  }
};

const getRattingBuildList = async () => {
  buildReleaseList.value = [];
  if (
    isBeforeRequiredItemEnteredStatus.value ||
    isOperatorRejectedSanctionedStatus.value ||
    !buildId.value
  ) {
    buildReleaseList.value = await findBuildReleaseEnableApi(productNo);
  } else {
    const result = await getProductBuildDetail();
    if (result) {
      buildReleaseList.value = [result];
    }
  }
  if (buildReleaseList.value?.length) {
    versionBuildOption.value = buildReleaseList.value.map((build: Build) => {
      return {
        label: `${build.buildNo} ${build.buildDescription}`,
        value: build.buildId
      };
    });
    releasePlanStore.setUploadingBuildFileCheckList(
      !ageRatingAndBuildCheckHidden.value || !!buildId.value
    );
  } else {
    releasePlanStore.setUploadingBuildFileCheckList(false);
  }
};

const getIsRunSetting = async () => {
  isRunSetting.value = await fetchIsAllRunOptionApplyApi(productNo);
  releasePlanStore.setExecutionSettingsCheckList(isRunSetting.value || false);
};

const getIsRunOptionType = async () => {
  runOptionType.value = await fetchIsAllRunOptionTypeApi(productNo);
  if (runOptionType.value) {
    isWebOrMobileOption.value =
      !runOptionType.value?.isExistPcOption &&
      (runOptionType.value?.isExistMobileOption || runOptionType.value?.isExistWebOption);
    if (releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD && isWebOrMobileOption.value) {
      setBuildId(RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE);
      releasePlanStore.setUploadingBuildFileCheckList(!ageRatingAndBuildCheckHidden.value);
      releasePlanStore.setAgeRatingAndBuildCheckList(!isRatingDataEmpty.value);
      return;
    }
  }
  await getRattingBuildList();
};

const checkAgeRatingAndBuildCheckList = async (): Promise<boolean> => {
  if (
    buildId.value &&
    buildId.value !== RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE &&
    isNeedCheckDRM.value
  ) {
    isDrmError.value = 0;
    const res = await getBuildMetaReleaseInfoApi(buildId.value);
    if (res) {
      const { isSdkApplied, isUseDrm, runOptions } = res;
      if (!isSdkApplied) {
        if (!isUseDrm) {
          isDrmError.value = 1;
        } else if (runOptions?.length) {
          for (let i = 0; i < runOptions.length; i++) {
            if (!runOptions[i].exist) {
              isDrmError.value = 2;
              break;
            }
          }
        }
      }
    }
  }
  if (!isRatingDataEmpty.value) {
    if (runOptionType.value) {
      isWebOrMobileOption.value =
        !runOptionType.value?.isExistPcOption &&
        (runOptionType.value?.isExistMobileOption || runOptionType.value?.isExistWebOption);
      if (releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD && isWebOrMobileOption.value) {
        releasePlanStore.setAgeRatingAndBuildCheckList(!isRatingDataEmpty.value);
        return false;
      }
    }
    releasePlanStore.setAgeRatingAndBuildCheckList(
      !!buildId.value,
      isFirstTime.value ? !!dataRejectBuildRating.value?.length : false
    );
    return false;
  }
  if (buildId.value && (!isBeforeRequiredItemEnteredStatus.value || isWebOrMobileOption.value || isProductUtilityType.value)) {
    if (storeReleasePlanInformation.value?.build?.buildNo || isProductUtilityType.value) {
      releasePlanStore.setAgeRatingAndBuildCheckList(true);
    }
    releasePlanStore.setUploadingBuildFileCheckList(true);
  }
  return true;
};

const callDataAPI = async () => {
  await getRatingsProduct();
  await Promise.all([getIsRunSetting(), getIsRunOptionType()]);
};

watch(
  () => buildId.value,
  async () => {
    await checkAgeRatingAndBuildCheckList();
  },
  { immediate: true }
);

watch(
  () => releaseType.value,
  () => {
    if (releaseType.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD) {
      callDataAPI();
    } else {
      updateChecklistData();
    }
  },
  { immediate: true }
);

onMounted(() => {
  isFirstTime.value = false;
});
</script>

<template>
  <s-dialog to="studio-release-popup" :open="true">
    <s-dialog-panel class="!w-full !h-full !max-h-[initial] !rounded-none">
      <div class="flex h-full w-full flex-col">
        <release-dialog-header
          isDetail
          isShowBackBtn
          :releaseDialogTitle="
            $t('studio.prj_prod.this_prod.release_stt_browser_product_release_info_btn')
          "
        >
          <template v-if="isBeforeRequiredItemEnteredStatus || isBeforeRequestingReviewStatus">
            <s-button
              :isDisabled="!isCompletedForm || !isConfirmSubmit"
              variant="primary"
              class="w-26"
              @click="onSubmitBeforeRequired()"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.release_details_browser_request_review_pop_request_review_btn'
                )
              }}
            </s-button>
          </template>
          <template v-if="isRequestedReviewStatus">
            <s-button
              :isDisabled="isUnderReviewStatus"
              variant="primary"
              @click="onCancelRequest()"
            >
              {{
                $t('studio.prj_prod.this_prod.release_details_browser_withdraw_review_request_btn')
              }}
            </s-button>
          </template>
          <template v-if="isOperatorRejectedSanctionedStatus">
            <s-button
              :isDisabled="!isCompletedForm || !isConfirmSubmit"
              variant="primary"
              @click="onSubmitBeforeRequired()"
            >
              {{ $t('studio.prj_prod.post_release.price_update.popup_request_cf_btn') }}
            </s-button>
          </template>
          <template v-if="isReviewCompletedStatus">
            <s-button
              :isDisabled="!isCheckListWithoutBuildCompleted || isDisableButtonSaveReviewPending"
              variant="primary"
              class="w-26"
              @click="onSubmitReviewCompleted()"
            >
              {{ $t('studio.prj_prod.this_prod.release_details_save_btn') }}
            </s-button>
          </template>
          <template v-if="isReleaseLiveStatus || isReleaseStoppedStatus">
            <s-button
              v-if="!isReleaseStoppedStatus"
              :isDisabled="!isCompletedForm"
              variant="primary"
              class="w-26"
              @click="onSubmitReviewCompleted()"
            >
              {{ $t('studio.prj_prod.this_prod.release_details_save_btn') }}
            </s-button>
            <s-button
              variant="primary"
              size="md"
              @click="
                releasePlanInformation?.displayYn === Confirmation.YES
                  ? suspendProductPageOpen()
                  : reopenProductPage()
              "
            >
              {{
                releasePlanInformation?.displayYn === Confirmation.YES
                  ? $t('studio.prod_home.release_info_open_suspend_btn')
                  : $t('studio.prod_home.prod_pg_reopen_btn')
              }}
            </s-button>
            <s-button
              v-if="
                !isOnlyPageOpenReleased &&
                  releaseTypeForm === PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
                  constantReleasePlanInformation?.releaseVerifiedYn === Confirmation.YES
              "
              variant="primary"
              size="md"
              :disabled="constantReleasePlanInformation?.saleStatus === SALE_STATUS.END"
              @click="!isStopSale ? suspendSale() : resumeSale()"
            >
              {{
                !isStopSale
                  ? $t('studio.prod_home.release_info_sale_suspend_btn')
                  : $t('studio.prj_prod_mngmt.popup_restart_sale_title')
              }}
            </s-button>
          </template>
        </release-dialog-header>
        <div
          class="release-setting studio-scrollbar-4 flex-1 pt-40 bg-[rgba(11,94,255,0.05)] pl-20"
        >
          <div v-if="releasePlanInformation && !isLoading" class="max-w-[120rem] mx-auto mb-32">
            <template v-if="isStopSale && isNotDisplay">
              <release-state-message :productReleaseStatus="currentProductReleaseStatus" customText>
                <safe-html
                  :html="
                    $t(
                      'studio.prj_prod.this_prod.stt_release_sale_suspended_guide.case_prod_pg_only'
                    )
                  "
                />
              </release-state-message>
              <br />
              <release-state-message :productReleaseStatus="currentProductReleaseStatus" customText>
                <safe-html
                  :html="
                    $t(
                      'studio.prj_prod.this_prod.stt_release_sale_suspended_guide.case_prod_pg_build'
                    )
                  "
                />
              </release-state-message>
            </template>
            <release-state-message
              v-else
              :productReleaseStatus="currentProductReleaseStatus"
              :isOnlyPageOpenReleased="isOnlyPageOpenReleased"
              :dataSanctionInquiry="dataSanctionInquiry"
            />
            <release-reject-reason
              v-if="releasePlanInformation.deleteRequestStatus === DELETE_REQUEST_STATUS.REJECT"
              :dataReject="[
                {
                  context: isOnlyPageOpenReleased
                    ? $t('studio.prj_prod.this_prod.del_req_rejected_reason_1')
                    : $t('studio.prj_prod.this_prod.del_req_rejected_reason_2')
                }
              ]"
            />
            <template
              v-if="
                (isReleaseRestrictStatus || isReleaseStoppedStatus) &&
                  dataSanctionInquiry?.restrictStatus !== RESTRICT_STATUS.REPORT &&
                  dataSanctionInquiry?.currentClaims?.length
              "
            >
              <template
                v-for="(currentClaim, indexClaim) in dataSanctionInquiry.currentClaims"
                :key="indexClaim"
              >
                <reason-rejections
                  v-for="(item, index) in currentClaim.reasons"
                  :key="index"
                  class="mt-8"
                  :rejectContentTitle="`[${CLAIM_TYPE_LABEL[currentClaim.claimType]}]`"
                  :rejectContentDesc="item"
                />
              </template>
            </template>
            <template v-if="rejectContentsEtc?.length && isOperatorRejectedSanctionedStatus">
              <reason-rejections
                v-for="(item, index) in rejectContentsEtc"
                :key="index"
                class="mt-8"
                :rejectContentTitle="`[${item.title}]`"
                :rejectContentDesc="item.reason"
              />
            </template>
            <div class="flex gap-40 mt-24 items-start mb-[12rem]">
              <div class="flex-1 flex flex-col">
                <release-product-launch-verify
                  v-if="
                    !(
                      isReviewCompletedStatus &&
                      releasePlanInformation.releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD
                    ) &&
                      !isReleaseRestrictStatus &&
                      !isOfficialReleased
                  "
                  :isPageBuild="isPageBuild"
                  :isOnlyPageOpenReleased="isOnlyPageOpenReleased"
                  :isReleaseOnlyProductPage="isReleaseOnlyProductPage"
                  @onChangeWhenReleaseOnlyProductPage="setReleaseTypeWhenOnlyProductPage($event)"
                />
                <div v-else class="-mt-24">
                  <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.PREPARE_PRODUCT_PAGE"></div>
                </div>

                <release-product-settings
                  v-if="!isLoadingReleaseType"
                  :isPageBuild="isPageBuild"
                  :isDisabled="isDisabledFormDropdown || isReleaseOnlyProductPage"
                  :dataReject="dataReject"
                  :isOnlyPageOpenReleased="isOnlyPageOpenReleased"
                />

                <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.PRODUCT_PAGE_OPTIONS"></div>
                <release-product-page-options
                  :isPageBuild="isPageBuild"
                  :isCurrentOnlyPageOpenReleased="isCurrentOnlyPageOpenReleased"
                  :defaultEarlyAccessBeginDate="defaultEarlyAccessBeginDate"
                />

                <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.BUILD_PREPARATION"></div>
                <release-preparing-to-build
                  v-if="!isLoadingReleaseType"
                  :isPageBuild="isPageBuild"
                  :dataReject="dataReject"
                />

                <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.GAME_SUPPORT_SERVICE_SETTINGS"></div>
                <release-setting-up-game-support-services v-show="isPageBuild" />

                <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.PRICE_SETTINGS"></div>
                <release-sales-settings v-show="isPageBuild" :dataReject="dataReject" />

                <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.PURCHASE_CONDITIONS"></div>
                <release-purchase-options v-show="isPageBuild" />

                <div :id="RELEASE_CHECKLIST_ID_NAVIGATOR.RELEASE_PLAN"></div>
                <release-launch-plan
                  :isPageBuild="isPageBuild"
                  :releasePlanInformation="releasePlanInformation"
                  :isReleaseOnlyProductPage="isReleaseOnlyProductPage"
                  :isOnlyPageOpenReleased="isOnlyPageOpenReleased"
                />

                <st-box
                  v-if="
                    isBeforeRequiredItemEnteredStatus ||
                      isBeforeRequestingReviewStatus ||
                      isOperatorRejectedSanctionedStatus
                  "
                  class="mt-24 !py-[4.3rem]"
                >
                  <s-checkbox
                    id="chk-keep"
                    v-model="isConfirmSubmit"
                    :isDisabled="isDisabledCheckbox"
                    size="lg"
                    align="middle"
                  >
                    <s-text as="span" role="title4" class="!font-bold">
                      {{
                        $t('studio.prj_prod.this_prod.release_details_release_form_confirmation')
                      }}
                    </s-text>
                  </s-checkbox>
                </st-box>

                <template
                  v-if="isBeforeRequiredItemEnteredStatus || isBeforeRequestingReviewStatus"
                >
                  <s-button
                    :isDisabled="!isCompletedForm || !isConfirmSubmit"
                    variant="primary"
                    size="lg"
                    class="w-[32.5rem] mt-32 self-center"
                    @click="onSubmitBeforeRequired()"
                  >
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_request_review_btn'
                      )
                    }}
                  </s-button>
                </template>
                <template v-if="isOperatorRejectedSanctionedStatus">
                  <s-button
                    :isDisabled="!isCompletedForm || !isConfirmSubmit"
                    variant="primary"
                    size="lg"
                    class="w-[32.5rem] mt-32 self-center"
                    @click="onSubmitBeforeRequired()"
                  >
                    {{ $t('studio.prj_prod.post_release.price_update.popup_request_cf_btn') }}
                  </s-button>
                </template>
              </div>
              <aside id="release-aside-checklist" class="sticky top-[-1.6rem] shrink-0 w-280">
                <release-checklist
                  v-if="releasePlanInformation"
                  stickyID="release-aside-checklist"
                  :isPageBuild="isPageBuild"
                  :isOnlyPageOpenReleased="isOnlyPageOpenReleased"
                  :hasRejectData="!!dataReject"
                  :isComplete="false"
                />
              </aside>
            </div>
          </div>
        </div>
      </div>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="studio-release-popup" />
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldValue } from 'vee-validate';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import {
  definePageMeta,
  formatToDateTime,
  getDateTimeByLocale,
  getDiscountCurrencies,
  showAlert,
  showCommonErrorDialog
} from '@/.nuxt/imports';
import { fetchProductSanctionInquiryApi } from '@/apis/products.api';
import { modifyProductDisplayApi, modifyProductSaleStatusApi } from '@/apis/project-product.api';
import {
  fetchLatestReviewRejectionInformationApi,
  fetchReleasePlanImageReleasedApi,
  fetchReleasePlanInformationApi,
  fetchReleasePlanRelationSellingApi,
  releasePlanVerifyApi,
  releasePlanVerifyCancelApi,
  saveReleaseInformationApi
} from '@/apis/release-plan.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import ReleaseLaunchPlan from '@/components/release-plan/detail-components/release-launch-plan.vue';
import ReleasePreparingToBuild from '@/components/release-plan/detail-components/release-preparing-to-build.vue';
import ReleaseProductLaunchVerify from '@/components/release-plan/detail-components/release-product-launch-verify.vue';
import ReleaseProductPageOptions from '@/components/release-plan/detail-components/release-product-page-options.vue';
import ReleaseProductSettings from '@/components/release-plan/detail-components/release-product-settings.vue';
import ReleasePurchaseOptions from '@/components/release-plan/detail-components/release-purchase-options.vue';
import ReleaseRejectReason from '@/components/release-plan/detail-components/release-reject-reason.vue';
import ReleaseSalesSettings from '@/components/release-plan/detail-components/release-sales-settings.vue';
import ReleaseSettingUpGameSupportServices from '@/components/release-plan/detail-components/release-setting-up-game-support-servives.vue';
import InspectionRequestConfirmationDialog from '@/components/release-plan/dialog/inspection-request-confirmation-dialog.vue';
import ReleaseCompletionDialog from '@/components/release-plan/dialog/release-completion-dialog.vue';
import ReasonRejections from '@/components/release-plan/reasons-rejection.vue';
import ReleaseChecklist from '@/components/release-plan/release-checklist.vue';
import ReleaseDialogHeader from '@/components/release-plan/release-dialog-header.vue';
import ReleaseStateMessage from '@/components/release-plan/release-state-message.vue';
import { useApp } from '@/composables/useApp';
import { showConfirm, showDialog } from '@/composables/useDialog';
import { currencyCodes } from '@/constants/currency-codes.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import {
  CLAIM_TYPE_LABEL,
  DELETE_REQUEST_STATUS,
  PLAN_STATUS,
  RESTRICT_STATUS,
  SALE_START_TYPE,
  SALE_STATUS,
  VERIFY_STATUS
} from '@/constants/products.const';
import {
  PLAY_OPTION,
  PRODUCT_LAUNCH_TYPE,
  RELEASE_CHECKLIST_ID_NAVIGATOR,
  RELEASE_DISCOUNT_DAYS_DEFAULT,
  RELEASE_DISCOUNT_RATE_DEFAULT,
  RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE,
  RELEASE_PLAN_LIMITED_SALES_STATUS,
  RELEASE_PLAN_PRODUCT_STATUS,
  RELEASE_PLAN_REQUEST_VERIFY_STATUS,
  RELEASE_PLAN_REVIEW_REJECTION_INFORMATION_VERIFY_TYPE,
  RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE,
  RELEASE_TIME_FORMAT
} from '@/constants/release-plan.const';
import { Confirmation } from '@/enums/common.enum';
import { useCurrencyStore } from '@/stores/currency.store';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { ProductSanctionInquiryResponse } from '@/types/product/product-response.type';
import type {
  ReleasePlanGracRatingInformation,
  ReleasePlanInformation,
  ReleasePlanRelationSelling,
  ReleaseProductLaunchType
} from '@/types/release-plan/release-plan.type';
import type {
  ReleasePlanVerifyRequest,
  ReviewRejectionInformationRequest,
  SaveReleaseInformationPageRequest,
  SaveReleaseInformationPrePurchaseRequest,
  SaveReleaseInformationReleaseRequest,
  SaveReleaseInformationRequest
} from '@/types/release-plan/release-plan-request.type';
import type {
  RejectContent,
  ReviewRejectionInformationResponse
} from '@/types/release-plan/release-plan-response.type';
import { showConfirmLeaveDialog } from '@/utils/common.util';
import { formatUnknownSrcToMillis } from '@/utils/date.util';

definePageMeta({
  middleware: ['handle-product-data-middleware']
});

const { t } = useI18n();
const { checkProductPermission } = useApp();
const routeParams = useRoute().params;
const productNo: number = parseInt(routeParams?.productId as string, 10);
const releaseTypeQueryParamRaw: string = useRoute().query?.releaseType as string;
const releaseTypeQueryParam: ReleaseProductLaunchType =
  releaseTypeQueryParamRaw !== PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
  releaseTypeQueryParamRaw !== PRODUCT_LAUNCH_TYPE.PAGE
    ? PRODUCT_LAUNCH_TYPE.PAGE_BUILD
    : releaseTypeQueryParamRaw;

const productStore = useProductStore();
const { productName, product } = storeToRefs(productStore);

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const releasePlanStore = useReleasePlanStore();
const {
  storeReleasePlanDetail,
  storeReleasePlanInformation,
  productReleaseStatus,
  isOfficialReleased,
  isStopSale,
  isNotDisplay,
  isOnlyPageOpenToOfficialReleased,
  isOnlyPageOpenReleased,
  isReleaseOnlyProductPage,
  isBeforeRequiredItemEnteredStatus,
  isBeforeRequestingReviewStatus,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isReleaseRestrictStatus,
  isOperatorRejectedSanctionedStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  isReleaseStoppedStatus,
  isCheckListWithoutBuildCompleted,
  isCheckListWithBuildCompleted,
  isReleaseAtDateOverDateNow
} = storeToRefs(releasePlanStore);

const releasePlanInformation = ref<ReleasePlanInformation>();
const constantReleasePlanInformation = ref<ReleasePlanInformation>();
const dataReject = ref<ReviewRejectionInformationResponse>();
const dataSanctionInquiry = ref<ProductSanctionInquiryResponse>();
const isFirstTime = ref<boolean>(true);
const exchangeRates = ref<CurrencyInfoModel[]>();

// Default date
const defaultStartDate: number | null = DateTime.local().plus({ day: 7 }).startOf('day').toMillis();
let defaultEarlyAccessBeginDate = DateTime.local().plus({ day: 7 }).startOf('day').toMillis();
let defaultStorePageOpeningDate = DateTime.local().plus({ day: 7 }).startOf('day').toMillis();
let defaultSaleLimitEndedAt = DateTime.local().plus({ day: 7 }).startOf('day').toMillis();

// Condition
const isConfirmSubmit = ref(false);
const isCheckLeave = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const isLoadingReleaseType = ref<boolean>(false);
const isChangeBuildFromPageOpenRelease = ref<boolean>(false);
const isCurrentOnlyPageOpenReleased = computed(() => {
  return (
    releasePlanInformation.value?.planStatus === PLAN_STATUS.PAGE_OPEN &&
    releasePlanInformation.value?.verifyStatus === VERIFY_STATUS.PASS &&
    releasePlanInformation.value?.releaseType === PRODUCT_LAUNCH_TYPE.PAGE
  );
});

// Status of product release
const currentProductReleaseStatus = computed(() => {
  const verifyStatus = releasePlanInformation.value?.verifyStatus;
  const planStatus = releasePlanInformation.value?.planStatus;
  if (verifyStatus === VERIFY_STATUS.NONE && !isCompletedForm.value) {
    return RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED;
  }
  if (verifyStatus === VERIFY_STATUS.NONE && isCompletedForm.value) {
    return RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUESTING_REVIEW;
  }
  if (
    releasePlanInformation.value?.restrictStatus &&
    releasePlanInformation.value?.restrictStatus !== RESTRICT_STATUS.NONE
  ) {
    return RELEASE_PLAN_PRODUCT_STATUS.RELEASE_RESTRICT;
  }
  if (isNotDisplay.value || isStopSale.value) {
    return RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED;
  }
  if (verifyStatus === VERIFY_STATUS.REQUEST) {
    return RELEASE_PLAN_PRODUCT_STATUS.REQUESTED_REVIEW;
  }
  if (verifyStatus === VERIFY_STATUS.START) {
    return RELEASE_PLAN_PRODUCT_STATUS.UNDER_REVIEW;
  }
  if (verifyStatus === VERIFY_STATUS.REJECT) {
    return RELEASE_PLAN_PRODUCT_STATUS.OPERATOR_REJECTED_SANCTIONED;
  }
  if (verifyStatus === VERIFY_STATUS.PASS) {
    const isWaitingReleasedFromOpenProductPage =
      isOnlyPageOpenToOfficialReleased.value &&
      releasedAt.value &&
      !isChangeBuildFromPageOpenRelease.value &&
      !isReleaseAtDateOverDateNow.value;
    const isOpenToBuildStatusReviewCompleted =
      constantReleasePlanInformation.value?.releaseVerifiedYn === Confirmation.YES &&
      constantReleasePlanInformation.value?.pageVerifiedYn === Confirmation.YES &&
      !isReleaseAtDateOverDateNow.value;
    if (
      planStatus === PLAN_STATUS.READY ||
      planStatus === PLAN_STATUS.NONE ||
      isWaitingReleasedFromOpenProductPage ||
      isOpenToBuildStatusReviewCompleted
    ) {
      return RELEASE_PLAN_PRODUCT_STATUS.REVIEW_COMPLETED;
    }
    if (
      planStatus === PLAN_STATUS.PRE_PURCHASE ||
      planStatus === PLAN_STATUS.RELEASE ||
      planStatus === PLAN_STATUS.PAGE_OPEN
    ) {
      if (
        constantReleasePlanInformation.value?.releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
        constantReleasePlanInformation.value?.releaseVerifiedYn === Confirmation.NO &&
        constantReleasePlanInformation.value?.pageVerifiedYn === Confirmation.YES &&
        !isChangeBuildFromPageOpenRelease.value
      ) {
        return RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED;
      }
      return RELEASE_PLAN_PRODUCT_STATUS.RELEASE_LIVE;
    }
  }
  return RELEASE_PLAN_PRODUCT_STATUS.CUSTOM_ERROR;
});

// Disabled button header
const isDisableButtonSaveReviewPending = computed(() => {
  if (
    !releasePlanInformation.value?.plans?.page?.pageOpenedAt &&
    !releasePlanInformation.value?.plans?.release?.releasedAt
  ) {
    return true;
  }
  if (releaseTypeForm.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD) {
    return Date.now() >= formatUnknownSrcToMillis(releasedAt.value);
  } else {
    return Date.now() >= formatUnknownSrcToMillis(pageOpenAt.value);
  }
});
const isPageBuild = computed(() => releaseTypeForm.value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD);
const isCompletedForm = computed(() => {
  if (isPageBuild.value) {
    return isCheckListWithBuildCompleted.value;
  } else {
    return isCheckListWithoutBuildCompleted.value;
  }
});

const rejectContentsEtc = computed(() =>
  dataReject.value?.rejectContents?.filter(
    (item: RejectContent) => item.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.ETC
  )
);

// Handle logic disabled of form
const isDisabledFormDropdown = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReleaseLiveStatus.value ||
    isReleaseStoppedStatus.value ||
    isCurrentOnlyPageOpenReleased.value ||
    isReleaseRestrictStatus.value ||
    isReviewCompletedStatus.value
  );
});

const isDisabledCheckbox = computed(() => {
  return isRequestedReviewStatus.value;
});

// Form Field
const { setValues, resetForm } = useForm<{
  releasePlanDetail: SaveReleaseInformationRequest;
}>({
  initialValues: {
    releasePlanDetail: {
      releaseNo: null,
      releaseType: PRODUCT_LAUNCH_TYPE.PAGE_BUILD,
      pageId: null,
      buildId: null,
      salesNo: null,
      gracRatingNos: [],
      plans: {
        page: {
          pageOpenedAt: defaultStartDate,
          earlyAccessYn: Confirmation.NO,
          earlyAccessSchedule: {
            startedAt: defaultEarlyAccessBeginDate,
            endedAt: null
          }
        },
        prePurchase: {
          prePurchaseYn: Confirmation.NO,
          prePurchaseSchedule: {
            startedAt: defaultStorePageOpeningDate,
            endedAt: null
          },
          downloadInstallYn: Confirmation.NO,
          playOption: PLAY_OPTION.NONE,
          targets: null // TODO: need update API here!
        },
        release: {
          releaseTimeFormat: '1',
          releasedAt: null,
          sale: {
            releaseDiscountYn: Confirmation.NO,
            releaseDiscountDays: RELEASE_DISCOUNT_DAYS_DEFAULT,
            releaseDiscountRate: RELEASE_DISCOUNT_RATE_DEFAULT,
            saleLimitType: RELEASE_PLAN_LIMITED_SALES_STATUS.NONE,
            saleLimitCount: 1,
            saleLimitEndedAt: defaultSaleLimitEndedAt
          }
        }
      },
      discountCurrencies: [],
      priceList: []
    }
  }
});
const releasePlanDetail = useFieldValue<SaveReleaseInformationRequest>('releasePlanDetail');
const pageOpenAt = useFieldValue<Date | number>('releasePlanDetail.plans.page.pageOpenedAt');
const releaseTypeForm = useFieldValue<string>('releasePlanDetail.releaseType');
const setReleaseType = useSetFieldValue<string>('releasePlanDetail.releaseType');
const saleLimitType = useFieldValue<string>('releasePlanDetail.plans.release.sale.saleLimitType');
const releaseDiscountYn = useFieldValue<string>(
  'releasePlanDetail.plans.release.sale.releaseDiscountYn'
);
const salesNo = useFieldValue<number>('releasePlanDetail.salesNo');
const gracRatingNos = useFieldValue<number[]>('releasePlanDetail.gracRatingNos');
const releasedAt = useFieldValue<number | Date>('releasePlanDetail.plans.release.releasedAt');

const filterDataByReleaseType = (): SaveReleaseInformationRequest => {
  const pageOpenedAt = new Date(releasePlanDetail.value?.plans?.page?.pageOpenedAt as number);
  const earlyAccessSchedule = releasePlanDetail.value?.plans?.page?.earlyAccessSchedule;
  if (releaseTypeForm.value === PRODUCT_LAUNCH_TYPE.PAGE) {
    return {
      ...releasePlanDetail.value,
      gracRatingNos: [],
      salesNo: null,
      plans: {
        page: {
          pageOpenedAt: formatUnknownSrcToMillis(pageOpenedAt),
          earlyAccessYn: releasePlanDetail.value?.plans?.page?.earlyAccessYn,
          earlyAccessSchedule: earlyAccessSchedule
            ? {
              startedAt: earlyAccessSchedule?.startedAt
                ? formatUnknownSrcToMillis(earlyAccessSchedule?.startedAt)
                : null,
              endedAt: earlyAccessSchedule?.endedAt
                ? formatUnknownSrcToMillis(earlyAccessSchedule?.endedAt)
                : null
            }
            : null
        },
        prePurchase: null,
        release: null
      }
    };
  } else {
    const prePurchaseSchedule = releasePlanDetail.value?.plans?.prePurchase?.prePurchaseSchedule;
    const sale = releasePlanDetail.value?.plans?.release?.sale;
    const releasedAt = releasePlanDetail.value?.plans?.release?.releasedAt;
    const priceList = releasePlanDetail.value?.priceList;
    const discountCurrencies =
      sale?.releaseDiscountYn === Confirmation.YES
        ? getDiscountCurrencies(priceList || [], sale.releaseDiscountRate, exchangeRates.value)
        : [];
    return {
      ...releasePlanDetail.value,
      buildId:
        releasePlanDetail.value.buildId === RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE
          ? null
          : releasePlanDetail.value.buildId,
      plans: {
        ...releasePlanDetail.value?.plans,
        page: {
          pageOpenedAt: formatUnknownSrcToMillis(pageOpenedAt),
          earlyAccessYn: releasePlanDetail.value?.plans?.page?.earlyAccessYn,
          earlyAccessSchedule: earlyAccessSchedule
            ? {
              startedAt: earlyAccessSchedule?.startedAt
                ? formatUnknownSrcToMillis(earlyAccessSchedule?.startedAt)
                : null,
              endedAt: earlyAccessSchedule?.endedAt
                ? formatUnknownSrcToMillis(earlyAccessSchedule?.endedAt)
                : null
            }
            : null
        },
        prePurchase: {
          ...releasePlanDetail.value?.plans?.prePurchase,
          prePurchaseSchedule: prePurchaseSchedule
            ? {
              startedAt: prePurchaseSchedule?.startedAt
                ? formatUnknownSrcToMillis(prePurchaseSchedule?.startedAt)
                : null,
              endedAt: prePurchaseSchedule?.endedAt
                ? formatUnknownSrcToMillis(prePurchaseSchedule?.endedAt)
                : null
            }
            : null
        },
        release: {
          ...releasePlanDetail.value?.plans?.release,
          releasedAt: releasedAt ? formatUnknownSrcToMillis(releasedAt) : null,
          sale: {
            ...sale,
            saleLimitEndedAt:
              sale?.saleLimitEndedAt &&
              sale?.saleLimitType === RELEASE_PLAN_LIMITED_SALES_STATUS.DATE
                ? formatUnknownSrcToMillis(sale?.saleLimitEndedAt)
                : null
          }
        }
      },
      discountCurrencies
    };
  }
};

const onSubmitBeforeRequired = async (): Promise<void> => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isValidRelationship = await checkRelationshipProduct(releasePlanDetail.value);
  if (!isValidRelationship) {
    return;
  }
  const props = {
    releaseType: releaseTypeForm.value,
    saleLimitType: saleLimitType.value,
    releaseDiscountYn: releaseDiscountYn.value
  };
  const autoReleaseYn: Confirmation | undefined = await showDialog({
    component: shallowRef(InspectionRequestConfirmationDialog),
    props: {
      ...props
    }
  });
  if (autoReleaseYn) {
    try {
      const data: SaveReleaseInformationRequest = filterDataByReleaseType();
      const releaseNo = await saveReleaseInformationApi(productNo, data);
      if (releaseNo) {
        const params: ReleasePlanVerifyRequest = {
          productNo,
          target: releaseNo.toString(),
          verifyType:
            data.releaseType === PRODUCT_LAUNCH_TYPE.PAGE
              ? RELEASE_PLAN_REQUEST_VERIFY_STATUS.PAGE_RELEASE
              : RELEASE_PLAN_REQUEST_VERIFY_STATUS.PAGE_BUILD_RELEASE,
          data: {
            autoReleaseYn
          }
        };
        const isVerify = await releasePlanVerifyApi(params);
        if (isVerify) {
          const result = await showAlert({
            content: t(
              'studio.prj_prod.this_prod.release_details_browser_request_review_request_review_alert_msg'
            )
          });
          if (result) {
            await setLoadingWithInitData();
          }
        }
      }
    } catch (error: any) {
      // console.log(error, error.statusCode);
      if (error.statusCode === STATUS_CODE.INVALID_PLAN_SCHEDULE_DATE) {
        return await showAlert({
          content: t('studio.event_period_settings.case_earlier_than_current_time_msg')
        });
      }
      await showAlert({
        content: t(COMMON_ERROR_MESSAGE_KEY)
      });
    }
  }
};

const onCancelRequest = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isConfirm = await showConfirm({
    content: `${t(
      'studio.prj_prod.this_prod.release_details_browser_withdraw_review_request_pop_msg1'
    )} <br/>
        ${t('studio.prj_prod.this_prod.release_details_browser_withdraw_review_request_pop_msg2')}`,
    cancelVariant: 'outline',
    cancelLabel: t('studio.common.popup_case_close_btn')
  });
  if (isConfirm && releasePlanInformation.value?.releaseNo) {
    const params: ReleasePlanVerifyRequest = {
      productNo,
      target: releasePlanInformation.value?.releaseNo,
      verifyType:
        releaseTypeForm.value === PRODUCT_LAUNCH_TYPE.PAGE
          ? RELEASE_PLAN_REQUEST_VERIFY_STATUS.PAGE_RELEASE
          : RELEASE_PLAN_REQUEST_VERIFY_STATUS.PAGE_BUILD_RELEASE
    };
    try {
      await releasePlanVerifyCancelApi(params);
      await showAlert({
        content: `${t(
          'studio.prj_prod.this_prod.release_details_browser_withdraw_review_request_msg1'
        )} <br/>
        ${t('studio.prj_prod.this_prod.release_details_browser_withdraw_review_request_msg2')}`
      });
      await setLoadingWithInitData();
    } catch (err) {
      await setLoadingWithInitData();
    }
  }
};

const onSubmitReviewCompleted = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isValidRelationship = await checkRelationshipProduct(releasePlanDetail.value);
  if (!isValidRelationship) {
    return;
  }
  try {
    const data: SaveReleaseInformationRequest = filterDataByReleaseType();
    const releaseNo = await saveReleaseInformationApi(productNo, data);
    if (releaseNo) {
      await setLoadingWithInitData();
      await showAlert({
        content: t('studio.prj_prod.this_prod.popup_product_page_change_saved_msg')
      });
    }
  } catch (error: any) {
    if (error.statusCode === STATUS_CODE.INVALID_PLAN_SCHEDULE_DATE) {
      return await showAlert({
        content: t('studio.event_period_settings.case_earlier_than_current_time_msg')
      });
    }
    await showAlert({
      content: t(COMMON_ERROR_MESSAGE_KEY)
    });
  }
};

const suspendProductPageOpen = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isConfirm = await showConfirm({
    content: `${t('studio.prod_home.popup_prod_pg_open_suspend_msg1')} <br/> ${t(
      'studio.prod_home.popup_prod_pg_open_suspend_msg2'
    )}`,
    confirmLabel: t('studio.prod_home.popup_prod_pg_open_suspend_cf_btn'),
    cancelVariant: 'outline'
  });
  if (isConfirm) {
    try {
      await modifyProductDisplayApi({ productNo, displayYn: Confirmation.NO }, false);
      await setLoadingWithInitData();
    } catch (error: any) {
      await customErrorSuspendOpenProductSale(error);
    }
  }
};

const suspendSale = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isConfirm = await showConfirm({
    content: `${t('studio.prod_home.popup_release_info_sale_suspend_msg1')} <br/> ${t(
      'studio.prod_home.popup_release_info_sale_suspend_msg2'
    )}`,
    cancelLabel: `${t('studio.common.popup_case_close_btn')}`,
    cancelVariant: 'outline'
  });
  if (isConfirm) {
    try {
      await modifyProductSaleStatusApi({ productNo, saleYn: Confirmation.NO }, false);
      await setLoadingWithInitData();
    } catch (error: any) {
      await customErrorSuspendOpenProductSale(error);
    }
  }
};

const reopenProductPage = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isConfirm = await showConfirm({
    content: `${t('studio.prod_home.popup_prod_pg_reopen_msg1')} <br/> ${t(
      'studio.prod_home.popup_prod_pg_reopen_msg2'
    )}`,
    cancelLabel: `${t('studio.common.popup_case_close_btn')}`,
    cancelVariant: 'outline'
  });
  if (isConfirm) {
    try {
      await modifyProductDisplayApi({ productNo, displayYn: Confirmation.YES }, false);
      await setLoadingWithInitData();
      await showAlert({ content: `${t('studio.product_page_resumed_msg')}` });
    } catch (error: any) {
      await customErrorSuspendOpenProductSale(error);
    }
  }
};

const resumeSale = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const isConfirm = await showConfirm({
    content: `${t('studio.prod_home.popup_prod_pg_reopen_msg1')} <br/> ${t(
      'studio.prod_home.popup_prod_pg_reopen_msg2'
    )}`,
    cancelLabel: `${t('studio.common.popup_case_close_btn')}`,
    cancelVariant: 'outline'
  });
  if (isConfirm) {
    try {
      await modifyProductSaleStatusApi({ productNo, saleYn: Confirmation.YES }, false);
      await setLoadingWithInitData();
    } catch (error: any) {
      await customErrorSuspendOpenProductSale(error);
    }
  }
};

const customErrorSuspendOpenProductSale = async (error: any) => {
  const errorCode = error?.statusCode;
  if (
    errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS ||
    errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS
  ) {
    return;
  }
  if (errorCode === STATUS_CODE.PRODUCT_STATUS_NOT_MODIFIABLE) {
    await showCommonErrorDialog(t('studio.release_info_del_requested.cannot_resume_sale_msg'));
  } else {
    await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
    throw error;
  }
};

const setReleaseTypeWhenOnlyProductPage = async (releaseType: string) => {
  if (!isReleaseOnlyProductPage.value && isOfficialReleased.value) {
    return;
  }
  if (releaseType === PRODUCT_LAUNCH_TYPE.PAGE) {
    await popupConfirmChangeReleaseTypeToPage();
  } else {
    await popupConfirmChangeReleaseTypeToPageBuild();
  }
};

const popupConfirmChangeReleaseTypeToPage = async () => {
  const isConfirmSwitchPage = await showConfirm({
    content: t('studio.prj_prod.this_prod.release_details.opt_1to2_switch_popup'),
    cancelVariant: 'outline'
  });
  if (isConfirmSwitchPage) {
    isChangeBuildFromPageOpenRelease.value = false;
    setReleaseType(PRODUCT_LAUNCH_TYPE.PAGE_BUILD);
    if (
      constantReleasePlanInformation.value &&
      constantReleasePlanInformation.value.verifyStatus !== VERIFY_STATUS.PASS &&
      constantReleasePlanInformation.value.pageVerifiedYn === Confirmation.YES &&
      constantReleasePlanInformation.value.releaseVerifiedYn === Confirmation.NO &&
      releasePlanInformation.value
    ) {
      releasePlanInformation.value = {
        ...releasePlanInformation.value,
        verifyStatus: constantReleasePlanInformation.value.verifyStatus,
        releaseType: PRODUCT_LAUNCH_TYPE.PAGE_BUILD
      } as ReleasePlanInformation;
      return;
    }
    releasePlanInformation.value = {
      ...releasePlanInformation.value,
      verifyStatus: VERIFY_STATUS.NONE,
      planStatus: PLAN_STATUS.NONE
    } as ReleasePlanInformation;
  }
};

const popupConfirmChangeReleaseTypeToPageBuild = async () => {
  const isConfirmSwitchPageBuild = await showConfirm({
    content: `${t(
      'studio.prj_prod.this_prod.release_details_opt_switch.prod_pg_release_only_popup_msg1'
    )}
                  <br/>
                  ${t(
    'studio.prj_prod.this_prod.release_details_opt_switch.prod_pg_release_only_popup_msg2'
  )}`,
    cancelVariant: 'outline',
    confirmLabel: t('studio.common.popup_case_change_btn')
  });
  if (isConfirmSwitchPageBuild) {
    setReleaseType(PRODUCT_LAUNCH_TYPE.PAGE);
    if (constantReleasePlanInformation.value) {
      releasePlanInformation.value = JSON.parse(
        JSON.stringify(constantReleasePlanInformation.value)
      );
      if (
        constantReleasePlanInformation.value.pageVerifiedYn === Confirmation.YES &&
        constantReleasePlanInformation.value.releaseVerifiedYn === Confirmation.NO &&
        releasePlanInformation.value
      ) {
        releasePlanInformation.value.verifyStatus = VERIFY_STATUS.PASS;
        releasePlanInformation.value.releaseType = PRODUCT_LAUNCH_TYPE.PAGE;
        isChangeBuildFromPageOpenRelease.value = true;
      }
    }
  }
};

const checkRelationshipProduct = async (
  currentData: SaveReleaseInformationRequest
): Promise<boolean> => {
  const data: ReleasePlanRelationSelling | undefined = await fetchReleasePlanRelationSellingApi(
    productNo
  );
  if (data) {
    const { child, parent } = data;
    const isProductParent = !product.value?.parentProduct;
    const isSetPrePurchase = currentData.plans?.prePurchase?.prePurchaseYn === Confirmation.YES;
    const currentPrePurchaseAt = formatUnknownSrcToMillis(
      currentData.plans?.prePurchase?.prePurchaseSchedule?.startedAt ?? new Date()
    );
    const isCurrentPrePurchaseEndedAt =
      currentData.plans?.prePurchase?.prePurchaseSchedule?.endedAt &&
      formatUnknownSrcToMillis(currentData.plans?.prePurchase?.prePurchaseSchedule?.endedAt);
    const currentReleasedAt = formatUnknownSrcToMillis(
      currentData.plans?.release?.releasedAt ?? new Date()
    );
    if (!currentReleasedAt && !currentPrePurchaseAt) {
      return false;
    }

    // Product is parent (BASIC)
    if (isProductParent && child && currentReleasedAt) {
      // currentReleasedAt is release time of parent
      // Pre purchase or release time of children is after release time of parent
      if (
        currentPrePurchaseAt &&
        currentPrePurchaseAt > child.saleStartedAt &&
        child.saleStartType === SALE_START_TYPE.PRE_PURCHASE
      ) {
        await showAlert({
          content: t('studio.event_period_settings.case_a_child_before_parent_pre_purchase_alert', {
            prodName: child.productName,
            prePurchaseStartTime: getDateTimeByLocale(child.saleStartedAt)
          })
        });
        return false;
      }
      if (
        currentReleasedAt &&
        currentReleasedAt > child.saleStartedAt &&
        child.saleStartType === SALE_START_TYPE.RELEASE
      ) {
        await showAlert({
          content: t(
            'studio.event_period_settings.case_a_child_before_parent_official_release_alert',
            {
              prodName: child.productName,
              releaseStartTime: getDateTimeByLocale(child.saleStartedAt)
            }
          )
        });
        return false;
      }
      // Parent set pre-purchase to unused but child set pre-purchase 16-7-27-a (3)
      if (
        !isSetPrePurchase &&
        isCurrentPrePurchaseEndedAt &&
        child.saleStartType === SALE_START_TYPE.PRE_PURCHASE
      ) {
        await showAlert({
          content: t('studio.pre_purchase_settings.case_child_used_parent_set_unused_msg')
        });
        return false;
      }
    }

    // Product is children (DLC, DEMO, etc.)
    if (!isProductParent) {
      // check parent is valid (passed inspection status) or not
      if (!parent) {
        // Parent not valid (not passed inspection status)
        await showAlert({
          content: t('studio.release_case_parent_product_verify_pass_n.cannot_request_review_msg')
        });
        return false;
      } else {
        // Parent valid (passed inspection status)
        // currentPrePurchaseAt & currentReleasedAt is time of children
        // Pre purchase of child is before release time of parent
        if (
          currentPrePurchaseAt &&
          currentPrePurchaseAt < parent.saleStartedAt &&
          parent.saleStartType === SALE_START_TYPE.PRE_PURCHASE
        ) {
          await showAlert({
            content: t(
              'studio.event_period_settings.case_b_child_before_parent_pre_purchase_alert',
              {
                prodName: productName.value,
                prePurchaseStartTime: getDateTimeByLocale(parent.saleStartedAt)
              }
            )
          });
          return false;
        }
        // Parent don't had pre-purchase but child set pre-purchase 16-7-27-a (4)
        if (isSetPrePurchase && parent.saleStartType === SALE_START_TYPE.RELEASE) {
          await showAlert({
            content: t('studio.pre_purchase_settings.case_parent_unused_child_set_used_msg')
          });
          return false;
        }
        // Release time of child is before release at of parent
        if (
          currentReleasedAt &&
          currentReleasedAt < parent.saleStartedAt &&
          parent.saleStartType === SALE_START_TYPE.RELEASE
        ) {
          await showAlert({
            content: t(
              'studio.event_period_settings.case_b_child_before_parent_official_release_alert',
              {
                prodName: productName.value,
                releaseStartTime: getDateTimeByLocale(parent.saleStartedAt)
              }
            )
          });
          return false;
        }
      }
    }
    return true;
  }
  return false;
};

// Init method
const setLoadingWithInitData = async (callback?: any) => {
  await setInitDataUserHandle(async () => {
    await initData();
    if (callback) {
      await callback();
    }
    isConfirmSubmit.value = true;
  });
};

const setInitDataUserHandle = async (callback: any) => {
  setIsLoading(true);
  await callback();
  setIsLoading(false);
};

const setIsLoading = (value: boolean) => {
  isLoading.value = value;
};

const setReleasePlanInformationByApi = (
  dataInformation?: ReleasePlanInformation | null,
  options?: { releaseTypeDefault?: ReleaseProductLaunchType }
): SaveReleaseInformationRequest => {
  const isProductPageOpen =
    constantReleasePlanInformation.value?.pageVerifiedYn === Confirmation.YES &&
    constantReleasePlanInformation.value?.releaseVerifiedYn === Confirmation.NO;
  defaultEarlyAccessBeginDate = pageOpenAt.value
    ? formatToDateTime(pageOpenAt.value).startOf('day').toMillis()
    : defaultEarlyAccessBeginDate;
  defaultStorePageOpeningDate =
    pageOpenAt.value && !isProductPageOpen
      ? formatToDateTime(pageOpenAt.value).startOf('day').toMillis()
      : defaultStorePageOpeningDate;
  defaultSaleLimitEndedAt = releasedAt.value
    ? formatToDateTime(releasedAt.value).startOf('day').toMillis()
    : defaultSaleLimitEndedAt;
  let pageForm: SaveReleaseInformationPageRequest | null = {
    pageOpenedAt: defaultStartDate,
    earlyAccessYn: Confirmation.NO,
    earlyAccessSchedule: {
      startedAt: defaultEarlyAccessBeginDate,
      endedAt: null
    }
  };
  let prePurchaseForm: SaveReleaseInformationPrePurchaseRequest | null = {
    prePurchaseYn: Confirmation.NO,
    prePurchaseSchedule: {
      startedAt: defaultStorePageOpeningDate,
      endedAt: null
    },
    downloadInstallYn: Confirmation.NO,
    playOption: PLAY_OPTION.NONE,
    targets: null // TODO: need update API here!
  };
  let releaseForm: SaveReleaseInformationReleaseRequest | null = {
    releaseTimeFormat: RELEASE_TIME_FORMAT.COMING_SOON,
    releasedAt: defaultStartDate,
    sale: {
      releaseDiscountYn: Confirmation.NO,
      releaseDiscountDays: RELEASE_DISCOUNT_DAYS_DEFAULT,
      releaseDiscountRate: RELEASE_DISCOUNT_RATE_DEFAULT,
      saleLimitType: RELEASE_PLAN_LIMITED_SALES_STATUS.NONE,
      saleLimitCount: 1,
      saleLimitEndedAt: defaultSaleLimitEndedAt
    }
  };
  let releaseNoForm: number | null = null;
  let buildForm: {
    buildNo?: number | null;
    buildId?: string | null;
    buildName?: string | null;
  } | null = null;
  let gracRatingsForm: ReleasePlanGracRatingInformation[] | null = [];
  let releaseTypeFormData: ReleaseProductLaunchType | null = null;

  if (dataInformation) {
    const { plans, releaseNo, build, gracRatings, releaseType } = dataInformation;
    if (plans) {
      const { page, prePurchase, release } = plans;
      if (page) {
        pageForm = {
          pageOpenedAt: page.pageOpenedAt ?? pageForm.pageOpenedAt,
          earlyAccessYn: page.earlyAccessYn ?? pageForm.earlyAccessYn,
          earlyAccessSchedule: page.earlyAccessSchedule ?? pageForm.earlyAccessSchedule
        };
        releaseForm.releasedAt = !isProductPageOpen
          ? pageForm.pageOpenedAt
          : releaseForm.releasedAt;
      }
      if (prePurchase) {
        prePurchaseForm = {
          prePurchaseYn: prePurchase.prePurchaseYn ?? Confirmation.NO,
          prePurchaseSchedule: prePurchase.prePurchaseSchedule ?? {
            startedAt: defaultStorePageOpeningDate,
            endedAt: null
          },
          downloadInstallYn: prePurchase.downloadInstallYn ?? Confirmation.NO,
          playOption: prePurchase.playOption ?? PLAY_OPTION.NONE,
          targets: prePurchase.targets ?? null // TODO: need update API here!
        };
      }
      if (release) {
        releaseForm = {
          releaseTimeFormat: release.releaseTimeFormat ?? releaseForm.releaseTimeFormat,
          releasedAt: release.releasedAt ?? releaseForm.releasedAt,
          sale: release.sale
            ? {
              releaseDiscountYn: release.sale?.releaseDiscountYn ?? Confirmation.NO,
              releaseDiscountDays:
                  release.sale?.releaseDiscountDays ?? RELEASE_DISCOUNT_DAYS_DEFAULT,
              releaseDiscountRate:
                  release.sale?.releaseDiscountRate ?? RELEASE_DISCOUNT_RATE_DEFAULT,
              saleLimitType:
                  release.sale?.saleLimitType ?? RELEASE_PLAN_LIMITED_SALES_STATUS.NONE,
              saleLimitCount: release.sale?.saleLimitCount ?? 1,
              saleLimitEndedAt: release.sale?.saleLimitEndedAt ?? defaultSaleLimitEndedAt
            }
            : null
        };
      }
    }
    releaseNoForm = releaseNo;
    buildForm = build;
    gracRatingsForm = gracRatings;
    releaseTypeFormData = releaseType;
  }
  return {
    discountCurrencies: [],
    priceList: [],
    releaseNo: releaseNoForm,
    releaseType:
      options?.releaseTypeDefault ??
      releaseTypeFormData ??
      releaseTypeQueryParam ??
      PRODUCT_LAUNCH_TYPE.PAGE_BUILD,
    pageId: dataInformation?.page?.pageId ?? null,
    buildId: buildForm?.buildId ?? null,
    salesNo: salesNo.value ?? dataInformation?.salesNo ?? null,
    gracRatingNos:
      gracRatingNos.value ??
      gracRatingsForm?.map((item: ReleasePlanGracRatingInformation) => item.gracRatingNo) ??
      [],
    plans: {
      page: pageForm,
      prePurchase: prePurchaseForm,
      release: releaseForm
    }
  };
};

const initData = async () => {
  isChangeBuildFromPageOpenRelease.value = false;
  let dataInformation: ReleasePlanInformation | undefined;
  try {
    dataInformation = await fetchReleasePlanInformationApi(productNo);
  } catch (err: any) {
    const errorByPassList = [
      STATUS_CODE.PRODUCT_RELEASE_NOT_FOUND,
      STATUS_CODE.PRODUCT_PAGE_NOT_FOUND
    ];
    if (!errorByPassList.includes(err?.statusCode)) {
      throw err;
    }
  }
  dataInformation = {
    verifyStatus: VERIFY_STATUS.NONE,
    planStatus: PLAN_STATUS.NONE,
    ...dataInformation
  } as ReleasePlanInformation;
  constantReleasePlanInformation.value = JSON.parse(JSON.stringify(dataInformation));
  const releasePlanDetailDefaultValue: SaveReleaseInformationRequest =
    setReleasePlanInformationByApi(dataInformation);
  await setValues({ releasePlanDetail: releasePlanDetailDefaultValue });
  releasePlanStore.initCheckListData(releaseTypeForm.value === PRODUCT_LAUNCH_TYPE.PAGE);
  releasePlanInformation.value = dataInformation;
};

const fetchRejectionData = async (params: ReviewRejectionInformationRequest) => {
  try {
    const data = await fetchLatestReviewRejectionInformationApi(params);
    if (!data) {
      return;
    }
    dataReject.value = data;
  } catch (err) {}
};

const initRejectData = async () => {
  if (!releasePlanInformation.value?.releaseNo) {
    return;
  }
  let paramsRejectedSanctioned: ReviewRejectionInformationRequest;
  let paramsReleaseStopped: ReviewRejectionInformationRequest;
  switch (currentProductReleaseStatus.value) {
    case RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED:
      paramsReleaseStopped = {
        productNo,
        verifyType: RELEASE_PLAN_REVIEW_REJECTION_INFORMATION_VERIFY_TYPE.DELETE,
        target: releasePlanInformation.value?.releaseNo ?? ''
      };
      await fetchRejectionData(paramsReleaseStopped);
      break;
    default:
      paramsRejectedSanctioned = {
        productNo,
        verifyType: RELEASE_PLAN_REVIEW_REJECTION_INFORMATION_VERIFY_TYPE.PAGE_RELEASE,
        target: releasePlanInformation.value?.releaseNo ?? ''
      };
      if (releasePlanInformation.value?.releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD) {
        paramsRejectedSanctioned.verifyType =
          RELEASE_PLAN_REVIEW_REJECTION_INFORMATION_VERIFY_TYPE.PAGE_BUILD_RELEASE;
      }
      await fetchRejectionData(paramsRejectedSanctioned);
      break;
  }
};

const getProductSanctionInquiry = async () => {
  try {
    const data = await fetchProductSanctionInquiryApi(productNo);
    if (!data) {
      return;
    }
    dataSanctionInquiry.value = data;
  } catch (err) {}
};

const showPopupInformationRelease = async () => {
  const isFirstReleaseList: number[] =
    JSON.parse(localStorage.getItem('isFirstReleaseListDetail') ?? '[]') ?? [];
  const isExitsRelease: boolean = !!isFirstReleaseList?.find((item: number) => item === productNo);
  if (isReleaseLiveStatus.value && !isExitsRelease) {
    const srcImage = (await fetchReleasePlanImageReleasedApi(productNo)) ?? '';
    showDialog({
      component: shallowRef(ReleaseCompletionDialog),
      props: {
        srcImage,
        productNo
      }
    });
    localStorage.setItem(
      'isFirstReleaseListDetail',
      JSON.stringify([...isFirstReleaseList, productNo])
    );
  }
};

const init = async () => {
  setIsLoading(true);
  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);
  await initData();
  const arrFetchApi = [];
  if (releasePlanInformation.value?.verifyStatus === VERIFY_STATUS.REJECT) {
    arrFetchApi.push(initRejectData());
  }
  if (releasePlanInformation.value?.restrictStatus !== RESTRICT_STATUS.NONE) {
    arrFetchApi.push(getProductSanctionInquiry());
  }
  await Promise.all([...arrFetchApi]);
  setIsLoading(false);
  await showPopupInformationRelease();
};

init();

onBeforeRouteLeave(async () => {
  if (isCheckLeave.value) {
    return showConfirmLeaveDialog();
  }
});

watch(
  () => currentProductReleaseStatus.value,
  () => {
    productReleaseStatus.value = currentProductReleaseStatus.value;
  }
);

watch(
  () => releaseTypeForm.value,
  async () => {
    if (isFirstTime.value) {
      return;
    }
    const releaseTypeTemp = JSON.parse(
      JSON.stringify(releaseTypeForm.value || PRODUCT_LAUNCH_TYPE.PAGE_BUILD)
    );
    const releasePlanDetailDefaultValue = setReleasePlanInformationByApi(
      constantReleasePlanInformation.value,
      { releaseTypeDefault: releaseTypeTemp }
    );
    resetForm({ values: { releasePlanDetail: releasePlanDetailDefaultValue } });
    isConfirmSubmit.value = false;
  }
);

watch(
  () => constantReleasePlanInformation.value,
  () => {
    storeReleasePlanInformation.value = constantReleasePlanInformation.value;
  },
  { deep: true, immediate: true }
);

watch(
  () => releasePlanDetail.value,
  () => {
    storeReleasePlanDetail.value = releasePlanDetail.value;
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  isFirstTime.value = false;
});
</script>

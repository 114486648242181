<!--SettingSubTitleComponent-->
<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-8 items-center">
      <h4
        v-if="settingSubTitle && settingSubTitle.length > 0"
        class="flex items-center gap-4 text-md leading-lg font-bold text-on-surface-elevation-2"
      >
        {{ settingSubTitle }}
        <span
          v-if="settingSubTitleDesc && settingSubTitleDesc.length > 0"
          class="font-medium leading-md text-on-surface-elevation-4 text-sm"
        >
          <safe-html :html="settingSubTitleDesc" />
        </span>
      </h4>
      <div v-if="useButton" class="ml-auto flex items-center gap-8 shrink-0">
        <s-button
          v-if="buttonName && buttonName.length > 0"
          variant="outline"
          size="xs"
          @click="handleClick()"
        >
          {{ buttonName }}
        </s-button>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';

defineProps<{
  settingSubTitle?: string;
  settingSubTitleDesc?: string;
  buttonName?: string;
  useButton?: boolean;
}>();

const emits = defineEmits<{
  onClick: [isClick: boolean];
}>();

const handleClick = () => {
  emits('onClick', true);
};
</script>

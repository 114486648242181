<template>
  <st-box
    v-if="
      currentProductDetailType !== PRODUCT_TYPE_DETAIL.UTILITY_DLC &&
        currentProductDetailType !== PRODUCT_TYPE_DETAIL.GAME_DLC
    "
    :id="RELEASE_CHECKLIST_ID.GAME_SUPPORT_SERVICE_SETTINGS"
    class="mt-24 !p-[3rem]"
  >
    <folding-release
      :settingTitle="$t('studio.prj_prod.this_prod.release_details_studio_support_title')"
      @onReload="fetchData"
    >
      <div class="folding-release-container">
        <!-- S: In-game store-->
        <div
          v-if="!releasePlanStore.isCheckListHidden(checkListData.inGameStore)"
          :id="RELEASE_CHECKLIST_ID.IN_GAME_STORE"
          class="pb-24"
        >
          <setting-item-title
            useButton
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_studio_support_in_app_shop_title')
            "
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_studio_support_in_app_shop_msg') }}
            </p>
            <template #button>
              <s-button
                variant="outline"
                size="sm"
                icon="ic-v2-control-web-link-line"
                class="flex-row-reverse"
                iconClass="!mr-0 ml-4"
                @click="openSettingInGameStore"
              >
                {{
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_in_app_shop_redirect_btn'
                  )
                }}
              </s-button>
            </template>
          </setting-item-title>
          <setting-content useTypeList>
            <template v-if="inGameStoreData?.length">
              <setting-content-item
                :settingContentItemTitle="
                  $t('studio.prj_prod.this_prod.release_details_studio_support_in_app_shop_y_guide')
                "
              />
            </template>
            <div v-else class="flex flex-wrap gap-x-40 gap-y-8">
              <setting-content-item
                :settingContentItemTitle="
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_in_app_shop_place_holder'
                  )
                "
              />
            </div>
          </setting-content>
        </div>
        <!-- E: In-game store-->

        <!-- S: Product term-->
        <div
          v-if="!releasePlanStore.isCheckListHidden(checkListData.productTerms)"
          :id="RELEASE_CHECKLIST_ID.PRODUCT_TERMS"
          class="pb-24"
        >
          <setting-item-title
            useButton
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_studio_support_term_title')
            "
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_studio_support_term_msg') }}
            </p>
            <template #button>
              <s-button
                variant="outline"
                size="sm"
                icon="ic-v2-control-web-link-line"
                class="flex-row-reverse"
                iconClass="!mr-0 ml-4"
                @click="openTermPage"
              >
                {{
                  $t('studio.prj_prod.this_prod.release_details_studio_support_term_redirect_btn')
                }}
              </s-button>
            </template>
          </setting-item-title>
          <template v-if="productTermList?.length">
            <setting-content v-for="(item, index) in productTermList" :key="index" useTypeList>
              <div class="flex flex-wrap gap-x-40 gap-y-8">
                <setting-content-item
                  :settingContentItemTitle="
                    $t('studio.prj_prod.this_prod.release_details_studio_support_term_category')
                  "
                  :settingContentItemValue="$t(getTermLabel(item.termType))"
                />
                <setting-content-item
                  :settingContentItemTitle="
                    $t('studio.prj_prod.this_prod.release_details_studio_support_term_name')
                  "
                  :settingContentItemValue="item.termManagementName"
                />
                <setting-content-item
                  :settingContentItemTitle="
                    $t('studio.prj_prod.this_prod.release_details_studio_support_term_version')
                  "
                  :settingContentItemValue="(item.termVersion ?? '').toUpperCase()"
                />
              </div>
            </setting-content>
          </template>
          <!--Don't have product term-->
          <template v-if="!productTermList?.length">
            <setting-content useTypeList>
              <div class="flex flex-wrap gap-x-40 gap-y-8">
                <setting-content-item
                  :settingContentItemTitle="
                    $t('studio.prj_prod.this_prod.release_details_studio_support_no_term_msg')
                  "
                />
              </div>
            </setting-content>
          </template>
        </div>
        <!-- E: Product term-->

        <!-- S: Product Challenge/Achievements-->
        <div
          v-if="!releasePlanStore.isCheckListHidden(checkListData.achievements)"
          :id="RELEASE_CHECKLIST_ID.ACHIEVEMENTS"
          class="pb-24"
        >
          <setting-item-title
            useButton
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_studio_support_achievements_title')
            "
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_studio_support_achievements_msg') }}
            </p>
            <template #button>
              <s-button
                variant="outline"
                size="sm"
                icon="ic-v2-control-web-link-line"
                class="flex-row-reverse"
                iconClass="!mr-0 ml-4"
                @click="openProductChallengedPage"
              >
                {{
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_achievements_redirect_btn'
                  )
                }}
              </s-button>
            </template>
          </setting-item-title>
          <setting-content useTypeList>
            <div class="flex flex-col gap-y-8">
              <setting-content-item
                v-if="numberTotalOfAchievementChallenge"
                orderCase
                :settingContentItemTitle="
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_achievements_registered_challenges_msg'
                  )
                "
              >
                <span class="leading-md text-sm"><span class="font-bold">{{ numberOfAchievementChallenge }}</span>/{{ numberTotalOfAchievementChallenge }}</span>
              </setting-content-item>
              <setting-content-item
                v-else
                orderCase
                :settingContentItemTitle="
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_achievements_place_holder'
                  )
                "
              />
            </div>
          </setting-content>
        </div>
        <!-- E: Product Challenge-->

        <!-- S: Product Ranking-->
        <div
          v-if="!releasePlanStore.isCheckListHidden(checkListData.rankings)"
          :id="RELEASE_CHECKLIST_ID.RANKINGS"
          class="pb-24"
        >
          <setting-item-title
            useButton
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_studio_support_ranking_title')
            "
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_studio_support_ranking_msg') }}
            </p>
            <template #button>
              <s-button
                variant="outline"
                size="sm"
                icon="ic-v2-control-web-link-line"
                class="flex-row-reverse"
                iconClass="!mr-0 ml-4"
                @click="openRankingPage"
              >
                {{
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_ranking_redirect_btn'
                  )
                }}
              </s-button>
            </template>
          </setting-item-title>
          <setting-content useTypeList>
            <div class="flex flex-col gap-y-8">
              <setting-content-item
                v-if="numberTotalOfLeaderboardRanking"
                orderCase
                :settingContentItemTitle="
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_ranking_registered_ranking_to_total_number_msg'
                  )
                "
              >
                <span class="leading-md text-sm"><span class="font-bold">{{ numberOfLeaderboardRanking }}</span>/{{ numberTotalOfLeaderboardRanking }}</span>
              </setting-content-item>
              <setting-content-item
                v-else
                :settingContentItemTitle="
                  $t(
                    'studio.prj_prod.this_prod.release_details_studio_support_ranking_place_holder'
                  )
                "
              />
            </div>
          </setting-content>
        </div>
        <!-- E: Product Ranking-->

        <!-- S: Product Cloud Saving-->
        <div
          v-if="!releasePlanStore.isCheckListHidden(checkListData.cloudSaving)"
          :id="RELEASE_CHECKLIST_ID.CLOUD_SAVING"
          class="pb-24"
        >
          <setting-item-title
            useButton
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_studio_support_cloud_title')
            "
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_studio_support_cloud_msg') }}
            </p>
            <template #button>
              <s-button
                variant="outline"
                size="sm"
                icon="ic-v2-control-web-link-line"
                class="flex-row-reverse"
                iconClass="!mr-0 ml-4"
                @click="openCloudSavingPage"
              >
                {{
                  $t('studio.prj_prod.this_prod.release_details_studio_support_cloud_redirect_btn')
                }}
              </s-button>
            </template>
          </setting-item-title>
          <setting-content useTypeList>
            <div class="flex flex-col gap-y-8">
              <template v-if="cloudSavingData">
                <setting-content-item
                  :settingContentItemTitle="
                    $t(
                      'studio.prj_prod.this_prod.release_details_studio_support_cloud_savefile_location_msg'
                    )
                  "
                  :settingContentItemValue="`${cloudSavingData.defaultPath}\\${cloudSavingData.subPath}`"
                />
                <setting-content-item
                  :settingContentItemTitle="
                    $t(
                      'studio.prj_prod.this_prod.release_details_studio_support_cloud_savefile_info_msg'
                    )
                  "
                  :settingContentItemValue="
                    cloudSavingData.saveTargetFileType === CLOUD_SAVING_SAVE_TARGET_FILE_TYPE.ALL
                      ? '*.*'
                      : cloudSavingData.fileExtensions.join(', ')
                  "
                />
              </template>
              <template v-else>
                <setting-content-item
                  :settingContentItemTitle="
                    $t(
                      'studio.prj_prod.this_prod.release_details_studio_support_cloud_savefile_place_holder'
                    )
                  "
                />
              </template>
            </div>
          </setting-content>
        </div>
        <!-- E: Product Cloud Saving-->

        <!-- S: Product Matchmaking-->
        <div
          v-if="!releasePlanStore.isCheckListHidden(checkListData.matchMaking)"
          :id="RELEASE_CHECKLIST_ID.MATCH_MAKING"
          class="pb-24"
        >
          <setting-item-title
            useButton
            :settingTitle="
              $t('studio.prj_prod.this_prod.release_details_studio_support_match_title')
            "
          >
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{ $t('studio.prj_prod.this_prod.release_details_studio_support_match_msg') }}
            </p>
            <template #button>
              <s-button
                variant="outline"
                size="sm"
                icon="ic-v2-control-web-link-line"
                class="flex-row-reverse"
                iconClass="!mr-0 ml-4"
                @click="openMatchmakingPage"
              >
                {{
                  $t('studio.prj_prod.this_prod.release_details_studio_support_match_redirect_btn')
                }}
              </s-button>
            </template>
          </setting-item-title>
          <setting-content
            :settingContentDesc="
              isMatchMakingExist
                ? $t(
                  'studio.prj_prod.this_prod.release_details_studio_support_match_status_intergrated_msg'
                )
                : $t(
                  'studio.prj_prod.this_prod.release_details_studio_support_match_status_not_prepared_msg'
                )
            "
          />
        </div>
        <!-- E: Product Matchmaking-->
      </div>
    </folding-release>
  </st-box>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchProductCountingApi } from '@/apis/products.api';
import { fetchPrecessionVersionApi } from '@/apis/purchase-condition.api';
import { fetchCloudSavingByGameIdApi, fetchInGameStoreListApi } from '@/apis/release-plan.api';
import StBox from '@/components/common/st-box.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import SettingContent from '@/components/release-plan/setting-content.vue';
import SettingContentItem from '@/components/release-plan/setting-content-item.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import { OPEN_TARGET_BLANK, OPEN_TARGET_BLANK_EXTERNAL } from '@/constants/common.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import {
  CLOUD_SAVING_SAVE_TARGET_FILE_TYPE,
  RELEASE_CHECKLIST_ID
} from '@/constants/release-plan.const';
import {
  PRODUCT_ACHIEVEMENTS_PAGE_URL,
  PRODUCT_CLOUD_SAVING_PAGE_URL,
  PRODUCT_IN_GAME_PAYMENT_SETTING_PAGE_URL,
  PRODUCT_LEADER_BOARD_PAGE_URL,
  PRODUCT_MATCH_MAKING_PAGE_URL,
  PRODUCT_TERMS_PAGE_URL
} from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { TermsRunningVersionResponse } from '@/types/purchase-condition/purchase-condition.response.type';
import type { ProductReleasePlanCloudSaving } from '@/types/release-plan/release-plan.type';
import type { InGameStoreListRequest } from '@/types/release-plan/release-plan-request.type';
import type { InGameStoreListResponse } from '@/types/release-plan/release-plan-response.type';
import { generateExternalLinkWithHostLocale, redirectTo } from '@/utils/common.util';
import { getTermLabel } from '@/utils/term.util';

const { locale } = useI18n();
const routeParams = useRoute().params;
const productNo: string = routeParams?.productId as string;
const groupId: string = routeParams?.groupId as string;

const productTermList = ref<
  { termType: string; termManagementName: string; termVersion: string }[] | undefined
>();

const isMatchMakingExist = ref(false);
const numberOfAchievementChallenge = ref(0);
const numberTotalOfAchievementChallenge = ref(0);
const numberOfLeaderboardRanking = ref(0);
const numberTotalOfLeaderboardRanking = ref(0);
const cloudSavingData = ref<ProductReleasePlanCloudSaving>();
const inGameStoreData = ref<InGameStoreListResponse[]>();

const releasePlanStore = useReleasePlanStore();
const { checkListData } = storeToRefs(releasePlanStore);
const productStore = useProductStore();
const { product, currentProductDetailType } = storeToRefs(productStore);
const gameId = product.value?.gameId || '';

const openSettingInGameStore = async () => {
  await redirectTo(
    await generateExternalLinkWithHostLocale(
      PRODUCT_IN_GAME_PAYMENT_SETTING_PAGE_URL,
      locale.value
    ),
    OPEN_TARGET_BLANK_EXTERNAL
  );
};

const openTermPage = async () => {
  await redirectTo(PRODUCT_TERMS_PAGE_URL, OPEN_TARGET_BLANK);
};

const openProductChallengedPage = async () => {
  await redirectTo(
    await generateExternalLinkWithHostLocale(PRODUCT_ACHIEVEMENTS_PAGE_URL, locale.value),
    OPEN_TARGET_BLANK_EXTERNAL
  );
};

const openRankingPage = async () => {
  await redirectTo(
    await generateExternalLinkWithHostLocale(PRODUCT_LEADER_BOARD_PAGE_URL, locale.value),
    OPEN_TARGET_BLANK_EXTERNAL
  );
};

const openCloudSavingPage = async () => {
  await redirectTo(
    await generateExternalLinkWithHostLocale(PRODUCT_CLOUD_SAVING_PAGE_URL, locale.value),
    OPEN_TARGET_BLANK_EXTERNAL
  );
};

const openMatchmakingPage = async () => {
  await redirectTo(
    await generateExternalLinkWithHostLocale(PRODUCT_MATCH_MAKING_PAGE_URL, locale.value),
    OPEN_TARGET_BLANK_EXTERNAL
  );
};

// Init Data
const getProductTerms = async () => {
  const productTerms = await fetchPrecessionVersionApi(gameId);
  if (productTerms) {
    productTermList.value = productTerms.map((item: TermsRunningVersionResponse) => ({
      termType: item.subCategory,
      termManagementName: item.name,
      termVersion: item.version
    }));
  }
  releasePlanStore.setProductTermsCheckList(!!productTerms?.length ?? false);
};

const getGameServiceCounting = async () => {
  const productCounting = await fetchProductCountingApi(productNo);
  if (productCounting) {
    isMatchMakingExist.value = productCounting.matchmakingRegistered === Confirmation.YES;
    numberOfAchievementChallenge.value = productCounting.achievementApplied;
    numberTotalOfAchievementChallenge.value = productCounting.achievementRegisteredTotal;
    numberOfLeaderboardRanking.value = productCounting.leaderboardApplied;
    numberTotalOfLeaderboardRanking.value = productCounting.leaderboardRegisteredTotal;
    releasePlanStore.setAchievementsCheckList(!!numberOfAchievementChallenge.value ?? false);
    releasePlanStore.setRankingsCheckList(!!numberOfLeaderboardRanking.value ?? false);
    releasePlanStore.setMatchMakingCheckList(isMatchMakingExist.value);
  }
};

const getInGameStore = async () => {
  const params: InGameStoreListRequest = {
    teamId: groupId,
    gameCodeList: [gameId]
  };
  const data = await fetchInGameStoreListApi(params);
  if (data?.length) {
    inGameStoreData.value = data;
  }
  releasePlanStore.setInGameStoreCheckList(!!data?.length ?? false);
};

const getCloudSaving = async () => {
  cloudSavingData.value = await fetchCloudSavingByGameIdApi(gameId);
  releasePlanStore.setCloudSavingCheckList(!!cloudSavingData.value);
};

const fetchData = () => {
  getProductTerms();
  getGameServiceCounting();
  getInGameStore();
  getCloudSaving();
};

const init = () => {
  fetchData();
};

init();
</script>
<style lang="scss">
.folding-release-container > div:last-child {
  padding-bottom: 0;
}
</style>

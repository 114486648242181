<!--ReleaseChecklistItem-->
<template>
  <li class="flex gap-8 item-center">
    <s-icon
      :icon="getIconStatus"
      size="3xl"
      :class="{
        'text-brand-primary': status === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED,
        'text-system-red': status === RELEASE_PLAN_CHECK_LIST_STATUS.REJECT,
        'text-disabled-variant-1':
          status === RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN ||
          status === RELEASE_PLAN_CHECK_LIST_STATUS.IN_PROGRESS
      }"
    />
    <s-text
      as="span"
      role="text3"
      :class="{
        'text-brand-primary': status === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED,
        'text-system-red': status === RELEASE_PLAN_CHECK_LIST_STATUS.REJECT,
        'text-on-surface-elevation-2':
          status === RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN ||
          status === RELEASE_PLAN_CHECK_LIST_STATUS.IN_PROGRESS
      }"
    >
      {{ releaseChecklistText }}
    </s-text>
  </li>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { RELEASE_PLAN_CHECK_LIST_STATUS } from '@/constants/release-plan.const';
import type { CheckListStatus } from '@/types/release-plan/release-plan.type';

const props = defineProps<{
  status?: CheckListStatus;
  releaseChecklistText?: string;
  noRedirect?: boolean;
}>();

const getIconStatus = computed(() => {
  return props.status === RELEASE_PLAN_CHECK_LIST_STATUS.REJECT
    ? 'ic-v2-state-warning-circle-line'
    : 'ic-v2-control-check-bold-line';
});
</script>

<template>
  <st-box
    v-if="!releasePlanStore.isCheckListHidden(checkListData.priceSettings)"
    :id="RELEASE_CHECKLIST_ID.PRICE_SETTINGS"
    class="mt-24 !p-[3rem]"
  >
    <folding-release
      required
      :isError="!!dataRejectPrice?.length && isOperatorRejectedSanctionedStatus"
      :settingTitle="$t('studio.prj_prod.this_prod.release_details_sales_setting_title')"
      @onReload="handleReload"
    >
      <setting-item-title
        useButton
        :settingTitle="
          $t('studio.prj_prod.this_prod.release_details_sales_setting_price_setting_title')
        "
      >
        <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
          {{ $t('studio.prj_prod.this_prod.release_details_sales_setting_price_setting_msg') }}
        </p>
        <template #button>
          <s-button
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="openSettingPricePage"
          >
            {{
              $t(
                'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_redirect_btn'
              )
            }}
          </s-button>
        </template>
      </setting-item-title>
      <setting-content useTypeList :isError="!priceSettingsCheckCompleted && !productPrice">
        <div class="flex flex-wrap gap-x-40 gap-y-8">
          <template v-if="productPrice && !isProductFree">
            <setting-content-item
              :settingContentItemTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_sales_type_msg'
                )
              "
              :settingContentItemValue="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_sales_type_this_type_paid'
                )
              "
            />
            <setting-content-item
              :settingContentItemTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_price_msg'
                )
              "
              :settingContentItemValue="`${formatPrice(productPrice, CurrencyCode.Usd)} ${
                CurrencyCode.Usd
              }`"
            />
          </template>
          <template v-else-if="isProductFree || isProductDemoType">
            <setting-content-item
              :settingContentItemTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_sales_type_msg'
                )
              "
              :settingContentItemValue="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_sales_type_this_type_free'
                )
              "
            />
          </template>
          <template v-else>
            <setting-content-item
              :isError="!priceSettingsCheckCompleted && !productPrice"
              :settingContentItemTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_case_free_place_holder'
                )
              "
            />
          </template>
        </div>
      </setting-content>
      <template v-if="dataRejectPrice?.length && isOperatorRejectedSanctionedStatus">
        <reason-rejections
          v-for="(item, index) in dataRejectPrice"
          :key="index"
          class="mt-8"
          :rejectContentTitle="`[${item.title}]`"
          :rejectContentDesc="item.reason"
        />
      </template>

      <div v-show="productPrice && !isProductFree" class="d-content">
        <setting-item-title
          :settingTitle="
            $t(
              'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_title'
            )
          "
          class="mt-24"
        >
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{
              $t(
                'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_msg'
              )
            }}
          </p>
        </setting-item-title>
        <release-setting-box class="mt-8">
          <setting-sub-title
            :settingSubTitle="
              $t(
                'studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_release_option'
              )
            "
          />
          <radio-group
            class="flex gap-24"
            name="releasePlanDetail.plans.release.sale.saleLimitType"
            :disabled="isDisablePrice"
            :options="limitedSaleOptions"
            :optionProps="{
              size: 'sm',
              align: 'middle'
            }"
          />
          <div v-show="saleLimitType === RELEASE_PLAN_LIMITED_SALES_STATUS.COUNT" class="d-content">
            <setting-sub-title
              :settingSubTitle="$t('studio.home.release_timeline.prod_pg_item.price_sale_limit')"
              class="mt-20"
            />
            <div
              class="mt-4 flex items-center gap-8 text-on-surface-elevation-3 text-md font-medium leading-lg"
            >
              <div class="w-[16rem]">
                <input-text
                  class="text-right"
                  name="releasePlanDetail.plans.release.sale.saleLimitCount"
                  size="sm"
                  variant="outline"
                  maxLength="10"
                  :countable="false"
                  :disabled="isDisablePrice"
                  :type="InputTextTypes.Number"
                  :max="999999999"
                  :min="1"
                  :preventInput="true"
                  :rules="{
                    required: {
                      value: true,
                      message: $t('studio.release_info_setting.limited_sales_quantity_required_msg')
                    }
                  }"
                />
              </div>
              <span>{{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_unit_msg'
                )
              }}</span>
            </div>
          </div>
          <div v-show="saleLimitType === RELEASE_PLAN_LIMITED_SALES_STATUS.DATE" class="d-content">
            <setting-sub-title
              :settingSubTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_period_setting'
                )
              "
              :settingSubTitleDesc="utcTimezone"
              class="mt-20"
            />
            <div
              class="mt-8 py-16 px-24 flex items-center bg-[var(--stds-glob-color-gray20)] rounded-xl"
            >
              <datetime-picker
                name="releasePlanDetail.plans.release.sale.saleLimitEndedAt"
                :isDisabled="isDisablePriceDate || (isOfficialReleased && isEndDateOverDateNow)"
                :disabledDates="disabledLimitedSaleExpiredDateValue"
                :containerClass="'flex items-center gap-16'"
                isReplaceContainerClass
                hiddenUtc
              />
            </div>
            <s-box v-if="isLimitedSaleExpiredError" class="flex gap-x-2 text-error mt-8">
              <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" />
              <s-text role="cap1">
                {{ LIMIT_SALE_EXPIRED_DATE_ERROR[isLimitedSaleExpiredError] }}
              </s-text>
            </s-box>
          </div>
        </release-setting-box>
      </div>

      <div class="d-content">
        <setting-item-title
          :settingTitle="$t('studio.release_info.discount_setting_title')"
          class="mt-24"
          useButton
        >
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{ $t('studio.release_info.discount_setting_guide') }}
          </p>
          <template #button>
            <s-button
              variant="outline"
              size="sm"
              icon="ic-v2-control-web-link-line"
              class="flex-row-reverse"
              iconClass="!mr-0 ml-4"
              @click="openSettingDiscountPage"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_redirect_btn'
                )
              }}
            </s-button>
          </template>
        </setting-item-title>
      </div>
    </folding-release>
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import type { ComputedRef } from 'vue';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchProductSellingPriceApi } from '@/apis/product-selling-price.api';
import StBox from '@/components/common/st-box.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import ReasonRejections from '@/components/release-plan/reasons-rejection.vue';
import ReleaseSettingBox from '@/components/release-plan/release-setting-box.vue';
import SettingContent from '@/components/release-plan/setting-content.vue';
import SettingContentItem from '@/components/release-plan/setting-content-item.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import SettingSubTitle from '@/components/release-plan/setting-sub-title.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showConfirm } from '@/composables/useDialog';
import { OPEN_TARGET_BLANK } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import { PRODUCT_TYPE_DETAIL, VERIFY_STATUS } from '@/constants/products.const';
import {
  RELEASE_CHECKLIST_ID,
  RELEASE_PLAN_LIMITED_SALES_STATUS,
  RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE
} from '@/constants/release-plan.const';
import { PRODUCT_DISCOUNT_PAGE_URL, PRODUCT_SELLING_PRICE_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { InputTextTypes } from '@/enums/validation.enum';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { FormOption } from '@/types/common/form.type';
import type {
  NotVerifyPassedPriceType,
  PriceListType,
  VerifyPassedPriceType
} from '@/types/product-selling-price/product-selling-price.type';
import type { ProductSellingPriceResponse } from '@/types/product-selling-price/product-selling-price-response.type';
import type {
  RejectContent,
  ReviewRejectionInformationResponse
} from '@/types/release-plan/release-plan-response.type';
import { redirectTo } from '@/utils/common.util';
import { formatPrice } from '@/utils/currency.util';
import { formatToDateTime, formatUnknownSrcToMillis, getUtcTimezone } from '@/utils/date.util';

const props = defineProps<{
  dataReject?: ReviewRejectionInformationResponse;
}>();

const sellingPrice = ref<ProductSellingPriceResponse | undefined>();

const utcTimezone = `(${getUtcTimezone()})`;
const { t } = useI18n();
const routeParams = useRoute().params;
const productNo: number = parseInt(routeParams?.productId as string, 10);

const productStore = useProductStore();
const { currentProductDetailType } = storeToRefs(productStore);

const releasePlanStore = useReleasePlanStore();
const {
  isEndDateOverDateNow,
  isOfficialReleased,
  checkListData,
  isOperatorRejectedSanctionedStatus,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  isReleaseRestrictStatus,
  isReleaseStoppedStatus
} = storeToRefs(releasePlanStore);

const releaseDiscountYn = useFieldValue<Confirmation | undefined>(
  'releasePlanDetail.plans.release.sale.releaseDiscountYn'
);
const releaseDiscountRate = useFieldValue<number | undefined>(
  'releasePlanDetail.plans.release.sale.releaseDiscountRate'
);
const releaseDiscountDays = useFieldValue<number | undefined>(
  'releasePlanDetail.plans.release.sale.releaseDiscountDays'
);
const saleLimitType = useFieldValue<string>('releasePlanDetail.plans.release.sale.saleLimitType');
const limitedSaleExpired = useFieldValue<Date | null>(
  'releasePlanDetail.plans.release.sale.saleLimitEndedAt'
);
const releasedAt = useFieldValue<number | Date>('releasePlanDetail.plans.release.releasedAt');
const saleLimitCount = useFieldValue<number | Date>(
  'releasePlanDetail.plans.release.sale.saleLimitCount'
);
const setSalesNo = useSetFieldValue<number>('releasePlanDetail.salesNo');
const setPriceList = useSetFieldValue<PriceListType[]>('releasePlanDetail.priceList');

const LIMIT_SALE_EXPIRED_DATE_ERROR: Record<number, string> = {
  1: t(
    'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_period_input_n_val_msg'
  ),
  2: t('studio.event_period_settings.limit_sale_end_before_release_val_msg')
};
const limitedSaleOptions: FormOption[] = [
  {
    value: RELEASE_PLAN_LIMITED_SALES_STATUS.COUNT,
    label: t(
      'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_radio_sales_by_volume_msg'
    )
  },
  {
    value: RELEASE_PLAN_LIMITED_SALES_STATUS.DATE,
    label: t(
      'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_radio_sales_by_period_msg'
    )
  },
  {
    value: RELEASE_PLAN_LIMITED_SALES_STATUS.NONE,
    label: t(
      'studio.prj_prod.this_prod.release_details_sales_setting_limited_sales_setting_radio_deactivated_msg'
    )
  }
];

const currentPrice = ref<VerifyPassedPriceType | NotVerifyPassedPriceType | undefined>();
const isFirstTime = ref<boolean>(true);
const isDisablePrice = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReviewCompletedStatus.value ||
    isReleaseLiveStatus.value ||
    isReleaseStoppedStatus.value ||
    isReleaseRestrictStatus.value
  );
});
const isDisablePriceDate = computed(() => {
  return isRequestedReviewStatus.value || isUnderReviewStatus.value || isReleaseStoppedStatus.value;
});
const isProductFree = computed(() => currentPrice.value?.paidYn === Confirmation.NO);
const productPrice = computed(() => currentPrice.value?.defaultPrice);
const dataRejectPrice: ComputedRef<RejectContent[] | undefined> = computed(() =>
  props.dataReject?.rejectContents?.filter(
    (item: RejectContent) =>
      item.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.PRICE &&
      isOperatorRejectedSanctionedStatus.value
  )
);
const priceSettingsCheckCompleted = computed(() =>
  releasePlanStore.isCheckCompleted(checkListData.value.priceSettings)
);

const isProductDemoType = computed(
  () =>
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.CONTENT_DEMO
);

const disabledLimitedSaleExpiredDateValue = computed(() => {
  return {
    to: formatToDateTime(releasedAt.value).startOf('day').toJSDate()
  };
});
const isLimitedSaleExpiredError = ref<number | null>(null);

const openSettingPricePage = () => {
  redirectTo(PRODUCT_SELLING_PRICE_PAGE_URL, OPEN_TARGET_BLANK);
};

const openSettingDiscountPage = async () => {
  if (!isOfficialReleased.value) {
    await showConfirm({
      content: t('studio.discount.verify_not_passed.cannot_access_msg')
    });
    return;
  }
  await redirectTo(PRODUCT_DISCOUNT_PAGE_URL, OPEN_TARGET_BLANK);
};

const handleReload = async () => {
  await getPriceData();
};

const getPriceData = async () => {
  try {
    sellingPrice.value = await fetchProductSellingPriceApi(productNo);
    if (sellingPrice.value) {
      currentPrice.value =
        sellingPrice.value?.notVerifyPassedPrice?.verifyStatus === VERIFY_STATUS.REJECT && isReleaseLiveStatus.value
          ? sellingPrice.value?.verifyPassedPrice
          : sellingPrice.value?.notVerifyPassedPrice ?? sellingPrice.value?.verifyPassedPrice;
      releasePlanStore.setPriceSettingsCheckListCompleted(
        !!currentPrice.value,
        isFirstTime.value ? !!dataRejectPrice.value?.length : false
      );
      if (currentPrice.value?.salesNo) {
        setSalesNo(currentPrice.value?.salesNo);
        setPriceList(currentPrice.value?.priceList ?? []);
      }
    }
  } catch (err: any) {
    if (err.statusCode === STATUS_CODE.PRODUCT_SELLING_PRICE_NOT_FOUND) {
      return;
    }
    throw err;
    // console.error(err);
  }
};

const init = () => {
  getPriceData();
};

init();

onMounted(() => {
  isFirstTime.value = false;
});

watchEffect(() => {
  if (isProductDemoType.value) {
    releasePlanStore.setPriceSettingsCheckListCompleted(true);
    isLimitedSaleExpiredError.value = null;
    return;
  }
  // Release discount options
  if (releaseDiscountYn.value === Confirmation.YES) {
    if (!releaseDiscountRate.value || !releaseDiscountDays.value) {
      releasePlanStore.setPriceSettingsCheckListError();
      return;
    }
    releasePlanStore.setPriceSettingsCheckListCompleted(
      true,
      isFirstTime.value ? !!dataRejectPrice.value?.length : false
    );
  }

  // Sale limit options
  if (saleLimitType.value === RELEASE_PLAN_LIMITED_SALES_STATUS.DATE) {
    if (!limitedSaleExpired.value) {
      isLimitedSaleExpiredError.value = 1;
      releasePlanStore.setPriceSettingsCheckListError();
      return;
    }
    if (
      limitedSaleExpired.value &&
      formatUnknownSrcToMillis(releasedAt.value) >=
        formatUnknownSrcToMillis(limitedSaleExpired.value)
    ) {
      isLimitedSaleExpiredError.value = 2;
      releasePlanStore.setPriceSettingsCheckListError();
      return;
    }
  }
  if (saleLimitType.value === RELEASE_PLAN_LIMITED_SALES_STATUS.COUNT && !saleLimitCount.value) {
    releasePlanStore.setPriceSettingsCheckListError();
    return;
  }
  releasePlanStore.setPriceSettingsCheckListCompleted(
    !!sellingPrice.value,
    isFirstTime.value ? !!dataRejectPrice.value?.length : false
  );
  isLimitedSaleExpiredError.value = null;
});
</script>

<style scoped lang="scss">
.d-content {
  display: contents;
}
</style>

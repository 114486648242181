<template>
  <s-dialog to="inspection-request-confirmation-dialog" size="md" open>
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <s-dialog-content-body>
          <div class="space-y-8 text-center">
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
              {{ $t('studio.prj_prod.this_prod.release_details_browser_request_review_pop_msg1') }}
              <br />
              {{ $t('studio.prj_prod.this_prod.release_details_browser_request_review_pop_msg2') }}
            </p>
            <s-text as="p" role="text3" class="text-on-surface-elevation-3">
              {{
                $t(
                  'studio.prj_prod.this_prod.release_details_browser_request_review_pop_manual_release_select_msg'
                )
              }}
            </s-text>
            <dl class="flex flex-col justify-center min-h-116 text-lg leading-lg">
              <template v-if="isPageBuild">
                <dt class="text-on-surface-elevation-2">
                  {{
                    $t(
                      'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item_title'
                    )
                  }}
                </dt>
                <dd class="text-on-surface-elevation-3">
                  <span v-if="checkIsCompleted(checkListData.productSetting)">
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item1'
                      )
                    }}
                  </span>
                  <span v-if="checkIsCompleted(checkListData.productPage)">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item2'
                      )
                    }}
                  </span>
                  <span v-if="checkIsCompleted(checkListData.executionSettings)">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item4'
                      )
                    }}
                  </span>
                  <span
                    v-if="
                      checkIsCompleted(checkListData.uploadBuildFile) &&
                        checkIsCompleted(checkListData.ageRatingAndBuild)
                    "
                  >
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item5'
                      )
                    }}
                  </span>
                  <span v-if="checkIsCompleted(checkListData.priceSettings)">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item6'
                      )
                    }}
                  </span>
                  <span v-if="releaseDiscountYn === Confirmation.YES">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item7'
                      )
                    }}
                  </span>
                  <span v-if="saleLimitType !== RELEASE_PLAN_LIMITED_SALES_STATUS.NONE">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item8'
                      )
                    }}
                  </span>
                  <span v-if="checkIsCompleted(checkListData.officialRelease)">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item3'
                      )
                    }}
                  </span>
                </dd>
              </template>
              <template v-else>
                <dt class="text-on-surface-elevation-2">
                  {{
                    $t(
                      'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item_title'
                    )
                  }}
                </dt>
                <dd class="text-on-surface-elevation-3">
                  <span class="text-on-surface-elevation-3">
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item1'
                      )
                    }}
                  </span>
                  <span class="text-on-surface-elevation-3">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item2'
                      )
                    }}
                  </span>
                  <span class="text-on-surface-elevation-3">
                    ,
                    {{
                      $t(
                        'studio.prj_prod.this_prod.release_details_browser_request_review_pop_review_item3'
                      )
                    }}
                  </span>
                </dd>
              </template>
            </dl>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button variant="outline" @click="onClose()">
          {{ $t('studio.common.popup_case_cancel_btn') }}
        </s-button>
        <s-button @click="onSubmit()">
          {{
            $t(
              'studio.prj_prod.this_prod.release_details_browser_request_review_pop_request_review_btn'
            )
          }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="inspection-request-confirmation-dialog" />
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import {
  PRODUCT_LAUNCH_TYPE,
  RELEASE_PLAN_CHECK_LIST_STATUS,
  RELEASE_PLAN_LIMITED_SALES_STATUS
} from '@/constants/release-plan.const';
import { Confirmation } from '@/enums/common.enum';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import type { CheckListStatus } from '@/types/release-plan/release-plan.type';

const releasePlanStore = useReleasePlanStore();
const { checkListData } = storeToRefs(releasePlanStore);

const props = defineProps<{
  releaseType: string;
  saleLimitType: string;
  releaseDiscountYn: Confirmation;
}>();

const emit = defineEmits<{
  close: [type?: Confirmation];
}>();

const isPageBuild = computed(() => props.releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD);

const onClose = () => {
  emit('close');
};

const onSubmit = () => {
  emit('close', Confirmation.YES);
};

const checkIsCompleted = (status?: CheckListStatus): boolean => {
  if (!status) {
    return false;
  }
  return status === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
};
</script>

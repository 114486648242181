<template>
  <st-box :id="RELEASE_CHECKLIST_ID.PRODUCT_PAGE_OPTIONS" class="mt-24 !p-[3rem]">
    <folding-release
      :settingTitle="$t('studio.prj_prod.this_prod.release_details_product_page_option_title')"
      @onReload="fetchData()"
    >
      <!--Section 3-->
      <setting-item-title
        :id="RELEASE_CHECKLIST_ID.STORE_COMMUNITY"
        :settingTitle="
          $t('studio.prj_prod.this_prod.release_details_product_page_option_store_community_title')
        "
        useButton
      >
        <template #default>
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{
              $t(
                'studio.prj_prod.this_prod.release_details_product_page_option_store_community_msg1'
              )
            }}
          </p>
        </template>
        <template #button>
          <s-button
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="goToManagerCommunity"
          >
            {{
              $t(
                'studio.prj_prod.this_prod.release_details_product_page_option_store_community_mgmt_dir_btn'
              )
            }}
          </s-button>
        </template>
      </setting-item-title>
      <setting-content
        :settingContentDesc="
          isCommunityActive
            ? $t(
              'studio.prj_prod.this_prod.release_details_product_page_option_store_community_msg3'
            )
            : $t(
              'studio.prj_prod.this_prod.release_details_product_page_option_store_community_msg2'
            )
        "
      />

      <template v-if="!releasePlanStore.isCheckListHidden(checkListData.earlyAccess)">
        <setting-item-title
          :id="RELEASE_CHECKLIST_ID.EARLY_ACCESS"
          class="mt-24"
          :settingTitle="
            $t('studio.prj_prod.this_prod.release_details_product_page_option_early_access_title')
          "
          useButton
        >
          <template #default>
            <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
              {{
                $t('studio.prj_prod.this_prod.release_details_product_page_option_early_access_msg')
              }}
            </p>
          </template>
          <template #button>
            <s-button
              v-if="earlyAccessData"
              variant="outline"
              size="sm"
              class="flex-row-reverse"
              iconClass="!mr-0 ml-4"
              @click="openStorePage"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.release_details_product_page_option_early_access_preview_btn'
                )
              }}
            </s-button>
            <s-button
              variant="outline"
              size="sm"
              icon="ic-v2-control-web-link-line"
              class="flex-row-reverse"
              iconClass="!mr-0 ml-4"
              @click="goToEarlyAccessPage"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.release_details_product_page_option_early_access_redirect_btn'
                )
              }}
            </s-button>
          </template>
        </setting-item-title>
        <setting-content
          v-show="!earlyAccessData"
          :settingContentDesc="
            $t(
              'studio.prj_prod.this_prod.release_details_product_page_option_early_access_content_place_holder'
            )
          "
        />
        <release-setting-box v-show="!!earlyAccessData" class="mt-8">
          <setting-sub-title
            :settingSubTitle="$t('studio.prj_prod.this_prod.home_early_access_text_display')"
          />
          <radio-group
            rules="required"
            name="releasePlanDetail.plans.page.earlyAccessYn"
            :disabled="isDisabledEarlyAccessYn"
            :options="earlyAccessOptions"
            :optionProps="{
              size: 'sm',
              align: 'middle'
            }"
            class="flex flex-wrap gap-x-24 my-12"
          />

          <div v-show="earlyAccessStatus === Confirmation.YES" class="contents">
            <setting-sub-title
              :settingSubTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_product_page_option_early_access_period_title'
                )
              "
              :settingSubTitleDesc="utcTimezone"
            >
              <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
                <safe-html
                  :html="
                    $t(
                      'studio.prj_prod.this_prod.release_details_product_page_option_early_access_period_msg'
                    )
                  "
                />
              </p>
            </setting-sub-title>
            <div
              class="mt-8 py-16 px-24 flex items-center bg-[var(--stds-glob-color-gray20)] rounded-xl"
            >
              <datetime-picker
                name="releasePlanDetail.plans.page.earlyAccessSchedule.startedAt"
                :disabledDates="disabledEarlyAccessBeginDateValue"
                :containerClass="'flex items-center gap-16'"
                :isDisabled="isDisabledEarlyAccessDate || isEarlyAccessStartedAtDateOverDateNow"
                isReplaceContainerClass
                hiddenUtc
              />
              <span class="px-16 text-md font-bold text-on-surface-elevation-2 leading-lg">
                ~
              </span>
              <datetime-picker
                name="releasePlanDetail.plans.page.earlyAccessSchedule.endedAt"
                :disabledDates="disabledEarlyAccessEndDateValue"
                :containerClass="'flex items-center gap-16'"
                :isDisabled="isDisabledEarlyAccessDate"
                isReplaceContainerClass
                hiddenUtc
              />
            </div>
            <s-box v-if="isEarlyAccessError" class="flex gap-x-2 text-error mt-8">
              <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" />
              <s-text role="cap1"> {{ EARLY_ACCESS_ERROR[isEarlyAccessError] }} </s-text>
            </s-box>
          </div>
        </release-setting-box>
      </template>
    </folding-release>
  </st-box>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchEarlyAccess } from '@/apis/early-access.api';
import { fetchCommunityInformationApi } from '@/apis/release-plan.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import Preview from '@/components/early-access/preview.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import ReleaseSettingBox from '@/components/release-plan/release-setting-box.vue';
import SettingContent from '@/components/release-plan/setting-content.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import SettingSubTitle from '@/components/release-plan/setting-sub-title.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showDialog } from '@/composables/useDialog';
import { OPEN_TARGET_BLANK, OPEN_TARGET_BLANK_EXTERNAL } from '@/constants/common.const';
import { DEFAULT_VALUE_CONTENT_EARLY_ACCESS } from '@/constants/early-access.const';
import { STATUS_CODE } from '@/constants/error.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { RELEASE_CHECKLIST_ID } from '@/constants/release-plan.const';
import { MANAGER_COMMUNITY, PRODUCT_EARLY_ACCESS_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { EarlyAccessRequest } from '@/types/early-access/early-access-request.type';
import type { EarlyAccessDetails } from '@/types/early-access/early-access-response.type';
import { generateExternalLink, getConfigs, redirectTo } from '@/utils/common.util';
import { formatToDateTime, formatUnknownSrcToMillis, getUtcTimezone } from '@/utils/date.util';

const props = defineProps<{
  isPageBuild: boolean;
  defaultEarlyAccessBeginDate: number;
  isCurrentOnlyPageOpenReleased?: boolean;
}>();

const releasePlanStore = useReleasePlanStore();
const {
  earlyAccessCheckListTextError,
  checkListData,
  isRequestedReviewStatus,
  isUnderReviewStatus,
  isReleaseStoppedStatus,
  isReleaseRestrictStatus,
  isReleaseLiveStatus,
  isReviewCompletedStatus,
  isEarlyAccessStartedAtDateOverDateNow
} = storeToRefs(releasePlanStore);
const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const { t, locale } = useI18n();
const routeParams = useRoute().params;
const productNo: number = parseInt(routeParams?.productId as string, 10);

const earlyAccessData = ref<EarlyAccessDetails | undefined>();
const isCommunityActive = ref<boolean>(false);

const utcTimezone = `(${getUtcTimezone()})`;
const earlyAccessOptions = [
  {
    label: t('studio.prj_prod.this_prod.home_early_access_text_display_y'),
    value: Confirmation.YES
  },
  {
    label: t('studio.prj_prod.this_prod.home_early_access_text_display_n'),
    value: Confirmation.NO
  }
];
const EARLY_ACCESS_ERROR: Record<number, string> = {
  1: t('studio.release_details.period_settings.end_before_start_val_msg1'),
  2: t('studio.release_details.period_settings.start_date_before_open_val_msg2'),
  3: t('studio.release_details.period_settings.end_date_input_n_val_msg3'),
  4: t('studio.release_details.period_settings.end_date_after_official_release_val_msg4'),
  5: t('studio.release_info_settings.cannot_set_early_access_msg')
};

const earlyAccessStatus = useFieldValue<string>('releasePlanDetail.plans.page.earlyAccessYn');
const earlyAccessBeginDateValue = useFieldValue<number>(
  'releasePlanDetail.plans.page.earlyAccessSchedule.startedAt'
);
const earlyAccessEndDateValue = useFieldValue<number>(
  'releasePlanDetail.plans.page.earlyAccessSchedule.endedAt'
);
const pageOpenedAt = useFieldValue<number>('releasePlanDetail.plans.page.pageOpenedAt');
const releasedAt = useFieldValue<number>('releasePlanDetail.plans.release.releasedAt');

const isUseEarlyAccess = computed(() => earlyAccessStatus.value === Confirmation.YES);
const isEarlyAccessError = ref<number | null>();
const disabledEarlyAccessBeginDateValue = computed(() => {
  const toDate = formatToDateTime(
    Date.now() <= formatUnknownSrcToMillis(pageOpenedAt.value) || !isReviewCompletedStatus.value
      ? pageOpenedAt.value
      : new Date()
  )
    .startOf('day')
    .toJSDate();
  const validation: { to: Date; from: Date | null } = {
    to: toDate,
    from: null
  };
  validation.from =
    props.isPageBuild && !isReleaseLiveStatus.value
      ? formatToDateTime(releasedAt.value).endOf('day').toJSDate()
      : null;
  return validation;
});
const disabledEarlyAccessEndDateValue = computed(() => {
  const toDate = formatToDateTime(
    Date.now() <= formatUnknownSrcToMillis(earlyAccessBeginDateValue.value)
      ? earlyAccessBeginDateValue.value
      : new Date()
  )
    .startOf('day')
    .toJSDate();
  const validation: { to: Date; from: Date | null } = {
    to: toDate,
    from: null
  };
  /**
   validation.from = props.isPageBuild
    ? formatToDateTime(releasedAt.value).endOf('day').toJSDate()
    : null;
   **/
  return validation;
});
const isDisabledEarlyAccessDate = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReleaseStoppedStatus.value ||
    isReleaseRestrictStatus.value
  );
});

const storeHref = computed(() => {
  const { STORE_URL } = getConfigs();
  return `${STORE_URL}/${locale.value}`;
});
const isDisabledEarlyAccessYn = computed(() => {
  return (
    isRequestedReviewStatus.value ||
    isUnderReviewStatus.value ||
    isReleaseStoppedStatus.value ||
    isReleaseRestrictStatus.value
  );
});

const goToEarlyAccessPage = () => {
  redirectTo(PRODUCT_EARLY_ACCESS_PAGE_URL, OPEN_TARGET_BLANK);
};

const goToManagerCommunity = async () => {
  await redirectTo(
    await generateExternalLink(
      MANAGER_COMMUNITY,
      locale.value,
      selectedGroupInfo.value?.groupId ?? ''
    ),
    {
      external: true,
      open: {
        target: '_blank'
      }
    }
  );
};

const openStorePage = async () => {
  if (earlyAccessData.value?.activeYn) {
    const url = `${storeHref.value}/games/${productNo}`;
    await redirectTo(url, OPEN_TARGET_BLANK_EXTERNAL);
  } else {
    earlyAccessPopup();
  }
};

const earlyAccessPopup = () => {
  const curLang = locale.value || DEFAULT_LOCALE;
  const content = ref(
    earlyAccessData?.value?.contents[curLang] ?? DEFAULT_VALUE_CONTENT_EARLY_ACCESS[curLang]
  );

  showDialog({
    component: shallowRef(Preview),
    props: {
      content
    }
  });
};

const fetchEarlyAccessData = async () => {
  try {
    const params: EarlyAccessRequest = {
      productNo: Number(productNo)
    };

    earlyAccessData.value = await fetchEarlyAccess(params);
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode !== STATUS_CODE.EARLY_ACCESS_NOT_FOUND) {
      throw err;
    }
  }
};

const fetchCommunityData = async () => {
  const data = await fetchCommunityInformationApi(productNo);
  isCommunityActive.value = data?.activeYn === Confirmation.YES ?? false;
  releasePlanStore.setStoreCommunityCheckList(isCommunityActive.value);
};

const fetchData = () => {
  fetchEarlyAccessData();
  fetchCommunityData();
};

const init = () => {
  fetchData();
};

init();

watchEffect(() => {
  // const releaseDateMillis = formatUnknownSrcToMillis(releasedAt.value);
  const pageOpenAtMillis = formatUnknownSrcToMillis(pageOpenedAt.value);
  const beginDateJS = formatUnknownSrcToMillis(earlyAccessBeginDateValue.value);
  const endDateJS = formatUnknownSrcToMillis(earlyAccessEndDateValue.value);

  // If EarlyAccess is non-active
  if (earlyAccessStatus.value === Confirmation.NO) {
    releasePlanStore.setEarlyAccessCheckListCompleted(false);
    return;
  }

  // If EarlyAccess is active
  /** if (pageOpenAtMillis === releaseDateMillis && props.isPageBuild) {
    isEarlyAccessError.value = 5;
    releasePlanStore.setEarlyAccessCheckListError();
    return;
  } **/
  if (!earlyAccessEndDateValue.value) {
    isEarlyAccessError.value = 3;
    releasePlanStore.setEarlyAccessCheckListError();
    return;
  }
  if (beginDateJS >= endDateJS) {
    isEarlyAccessError.value = 1;
    releasePlanStore.setEarlyAccessCheckListError();
    return;
  }
  if (beginDateJS < pageOpenAtMillis) {
    isEarlyAccessError.value = 2;
    releasePlanStore.setEarlyAccessCheckListError();
    return;
  }
  /** if (endDateJS > releaseDateMillis && props.isPageBuild) {
    isEarlyAccessError.value = 4;
    releasePlanStore.setEarlyAccessCheckListError();
    return;
  } **/
  releasePlanStore.setEarlyAccessCheckListCompleted(isUseEarlyAccess.value);
  isEarlyAccessError.value = null;
});

watch(
  () => earlyAccessStatus.value,
  () => {
    if (earlyAccessStatus.value === Confirmation.NO) {
      releasePlanStore.setEarlyAccessCheckListCompleted(isUseEarlyAccess.value);
      return;
    } else if (earlyAccessStatus.value === Confirmation.YES && !isEarlyAccessError.value) {
      releasePlanStore.setEarlyAccessCheckListCompleted(isUseEarlyAccess.value);
      return;
    }
    releasePlanStore.setEarlyAccessCheckListCompleted(false);
  },
  { immediate: true }
);

watch(
  () => isEarlyAccessError.value,
  () => {
    earlyAccessCheckListTextError.value = isEarlyAccessError.value
      ? EARLY_ACCESS_ERROR[isEarlyAccessError.value]
      : null;
  }
);
</script>

<template>
  <st-box :id="RELEASE_CHECKLIST_ID.PREPARE_PRODUCT_PAGE" class="mt-24 !p-[3rem]">
    <folding-release
      required
      :isError="
        (!!dataRejectProductSetting?.length || !!dataRejectProductPage?.length) &&
          isOperatorRejectedSanctionedStatus
      "
      :settingTitle="t('studio.prj_prod.this_prod.release_details_product_page_info_title')"
      :preparingProductStatus="currentPreparingProductStatus"
      @onReload="handleReload"
    >
      <!--Section 1-->
      <setting-item-title
        :id="RELEASE_CHECKLIST_ID.PRODUCT_SETTING"
        :settingTitle="
          t('studio.prj_prod.this_prod.release_details_product_page_info_product_setting_title')
        "
        useButton
      >
        <template #default>
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            {{
              $t('studio.prj_prod.this_prod.release_details_product_page_info_product_setting_msg1')
            }}
          </p>
        </template>
        <template #button>
          <s-button
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="goToProductSetting"
          >
            {{
              $t(
                'studio.prj_prod.this_prod.release_details_product_page_info_product_setting_redirect_btn'
              )
            }}
          </s-button>
        </template>
      </setting-item-title>
      <template v-if="dataRejectProductSetting?.length && isOperatorRejectedSanctionedStatus">
        <reason-rejections
          v-for="(item, index) in dataRejectProductSetting"
          :key="index"
          class="mt-8"
          :rejectContentTitle="`[${item.title}]`"
          :rejectContentDesc="item.reason"
        />
      </template>
      <setting-content
        v-else
        :isError="!productSettingCheckCompleted"
        :settingContentDesc="
          currentProductSettingStatus
            ? productSettingStatus.completed
            : productSettingStatus.inCompleted
        "
      />

      <!--Section 2-->
      <setting-item-title
        :id="RELEASE_CHECKLIST_ID.PRODUCT_PAGE"
        :settingTitle="
          $t('studio.prj_prod.this_prod.release_details_product_page_info_product_page_title')
        "
        useButton
        class="mt-32"
      >
        <template #default>
          <p class="stds-text text-text4 leading-text4 font-text4 font-regular text-placeholder">
            <template v-if="!isReleaseLiveStatus">
              {{ $t('studio.prj_prod.this_prod.release_details_product_page_guide') }}
            </template>
            <template v-else>
              {{
                $t('studio.prj_prod.this_prod.release_details_product_page_info_product_page_msg')
              }}
            </template>
          </p>
        </template>
        <template #button>
          <s-button
            variant="outline"
            size="sm"
            icon="ic-v2-control-web-link-line"
            class="flex-row-reverse"
            iconClass="!mr-0 ml-4"
            @click="goToProductPage"
          >
            {{
              $t(
                'studio.prj_prod.this_prod.release_details_product_page_info_product_page_redirect_btn'
              )
            }}
          </s-button>
        </template>
      </setting-item-title>
      <release-setting-box class="mt-8" :isError="!productPageCheckCompleted">
        <div class="flex items-center gap-8">
          <div class="relative w-[62rem]">
            <dropdown
              rules="required"
              name="releasePlanDetail.pageId"
              :disabled="isDisabled"
              :options="productPageList"
              :dropdownProps="{
                size: 'lg',
                variant: 'line',
                distance: 4,
                offset: [0, 0],
                placement: 'bottom',
                class: 'w-full'
              }"
              :containerClass="'relative'"
              :placeholder="
                $t(
                  'studio.prj_prod.this_prod.release_details_product_page_info_product_page_dbox_default_msg'
                )
              "
              closeOnClick
              size="lg"
              variant="line"
              :distance="4"
              :offset="[0, 0]"
              placement="bottom"
              class="w-full"
            />
          </div>
          <s-button
            variant="outline"
            size="sm"
            :isDisabled="isReviewCompletedStatus || !pageId"
            @click="goToProductPageDetail"
          >
            {{
              $t(
                isReleaseOnlyProductPage
                  ? 'studio.prj_prod.this_prod.release_stt_calendarview_store_dir_btn'
                  : 'studio.prj_prod.this_prod.release_details_product_page_preview_dir_btn'
              )
            }}
          </s-button>
        </div>
      </release-setting-box>
      <template v-if="dataRejectProductPage?.length && isOperatorRejectedSanctionedStatus">
        <reason-rejections
          v-for="(item, index) in dataRejectProductPage"
          :key="index"
          class="mt-8"
          :rejectContentTitle="`[${item.title}]`"
          :rejectContentDesc="item.reason"
        />
      </template>
    </folding-release>
  </st-box>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue } from 'vee-validate';
import { computed, type ComputedRef, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  fetchProductPageForLaunchApi,
  generateProductPagePreviewKeyApi
} from '@/apis/product-page.api';
import { checkProductSettingReleaseApi } from '@/apis/products.api';
import StBox from '@/components/common/st-box.vue';
import FoldingRelease from '@/components/release-plan/folding-release.vue';
import ReasonRejections from '@/components/release-plan/reasons-rejection.vue';
import ReleaseSettingBox from '@/components/release-plan/release-setting-box.vue';
import SettingContent from '@/components/release-plan/setting-content.vue';
import SettingItemTitle from '@/components/release-plan/setting-item-title.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { OPEN_TARGET_BLANK, OPEN_TARGET_BLANK_EXTERNAL } from '@/constants/common.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import {
  RELEASE_CHECKLIST_ID,
  RELEASE_PLAN_PREPARING_PRODUCT_STATUS,
  RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE
} from '@/constants/release-plan.const';
import { PRODUCT_PAGES_PAGE_URL, PRODUCT_SETTING_PAGE_URL } from '@/constants/url.const';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import { useUserStore } from '@/stores/user.store';
import type { FormOption } from '@/types/common/form.type';
import type { ProductPageSimpleResponse } from '@/types/product-page/product-page-response.type';
import type {
  RejectContent,
  ReviewRejectionInformationResponse
} from '@/types/release-plan/release-plan-response.type';
import { getConfigs, redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';

const { t, locale } = useI18n();
const routeParams = useRoute().params;
const productNo: string = routeParams?.productId as string;

const releasePlanStore = useReleasePlanStore();
const {
  isReleaseOnlyProductPage,
  isOperatorRejectedSanctionedStatus,
  isReviewCompletedStatus,
  isReleaseLiveStatus,
  checkListData
} = storeToRefs(releasePlanStore);

const props = defineProps<{
  isPageBuild: boolean;
  isDisabled: boolean;
  dataReject?: ReviewRejectionInformationResponse;
  isOnlyPageOpenReleased?: boolean;
}>();

const productSettingStatus = {
  completed: t(
    'studio.prj_prod.this_prod.release_details_product_page_info_product_setting_complete_msg'
  ),
  inCompleted: t(
    'studio.prj_prod.this_prod.release_details_product_page_info_product_setting_missing_msg'
  )
};

const isFirstTime = ref<boolean>(true);

const dataRejectProductSetting: ComputedRef<RejectContent[] | undefined> = computed(() =>
  props.dataReject?.rejectContents?.filter(
    (item: RejectContent) =>
      item.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.PRODUCT_SETTING &&
      isOperatorRejectedSanctionedStatus.value
  )
);
const dataRejectProductPage: ComputedRef<RejectContent[] | undefined> = computed(() =>
  props.dataReject?.rejectContents?.filter(
    (item: RejectContent) =>
      item.verifyTargetType === RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE.PRODUCT_PAGE &&
      isOperatorRejectedSanctionedStatus.value
  )
);
const productSettingCheckCompleted = computed(() =>
  releasePlanStore.isCheckCompleted(checkListData.value.productSetting)
);
const productPageCheckCompleted = computed(() =>
  releasePlanStore.isCheckCompleted(checkListData.value.productPage)
);

const productPageList = ref<FormOption[]>([]);

const currentPreparingProductStatus = ref(RELEASE_PLAN_PREPARING_PRODUCT_STATUS.COMPLETED);
const currentProductSettingStatus = ref(false);
const pageId = useFieldValue<string>('releasePlanDetail.pageId');
const releaseType = useFieldValue<string>('releasePlanDetail.releaseType');

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);
const storeHref = computed(() => {
  const { STORE_URL } = getConfigs();
  return `${STORE_URL}/${locale.value}`;
});

const goToProductSetting = () => {
  redirectTo(PRODUCT_SETTING_PAGE_URL, OPEN_TARGET_BLANK);
};

const goToProductPage = () => {
  redirectTo(PRODUCT_PAGES_PAGE_URL, OPEN_TARGET_BLANK);
};

const goToProductPageDetail = async () => {
  const url = `${storeHref.value}/games/${productNo}`;
  if (isReleaseOnlyProductPage.value) {
    await redirectTo(url, OPEN_TARGET_BLANK_EXTERNAL);
    return;
  }
  const res = await generateProductPagePreviewKeyApi(
    parseInt(productNo, 10),
    pageId.value,
    selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE
  );
  if (res) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: `${url}?token=${res.previewKey}&preview_type=studio`
    }).click();
  }
};

const getCurrentProductSettingStatus = async () => {
  currentProductSettingStatus.value = await checkProductSettingReleaseApi(productNo);
  releasePlanStore.setProductSettingCheckList(
    currentProductSettingStatus.value,
    isFirstTime.value ? !!dataRejectProductSetting.value?.length : false
  );
};

const getProductPageList = async () => {
  const data = await fetchProductPageForLaunchApi({
    productNo: parseInt(productNo, 10),
    page: 1,
    size: 9999
  });
  if (data?.contents?.length) {
    productPageList.value = data?.contents.map((item: ProductPageSimpleResponse) => ({
      value: item.pageId.toString(),
      label: `${item.pageName} (${getDateTimeByLocale(item?.pageUpdatedAt ?? item.createdAt)})`
    }));
  } else {
    productPageList.value = [];
  }
};

const handleReload = async () => {
  await fetchDataApi();
  releasePlanStore.setProductSettingCheckList(currentProductSettingStatus.value);
  releasePlanStore.setProductPageCheckList(!!pageId.value && !!productPageList.value.length);
};

const fetchDataApi = async () => {
  await Promise.all([getProductPageList(), getCurrentProductSettingStatus()]);
};

const init = async () => {
  await fetchDataApi();
};

await init();

watch(
  () => pageId.value,
  () => {
    releasePlanStore.setProductPageCheckList(
      !!pageId.value,
      isFirstTime.value ? !!dataRejectProductPage.value?.length : false
    );
  },
  { immediate: true }
);

watch(
  () => releaseType.value,
  () => {
    releasePlanStore.setProductSettingCheckList(
      currentProductSettingStatus.value,
      isFirstTime.value ? !!dataRejectProductSetting.value?.length : false
    );
    releasePlanStore.setProductPageCheckList(
      !!pageId.value,
      isFirstTime.value ? !!dataRejectProductPage.value?.length : false
    );
  },
  { immediate: true }
);

onMounted(() => {
  isFirstTime.value = false;
});
</script>

<template>
  <div class="flex gap-20 items-center mt-20">
    <div
      class="flex gap-12 w-[57.2rem] items-center border-solid border-1 border-border rounded-xl bg-[#FCFCFD] pl-16 py-[1rem] pr-12"
    >
      <s-text
        as="div"
        role="text3"
        class="flex flex-1 items-center justify-start gap-8"
        :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
      >
        <span>{{ uploadText }}</span>
        <button v-if="!isDisabled" v-show="!!uploadValue" class="flex" @click="clearUpload">
          <s-icon
            icon="ic-v2-control-close-circle-fill"
            size="xl"
            class="text-on-surface-elevation-4"
          />
        </button>
      </s-text>
      <input
        ref="fileInput"
        type="file"
        class="hidden"
        :accept="EXCEL_FILE_EXTENSION.toString()"
        :disabled="isDisabled"
        @change="handleFileChange"
      />
      <s-button
        size="sm"
        variant="outline"
        class="!min-w-72"
        :isDisabled="isDisabled"
        @click="triggerFileUpload"
      >
        {{
          $t(
            'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_reg_file_upload_btn'
          )
        }}
      </s-button>
    </div>
    <s-button variant="secondary" size="sm" class="pr-8" @click="downloadExcelTemplate">
      <span>{{
        $t(
          'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_reg_template_download_btn'
        )
      }}</span>
      <SIcon icon="ic-v2-object-download-line" size="xl" class="ml-2" />
    </s-button>
  </div>
  <div v-if="isUploadError" class="w-full">
    <s-box class="flex gap-x-2 text-error mt-8">
      <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" />
      <s-text role="cap1"> {{ UPLOAD_ERROR[isUploadError] }} </s-text>
    </s-box>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { importWhitelistApi } from '@/apis/products.api';
import { EXCEL_FILE_EXTENSION } from '@/constants/file.const';
import { PLAY_OPTION } from '@/constants/release-plan.const';
import { downLoadFile } from '@/utils/file.util';

const { t } = useI18n();

const props = defineProps<{
  playOption: string;
  isDisabled?: boolean;
}>();

const TEMPLATE_URL: string =
  'https://static-resource-dev.onstove.com/dev/application_no/STATIC/new-studio-mock-BE/project-product/sample_member_no_list.xlsx';

const fileInput = ref();
const uploadValue = ref('');
const uploadText = computed(() => {
  return (
    uploadValue.value ||
    t(
      'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_reg_template_placeholder'
    )
  );
});
const isUploadError = ref<number | undefined>();
const UPLOAD_ERROR: Record<number, string> = {
  1: t(
    'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_upload_failed_case_invalid_input_value'
  ),
  2: t(
    'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_upload_failed_case_over_1000'
  ),
  3: t(
    'studio.prj_prod.this_prod.release_details_plan_early_access_setting_execution_upload_failed_case_invalid_format'
  )
};
const routeParams = useRoute().params;
const productNo: string = routeParams?.productId as string;

const triggerFileUpload = () => {
  fileInput.value.click();
};
const handleFileChange = async (event: any) => {
  const file = event.target.files[0];
  if (file) {
    uploadValue.value = file.name;
  } else {
    uploadValue.value = '';
  }
  const data = await importWhitelistApi(productNo, props.playOption, file);
  if (data?.success) {
    isUploadError.value = 0;
  } else {
    // If api return invalid input value
    // isUploadError.value = 1;
    // If api return code user > 1000 = 2
    // isUploadError.value = 2;
    // If api return invalid format
    // isUploadError.value = 3;
  }
};
const clearUpload = () => {
  uploadValue.value = '';
  fileInput.value.value = null;
};

const downloadExcelTemplate = () => {
  if (props.playOption === PLAY_OPTION.STOVE_MEMBER) {
    downLoadFile(TEMPLATE_URL, 'sample_member_no_list.xlsx');
  } else if (props.playOption === PLAY_OPTION.IP) {
    downLoadFile(TEMPLATE_URL, 'sample_member_no_list.xlsx');
  }
};

watch(
  () => props.playOption,
  () => {
    if (props.playOption === PLAY_OPTION.IP || props.playOption === PLAY_OPTION.STOVE_MEMBER) {
      clearUpload();
    }
  }
);
</script>

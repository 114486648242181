<!--ReleaseRadioComponent-->
<template>
  <label
    class="release-radio flex-1 rounded-[2.8rem] border-2 border-solid border-border bg-surface-elevation-1 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] px-24 py-20 has-[:checked]:border-brand-primary has-[:checked]:border-[0.3rem]"
    :class="{
      'cursor-default pointer-events-none !border-on-surface-elevation-4': isDisabled,
      'cursor-pointer': !isDisabled
    }"
  >
    <s-radio
      :id="id"
      :value="value"
      size="sm"
      align="top"
      :isDisabled="isDisabled"
    >
      <span class="flex justify-between items-center -ml-8">
        <s-icon
          :icon="
            value === PRODUCT_LAUNCH_TYPE.PAGE_BUILD
              ? 'ic-v2-object-game-line'
              : 'ic-v2-navigation-shop-line'
          "
          size="5xl"
          :class="{
            'text-on-surface-elevation-1': !isDisabled && releaseType !== value,
            'text-disabled-variant-1': isDisabled || releaseType !== value,
            'text-brand-primary': releaseType === value && !isDisabled
          }"
        />
        <s-icon
          icon="ic-v2-control-check-circle-fill"
          size="5xl"
          :class="{
            'text-disabled-variant-1':
              (releaseType === value && isDisabled) || (releaseType !== value && !isDisabled),
            'text-disabled-variant-2': isDisabled && releaseType !== value,
            'text-brand-primary': releaseType === value && !isDisabled
          }"
        />
      </span>
      <p class="flex flex-col gap-4 mt-8 -ml-8">
        <s-text
          as="span"
          role="title6"
          class="!font-bold"
          :class="{
            'text-disabled-variant-1': isDisabled,
            'text-on-surface-elevation-1': !isDisabled
          }"
        >
          {{ label }}
        </s-text>
        <s-text
          as="span"
          role="text3"
          :class="{
            'text-disabled-variant-1': isDisabled,
            'text-on-surface-elevation-2': !isDisabled
          }"
        >
          {{ radioLabelDesc }}
        </s-text>
      </p>
    </s-radio>
  </label>
</template>
<script setup lang="ts">
import { useFieldValue } from 'vee-validate';

import { PRODUCT_LAUNCH_TYPE } from '@/constants/release-plan.const';

defineProps<{
  value: string;
  id?: string;
  label?: string;
  radioLabelDesc?: string;
  isDisabled?: boolean;
}>();

const releaseType = useFieldValue<string>('releasePlanDetail.releaseType');
</script>

<style lang="scss">
.release-radio input {
  display: none;
}
</style>

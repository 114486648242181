<!--FoldingReleaseComponent-->
<template>
  <div :id="id" class="flex items-center gap-16">
    <h2
      class="text-3xl leading-2xl break-all flex flex-1 items-center font-bold text-on-surface-elevation-1 gap-4"
    >
      {{ settingTitle }}
      <s-icon
        v-if="required"
        icon="ic-v2-control-required-fill"
        size="xs"
        class="text-tint-red-a400 shrink-0 self-start mt-[.2rem]"
      />
      <s-icon
        v-if="isError"
        icon="ic-v2-state-warning-circle-fill"
        size="4xl"
        class="text-system-red shrink-0"
      />
    </h2>
    <div class="shrink-0 flex items-center gap-16">
      <button
        v-if="!isHiddenReload"
        type="button"
        class="h-24 w-24 flex items-center justify-center release-reload-btn"
        @click="reload()"
      >
        <s-icon
          size="4xl"
          icon="ic-v2-control-refresh-line"
          class="text-on-surface-elevation-1 flex"
        />
      </button>
      <button
        type="button"
        class="h-24 w-24 flex items-center justify-center"
        @click="isOpenDetail = !isOpenDetail"
      >
        <s-icon
          size="4xl"
          icon="ic-v2-control-arrow-down-line"
          class="text-on-surface-elevation-1 flex"
          :class="{ 'rotate-180': isOpenDetail }"
        />
      </button>
    </div>
  </div>
  <div v-show="isOpenDetail" class="mt-24 pt-24 border-t-1 border-solid border-border">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  settingTitle?: string;
  required?: boolean;
  isError?: boolean;
  openDetail?: boolean;
  isHiddenReload?: boolean;
  id?: string;
}>();
const isOpenDetail = ref<boolean>(true);

const emits = defineEmits<{
  onReload: [isReload: boolean];
}>();

const reload = () => {
  emits('onReload', true);
};
</script>
